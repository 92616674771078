import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  Container,
  Alert,
  Box,
  Divider,
  FormControl,
  // IconButton,
  SelectChangeEvent,
  Stack,
  // Tooltip,
  Typography,
} from "@mui/material";
import { Formik, Field, FormikValues } from "formik";
import GeneralHeader from "@/components/headers/GeneralHeader";
import { PaystackProps } from "react-paystack/dist/types";
import { usePaystackPayment } from "react-paystack";
import DashboardSectionLayout from "@/components/dashboard/DashboardSectionLayout";
import CashMeterForm from "@/components/forms/meter/CashMeterForm";
import DashboardHeader from "@/components/headers/DashboardHeader";
import { Select, TextField } from "formik-mui";
import MenuItem from "@mui/material/MenuItem";
import { motion, AnimatePresence } from "framer-motion";
import Spacer from "@/components/common/Spacer";
import { IMeterDocument } from "@/types/meter-types";
import { KadunaCustomerDoc } from "@/types/customer-types";
import MeterForm from "@/components/forms/meter/MeterForm";
import { useSession } from "@/hooks/app-hooks";
import { collectionServices, powerPayServices } from "@/services/root";
import { Grid, Paper } from "@mui/material";
import { auth } from "@/configs/firebase";
import {
  // calculateAmountFromDiscount,
  generateTransactionId,
  generateUUIDV4,
  stringToArray,
} from "@/utils/funcs";
// import MeterForm from "../meter/MeterForm";
import LoadingCircle from "@/components/common/LoadingCircle";
import { APP_CURRENCY } from "@/constants/app";
import NumberFormatCustom from "@/components/common/NumberFormatCustom";
import { showSnackbar } from "@/helpers/snackbar-helpers";

import InputLabel from "@mui/material/InputLabel";
import { default as MUISelect } from "@mui/material/Select";
import Price from "@/components/common/Price";
import { LoadingButton } from "@mui/lab";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  IHaskeTokenDocument,
  KadcoThirdPartyPaymentResponse,
  TokenResponse,
} from "@/types/transaction-types";
import TokenTransactionReciept from "@/components/forms/token/TokenTransactionReciept";
import ThirdPaymentTransactionReciept from "@/components/forms/token/ThirdPaymentTransactionReciept";

const CashPayment = () => {
  const [isOn, setIsOn] = useState(true);
  const toggleSwitch = () => setIsOn(!isOn);
  const [selectedMeterNumber, setSelectedMeterNumber] = useState("");
  const [loadingKadcoCustomerInfo, setLoadingKadcoCustomerInfo] =
    useState(false);
  const [kadcoCustomerInfo, setKadcoCustomerInfo] =
    useState<KadunaCustomerDoc | undefined>(undefined);
  const spring = {
    type: "spring",
    stiffness: 700,
    damping: 30,
  };

  const [customerMeters, setCustomerMeters] =
    useState<IMeterDocument[] | undefined>(undefined);

  const [loadingMeters, setloadingMeters] = useState(true);
  const [amountTopay, setAmountTopay] = useState(0);
  const profile = useSession();
  const profileBalance = useSession().wallet.balance;
  const profileCredit = useSession().wallet?.credit;
  const [canMakePayment, setCanMakePayment] = useState(true);
  const [processing, setProcessing] = useState(false);

  const [tokenResponse, setTokenResponse] =
    useState<TokenResponse | null>(null);

  const [thirdPartyPaymentResponse, setThirdPartyPaymentResponse] =
    useState<KadcoThirdPartyPaymentResponse | null>(null);

  const [showSuccessTransactionModal, setShowSuccessTransactionModal] =
    useState(false);

  const [
    showThirdPartyPaymentTransactionModal,
    setShowThirdPartyPaymentTransactionModal,
  ] = useState(false);

  const formikRef = useRef<FormikValues | null>(null);

  const requestId = generateTransactionId();
  const tokenTransactionDocId = generateUUIDV4();

  const serviceFee = 100;

  const config: PaystackProps = {
    email: profile.email !== "" ? profile.email : "haskecustomer@gmail.com",
    amount: amountTopay * 100,
    publicKey: process.env.REACT_APP_PAYSTACK_LIVE_PUBLIC_KEY
      ? process.env.REACT_APP_PAYSTACK_LIVE_PUBLIC_KEY
      : "111",
    metadata: {
      custom_fields: [
        {
          display_name: "type",
          value: "BUY_TOKEN",
          variable_name: "BUY_TOKEN",
        },
      ],
    },
    label: "Buy token",
    reference: requestId,
  };

  const initializePayment = usePaystackPayment(config);

  const onClose = () => {
    setProcessing(false);
  };

  const handleOnSuccess = async () => {
    if (formikRef.current) {
      const _tokenTransactionDoc: Omit<IHaskeTokenDocument, "addedOn"> = {
        transactionID: tokenTransactionDocId,
        requestId: requestId,
        tokenAmount: formikRef.current.values.amount,
        serviceFee: serviceFee,
        meter: {
          meterId: formikRef.current.values.meterId,
          meterName: formikRef.current.values.meterName,
          meterNumber: formikRef.current.values.meterNumber,
        },
        userId: profile.uid,
        paymentMethod: "thirdparty",
        paymentDone: true,
        meterType:
          kadcoCustomerInfo === undefined
            ? ""
            : kadcoCustomerInfo?.isPPM
            ? "Prepaid"
            : "Postpaid",
        status: "pending",
        query: stringToArray(
          `${tokenTransactionDocId} ${formikRef.current.values.meterName} ${formikRef.current.values.meterNumber}`
        ),
        queryType: "transaction",
      };

      const { status, errorMessage } = await collectionServices.addDoc(
        "TokenTransactions",
        tokenTransactionDocId,
        {
          ..._tokenTransactionDoc,
        }
      );

      if (status === "success") {
        if (kadcoCustomerInfo?.isPPM === true) {
          const {
            status: requestTokenStatus,
            errorMessage: requqestTokenErrmsg,
            item: _tokenResponse,
          } = await powerPayServices.generateToken({
            meterNumber: formikRef.current.values.meterNumber,
            tokenType: "CreditToken",
            amount: `${formikRef.current.values.amount}`,
            tokenId: `${process.env.REACT_APP_CLIENT_ID}`,
            locationofPayment: `${
              profile.address.city ? profile.address.city : "kaduna"
            },${profile.address.state ? profile.address.state : "kaduna"}`,
            isBorrowed: false,
            employeeTokenId: `${process.env.REACT_APP_CLIENT_ID}`,
            requestId: requestId,
            modeofPayment: "card",
            paymentRefNumber: requestId,
            telephoneNumber: profile.phoneNumber,
          });

          if (
            requestTokenStatus === "success" &&
            _tokenResponse?.resultCode === "Credit:Electricity"
          ) {
            const { status: tokenGenStatus, errorMessage: tokenGenErrMsg } =
              await collectionServices.editDoc(
                "TokenTransactions",
                tokenTransactionDocId,
                {
                  status: "success",
                  tokenResponse: _tokenResponse,
                }
              );

            if (tokenGenStatus === "success") {
              setTokenResponse(_tokenResponse);
              setShowSuccessTransactionModal(true);
              setProcessing(false);
              formikRef.current.resetForm();
            }
            if (tokenGenStatus === "error") {
              showSnackbar({
                status: tokenGenStatus,
                msg: tokenGenErrMsg,
                openSnackbar: true,
              });
              setProcessing(false);
            }
          }

          if (
            requestTokenStatus === "success" &&
            _tokenResponse?.resultCode !== "Credit:Electricity"
          ) {
            showSnackbar({
              status: "warning",
              msg: `${_tokenResponse?.tokenComment}`,
              openSnackbar: true,
            });
            setProcessing(false);
          }

          if (requestTokenStatus === "error" && requqestTokenErrmsg) {
            showSnackbar({
              status: requestTokenStatus,
              msg: requqestTokenErrmsg,
              openSnackbar: true,
            });
            setProcessing(false);
          }
        }

        if (kadcoCustomerInfo?.isPPM === false) {
          let thirdpartyPaymentResponse =
            await powerPayServices.thirdPartyPayment({
              bankReceiptNo: "nil",
              modeofPayment: "haske",
              typesofPayment: "thirdparty",
              customerAccountNo: formikRef.current.values.meterNumber,
              paymentChannelAmount: `${formikRef.current.values.amount}`,
              paymentChannelDate: new Date().toDateString(),
              tokenId: `${process.env.REACT_APP_CLIENT_ID}`,
              notes: "Postpaid payment done on haske platform",
              depositSlipNo: "nil",
              bankAccountNo: "nil",
              telephoneNumber: "nil",
              iscustomer: true,
            });

          if (
            thirdpartyPaymentResponse.status === "success" &&
            typeof thirdpartyPaymentResponse.item === "object"
          ) {
            const { status: tokenGenStatus, errorMessage: tokenGenErrMsg } =
              await collectionServices.editDoc(
                "TokenTransactions",
                tokenTransactionDocId,
                {
                  status: "success",
                  paymentResponse: thirdpartyPaymentResponse.item,
                }
              );

            if (tokenGenStatus === "success") {
              setThirdPartyPaymentResponse(thirdpartyPaymentResponse.item);
              setShowThirdPartyPaymentTransactionModal(true);
              setProcessing(false);
              formikRef.current.resetForm();
            }
            if (tokenGenStatus === "error") {
              showSnackbar({
                status: tokenGenStatus,
                msg: tokenGenErrMsg,
                openSnackbar: true,
              });
              setProcessing(false);
            }
          }

          if (
            thirdpartyPaymentResponse.status === "success" &&
            typeof thirdpartyPaymentResponse.item === "string"
          ) {
            showSnackbar({
              status: "error",
              msg: thirdpartyPaymentResponse.item,
              openSnackbar: true,
            });
            setProcessing(false);
          }
          if (thirdpartyPaymentResponse.status === "error") {
            showSnackbar({
              status,
              msg: thirdpartyPaymentResponse.errorMessage ?? "",
              openSnackbar: true,
            });
            setProcessing(false);
          }
        }
      }

      if (status === "error") {
        showSnackbar({
          status,
          msg: errorMessage,
          openSnackbar: true,
        });
        setProcessing(false);
      }
    }
  };

  const onSuccess = () => {
    handleOnSuccess();
  };

  useEffect(() => {
    console.log(auth);
    // console.log(useSession());

    (async () => {
      if (profile.uid) {
        const { status, list } = await collectionServices.getDocs("Meters", [
          {
            uField: "userId",
            uid: profile.uid,
          },
        ]);

        if (status === "success" && list) {
          const _meters = list as IMeterDocument[];
          setCustomerMeters([..._meters]);
          setloadingMeters(false);
          if (_meters.length > 0) {
            // ###############################
            setIsOn(false);
          }
        }
      }
    })();
  }, [profile.uid]);

  return (
    <>
      <GeneralHeader title="Purchase Token By Cash" />
      <Container>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h2"
            color="primary"
            sx={{ fontSize: "2rem", fontWeight: "bold", textAlign: "center" }}
          >
            {" "}
            GET ELECTRICITY TOKEN WHEN YOU NEED IT!
          </Typography>
          <Spacer space={20} />
          {auth.currentUser?.uid === "1dd62327-9393-468c-8a4a-a5dbaa4a61e2" ? (
            <DashboardSectionLayout>
              <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={12} md={6}>
                  <Paper sx={{ p: 3 }}>
                    <Paper sx={{ p: 6 }}>
                      <TokenTransactionReciept
                        visible={showSuccessTransactionModal}
                        close={() => setShowSuccessTransactionModal(false)}
                        data={{
                          recieptNo: tokenTransactionDocId,
                          meterNumber: kadcoCustomerInfo?.meterNumber ?? "",
                          customerName: kadcoCustomerInfo?.customerName ?? "",
                          customerPhonenumber:
                            kadcoCustomerInfo?.customerMobileNo ?? "",
                          transactionId: tokenResponse?.transactionId ?? "",
                          units: tokenResponse?.totalUnitVended ?? "",
                          cost: amountTopay,
                          address: kadcoCustomerInfo?.customerAddress ?? "",
                          token: tokenResponse?.result ?? "",
                        }}
                      />
                      <ThirdPaymentTransactionReciept
                        visible={showThirdPartyPaymentTransactionModal}
                        close={() =>
                          setShowThirdPartyPaymentTransactionModal(false)
                        }
                        data={{
                          recieptNo: thirdPartyPaymentResponse?.receiptNo ?? 0,
                          customerName: kadcoCustomerInfo?.customerName ?? "",
                          customerPhonenumber:
                            kadcoCustomerInfo?.customerMobileNo ?? "",
                          transactionId:
                            thirdPartyPaymentResponse?.paymentId ?? "",
                          cost: amountTopay,
                          address: kadcoCustomerInfo?.customerAddress ?? "",
                        }}
                      />
                      <Formik
                        innerRef={(p) => (formikRef.current = p)}
                        key="token-form"
                        initialValues={{
                          meterId: "",
                          meterName: "",
                          meterNumber: "",
                          userId: profile.uid,
                          amount: 0,
                          payMethod: "thirdparty",
                        }}
                        onSubmit={() => {}}
                      >
                        {({
                          isSubmitting,
                          setFieldValue,
                          values,
                          resetForm,
                        }) => (
                          <div>
                            <Stack alignItems="center" justifyContent="center">
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-evenly"
                                sx={{ width: 300 }}
                              >
                                <Box
                                  sx={{
                                    width: 50,
                                    height: 20,
                                    bgcolor: "primary.main",
                                    display: "flex",
                                    // ##################################
                                    justifyContent: isOn
                                      ? "flex-end"
                                      : "flex-start",
                                    borderRadius: 5,
                                    p: 1 / 2,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    toggleSwitch();
                                    resetForm();
                                  }}
                                >
                                  <motion.div
                                    layout
                                    transition={spring}
                                    style={{
                                      width: 20,
                                      height: 20,
                                      backgroundColor: "#fff",
                                      borderRadius: 10,
                                    }}
                                  />
                                </Box>
                              </Stack>
                            </Stack>
                            <Spacer space={20} />
                            <Stack alignItems="center">
                              <Box sx={{ width: 300, pb: 20 }}>
                                <AnimatePresence mode="wait">
                                  {/* ###################### */}
                                  {!isOn ? (
                                    <motion.div
                                      initial={{ opacity: 0, y: 50 }}
                                      animate={{ opacity: 1, y: 0 }}
                                      exit={{ opacity: 0, y: 50 }}
                                      transition={{ ease: "easeInOut" }}
                                      key={"1"}
                                    >
                                      {loadingMeters && (
                                        <Stack
                                          direction="row"
                                          alignItems="center"
                                          columnGap={1}
                                        >
                                          <Typography
                                            variant="caption"
                                            color="textPrimary"
                                          >
                                            Fetching your meters
                                          </Typography>
                                          <LoadingCircle />
                                        </Stack>
                                      )}

                                      {!customerMeters && !loadingMeters && (
                                        <Typography
                                          variant="caption"
                                          color="textPrimary"
                                          textAlign="center"
                                        >
                                          You have no meters added
                                        </Typography>
                                      )}
                                      {customerMeters && !loadingMeters && (
                                        <>
                                          {customerMeters.length === 0 && (
                                            <Typography
                                              variant="caption"
                                              color="textPrimary"
                                              textAlign="center"
                                            >
                                              You have no meters added
                                            </Typography>
                                          )}
                                        </>
                                      )}
                                    </motion.div>
                                  ) : (
                                    <>
                                      {values.meterName === "" && (
                                        <motion.div
                                          key={"2"}
                                          initial={{ opacity: 0, y: 50 }}
                                          animate={{ opacity: 1, y: 0 }}
                                          exit={{ opacity: 0, y: 50 }}
                                          transition={{ ease: "easeInOut" }}
                                        >
                                          <CashMeterForm />
                                        </motion.div>
                                      )}
                                    </>
                                  )}
                                </AnimatePresence>
                                <Spacer space={20} />
                                <Stack alignItems="center">
                                  {loadingKadcoCustomerInfo && (
                                    <LoadingCircle />
                                  )}
                                </Stack>
                                {values.meterName !== "" &&
                                  kadcoCustomerInfo !== undefined && (
                                    <>
                                      <Typography
                                        variant="subtitle2"
                                        color="textPrimary"
                                      >
                                        Buy electricity for
                                      </Typography>

                                      <Grid
                                        container
                                        sx={{
                                          alignItems: "center",
                                          justifyContent: "center",
                                          mt: 2,
                                        }}
                                      >
                                        <Grid item xs={3}>
                                          <LazyLoadImage
                                            src={require("@/assets/electric-meter.png")}
                                            alt="buy for meter"
                                            style={{ width: 60, height: 60 }}
                                          />
                                        </Grid>
                                        <Grid item xs={9}>
                                          <Stack>
                                            <Typography variant="caption">
                                              {values.meterName}
                                            </Typography>
                                            <Typography variant="caption">
                                              {values.meterNumber}
                                            </Typography>
                                            {loadingKadcoCustomerInfo ? (
                                              <LoadingCircle />
                                            ) : (
                                              <>
                                                {kadcoCustomerInfo !==
                                                  undefined && (
                                                  <>
                                                    <Typography variant="caption">
                                                      {
                                                        kadcoCustomerInfo.customerName
                                                      }
                                                    </Typography>
                                                    <Typography variant="caption">
                                                      {
                                                        kadcoCustomerInfo.customerAddress
                                                      }
                                                    </Typography>
                                                    <Typography variant="caption">
                                                      {
                                                        kadcoCustomerInfo.customerMobileNo
                                                      }
                                                    </Typography>
                                                    <Typography variant="caption">
                                                      {kadcoCustomerInfo.isPPM
                                                        ? "Prepaid"
                                                        : "Postpaid"}
                                                    </Typography>
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                      <Spacer space={20} />
                                      <Field
                                        component={TextField}
                                        label="How much electricity do you want to buy?"
                                        type="text"
                                        name="amount"
                                        helperText={`Enter the amount. Minium of ${APP_CURRENCY} 100`}
                                        variant="filled"
                                        fullWidth
                                        value={values.amount}
                                        onChange={(
                                          event: ChangeEvent<HTMLInputElement>
                                        ) => {
                                          const _amount =
                                            typeof event.target.value ===
                                            "string"
                                              ? parseInt(event.target.value)
                                              : null;

                                          if (_amount && _amount < 100) {
                                            showSnackbar({
                                              status: "warning",
                                              msg: `Adjust the amount, it is lower than ${APP_CURRENCY} 100`,
                                              openSnackbar: true,
                                            });
                                          } else if (
                                            _amount &&
                                            _amount > 1000000
                                          ) {
                                            showSnackbar({
                                              status: "warning",
                                              msg: `Adjust the amount, it is greater than ${APP_CURRENCY} 1,000,000`,
                                              openSnackbar: true,
                                            });
                                          } else {
                                            setFieldValue(
                                              "amount",
                                              _amount ? _amount : 0,
                                              true
                                            );
                                            if (_amount) {
                                              setAmountTopay(
                                                serviceFee + _amount
                                              );
                                              // setAmountTopay(
                                              //   calculateAmountFromDiscount({
                                              //     amount: _amount,
                                              //     discount: 4,
                                              //     mode: "discount-amount-only",
                                              //   }) + _amount
                                              // );
                                            }
                                          }
                                        }}
                                        InputProps={{
                                          inputComponent: NumberFormatCustom,
                                        }}
                                      />
                                      {values.amount > 99 && (
                                        <>
                                          <Spacer space={10} />
                                          <Stack alignItems="center">
                                            <FormControl
                                              variant="filled"
                                              fullWidth
                                              sx={{ m: 1, width: "100%" }}
                                            >
                                              <InputLabel id="payment-method-filled-label">
                                                Select a payment method
                                              </InputLabel>
                                              <MUISelect
                                                labelId="payment-method-filled-label"
                                                id="payment-method-filled"
                                                defaultValue="thirdparty"
                                                value={values.payMethod}
                                                onChange={(
                                                  event: SelectChangeEvent
                                                ) => {
                                                  const _val = event.target
                                                    .value as string;
                                                  setFieldValue(
                                                    "payMethod",
                                                    _val,
                                                    false
                                                  );
                                                  if (_val === "balance") {
                                                    if (
                                                      values.amount >
                                                      profileBalance
                                                    ) {
                                                      setCanMakePayment(false);
                                                    } else {
                                                      setCanMakePayment(true);
                                                    }
                                                  } else if (
                                                    _val === "credit"
                                                  ) {
                                                    if (
                                                      values.amount >
                                                      profileCredit
                                                    ) {
                                                      setCanMakePayment(false);
                                                    } else {
                                                      setCanMakePayment(true);
                                                    }
                                                  } else if (
                                                    _val === "thirdparty"
                                                  ) {
                                                    setCanMakePayment(true);
                                                  }
                                                }}
                                              >
                                                <MenuItem value="balance">
                                                  Balance
                                                </MenuItem>
                                                <MenuItem value="credit">
                                                  Credit
                                                </MenuItem>
                                                <MenuItem value="thirdparty">
                                                  Paystack
                                                </MenuItem>
                                              </MUISelect>
                                            </FormControl>
                                          </Stack>
                                        </>
                                      )}
                                    </>
                                  )}

                                <Spacer space={10} />
                                {values.payMethod === "balance" && (
                                  <>
                                    {values.amount + serviceFee >
                                    profileBalance ? (
                                      <Alert
                                        variant="filled"
                                        severity="warning"
                                        sx={{ my: 1 }}
                                      >
                                        Insufficient funds to process order
                                      </Alert>
                                    ) : (
                                      <Typography
                                        variant="caption"
                                        color="textPrimary"
                                        sx={{ my: 1 }}
                                        textAlign="center"
                                      >
                                        Balance -{" "}
                                        <Price amount={profileBalance} />
                                      </Typography>
                                    )}
                                  </>
                                )}
                                {values.payMethod === "credit" && (
                                  <>
                                    {values.amount + serviceFee >
                                    profileCredit ? (
                                      <Alert
                                        variant="filled"
                                        severity="warning"
                                        sx={{ my: 1 }}
                                      >
                                        Insufficient funds to process order
                                      </Alert>
                                    ) : (
                                      <Typography
                                        variant="caption"
                                        color="textPrimary"
                                        sx={{ my: 1 }}
                                        textAlign="center"
                                      >
                                        Credit -{" "}
                                        <Price amount={profileCredit} />
                                      </Typography>
                                    )}
                                  </>
                                )}

                                <Spacer space={10} />
                                {values.meterName !== "" &&
                                  values.payMethod !== "" &&
                                  kadcoCustomerInfo !== undefined && (
                                    <>
                                      <Divider sx={{ my: 1 }} />
                                      <Typography
                                        variant="subtitle2"
                                        color="textPrimary"
                                      >
                                        Payment summary
                                      </Typography>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                      >
                                        <Typography
                                          variant="caption"
                                          color="textPrimary"
                                          sx={{ fontSize: "0.8rem" }}
                                        >
                                          Amount
                                        </Typography>
                                        <Typography
                                          variant="caption"
                                          color="textPrimary"
                                          sx={{ fontSize: "1.4rem" }}
                                        >
                                          <Price amount={values.amount} />
                                        </Typography>
                                      </Stack>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                      >
                                        <Typography
                                          variant="caption"
                                          color="textPrimary"
                                          sx={{ fontSize: "0.8rem" }}
                                        >
                                          Service fee
                                        </Typography>
                                        <Typography
                                          variant="caption"
                                          color="textPrimary"
                                          sx={{ fontSize: "1.4rem" }}
                                        >
                                          <Price
                                            amount={
                                              serviceFee
                                              //   calculateAmountFromDiscount({
                                              //   amount: values.amount,
                                              //   discount: 4,
                                              //   mode: "discount-amount-only",
                                              // })
                                            }
                                          />
                                        </Typography>
                                      </Stack>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                      >
                                        <Typography
                                          variant="caption"
                                          color="textPrimary"
                                          sx={{ fontSize: "0.8rem" }}
                                        >
                                          Total
                                        </Typography>
                                        <Typography
                                          variant="caption"
                                          color="textPrimary"
                                          sx={{ fontSize: "1.4rem" }}
                                        >
                                          <Price
                                            amount={
                                              serviceFee + values.amount
                                              // calculateAmountFromDiscount({
                                              //   amount: values.amount,
                                              //   discount: 4,
                                              //   mode: "discount-amount-only",
                                              // }) + values.amount
                                            }
                                          />
                                        </Typography>
                                      </Stack>
                                      <Stack>
                                        <LoadingButton
                                          loading={isSubmitting || processing}
                                          disabled={
                                            isSubmitting ||
                                            canMakePayment === false ||
                                            processing
                                          }
                                          variant="contained"
                                          sx={{ color: "#fff" }}
                                          onClick={async () => {
                                            setAmountTopay(
                                              serviceFee + values.amount
                                            );
                                            // setAmountTopay(
                                            //   calculateAmountFromDiscount({
                                            //     amount: values.amount,
                                            //     discount: 4,
                                            //     mode: "discount-amount-only",
                                            //   }) + values.amount
                                            // );
                                            // setRequestTokenAPIModel({
                                            //   meterNumber: values.meterNumber,
                                            //   tokenType: "CreditToken",
                                            //   amount: `${values.amount}`,
                                            //   tokenId: `${process.env.REACT_APP_CLIENT_ID}`,
                                            //   locationofPayment: `${
                                            //     profile.address.city
                                            //       ? profile.address.city
                                            //       : "kaduna"
                                            //   },${
                                            //     profile.address.state
                                            //       ? profile.address.state
                                            //       : "kaduna"
                                            //   }`,
                                            //   isBorrowed: false,
                                            //   employeeTokenId: `${process.env.REACT_APP_CLIENT_ID}`,
                                            //   requestId: requestId,
                                            //   modeofPayment: "card",
                                            //   paymentRefNumber: requestId,
                                            //   telephoneNumber: profile.phoneNumber,
                                            //   // transactionApprovalDate: "2022-11-17T09:20:15.309Z",
                                            //   // transactionValidationDate:
                                            //   //   "2022-11-17T09:20:15.309Z",
                                            // });
                                            if (
                                              values.payMethod === "balance"
                                            ) {
                                              // setProcessing(true);
                                              // await handleOnSuccess();
                                            }
                                            if (values.payMethod === "credit") {
                                              // setProcessing(true);
                                              // await handleOnSuccess();
                                            }
                                            if (
                                              values.payMethod === "thirdparty"
                                            ) {
                                              setProcessing(true);
                                              initializePayment(
                                                onSuccess,
                                                onClose
                                              );

                                              // SquadPay();
                                            }
                                          }}
                                        >
                                          Make payment
                                        </LoadingButton>
                                        {/* <SquadPay
                        text="Buy token"
                        params={config}
                        onClose={onClose}
                        onLoad={() => {}}
                        onSuccess={(res) => {
                          console.log(res);

                          onSuccess();
                        }}
                      /> */}
                                      </Stack>
                                    </>
                                  )}
                              </Box>
                            </Stack>
                          </div>
                        )}
                      </Formik>
                    </Paper>
                  </Paper>
                </Grid>
              </Grid>
            </DashboardSectionLayout>
          ) : (
            <p>Login in</p>
          )}

          {/* <Typography
              variant="body1"
              color="textPrimary"
              sx={{ textAlign: "center" }}
            >
              Haske is a secured power token vending channel that allows you
              purchase electricity token from the comfort of your home. Haske
              provides token vending via Automated kiosk system, Web vending and
              mobile POS. Haske is developed with intentions of providing
              comfort through fast and reliable vending channels.{" "}
            </Typography> */}
        </Grid>
      </Container>
    </>
  );
};

export default CashPayment;
