import EmptyList from "@/components/common/EmptyList";
import LoadingScreen from "@/components/common/LoadingScreen";
import DashboardSectionLayout from "@/components/dashboard/DashboardSectionLayout";
import DashboardHeader from "@/components/headers/DashboardHeader";
import MeterContainer from "@/components/meter/MeterContainer";
import {
  getAllDocs,
  setPageState,
  setShowLoadMoreBtn,
} from "@/helpers/collection-helpers";
import { useCollection, useSession } from "@/hooks/app-hooks";
import { useEffect } from "react";

const MgtMeters = () => {
  const collectionState = useCollection();
  const profile = useSession();

  useEffect(() => {
    (async () => {
      if (profile.uid) {
        await getAllDocs("Meters");

        setPageState("mgt");
        setShowLoadMoreBtn(true);
      }
    })();
  }, [profile.uid]);

  if (collectionState.isLoading) return <LoadingScreen />;
  if (collectionState.status === "error")
    return <EmptyList title={"An error occured"} caption="." />;

  return (
    <>
      {collectionState.status === "success" && collectionState.all.count > 0 && (
        <>
          <DashboardHeader title="Manage Meters" />
          <DashboardSectionLayout>
            <MeterContainer />
          </DashboardSectionLayout>
        </>
      )}
      {collectionState.status === "success" &&
        collectionState.all.count === 0 && (
          <EmptyList title={"Meter list is empty"} caption="." />
        )}
    </>
  );
};

export default MgtMeters;
