import {
  ADMIN_DASHBOARD,
  ADMIN_MGT_ACCOUNT,
  ADMIN_MGT_METER,
  ADMIN_MGT_REPORTS,
  ADMIN_MGT_TRANSACTIONS,
  CLIENT_ACCOUNT,
  CLIENT_ADD_METER,
  CLIENT_BUY_TOKEN,
  CLIENT_METERS,
  CLIENT_TRANSACTIONS,
  CLIENT_UPDATE_ACCOUNT,
  CLIENT_WALLET,
  DASHBOARD_BASE,
} from "@/routes/dashboard-routes";
import {
  ABOUT_US,
  CONTACT_US,
  FAQ_P,
  CASH_PAYMENT,
} from "@/routes/guest-routes";
import {
  ACCESS_RESTRICTED,
  FORGOT_PASSWORD,
  REGISTER,
  SESSION_BASE,
} from "@/routes/session-routes";
import AboutUs from "@/screens/AboutUs";
import ContactUs from "@/screens/ContactUs";
import CashPayment from "@/screens/CashPayment";
import ClientDashboard from "@/screens/dashboard/client/ClientDashboard";
import ClientMeter from "@/screens/dashboard/client/ClientMeter";
import ClientTransaction from "@/screens/dashboard/client/ClientTransaction";
import Home from "@/screens/Home";
import NotFound from "@/screens/NotFound";
import AccessRestricted from "@/screens/session/AccessRestricted";
import ForgotPassword from "@/screens/session/ForgotPassword";
import Login from "@/screens/session/Login";
import Register from "@/screens/session/Register";
import { Routes, Route, useLocation } from "react-router-dom";
import DashboardLayout from "../layouts/DashboardLayout";
import GeneralLayout from "../layouts/GeneralLayout";
import { AnimatePresence } from "framer-motion";
import ClientAccount from "@/screens/dashboard/client/ClientAccount";
import ClientWallet from "@/screens/dashboard/client/ClientWallet";
import UpdateProfile from "@/screens/session/UpdateProfile";
import ClientBuyToken from "@/screens/dashboard/client/ClientBuyToken";
import MgtDashboard from "@/screens/dashboard/admin/MgtDashboard";
import MgtAccounts from "@/screens/dashboard/admin/MgtAccounts";
import MgtReports from "@/screens/dashboard/admin/MgtReports";
import MgtTransactions from "@/screens/dashboard/admin/MgtTransactions";
import Test from "@/screens/Test";
import ClientViewTransaction from "@/screens/dashboard/client/ClientViewTransaction";
import ClientAddMeter from "@/screens/dashboard/client/ClientAddMeter";
import MgtMeters from "@/screens/dashboard/admin/MgtMeters";
import MgtViewUser from "@/screens/dashboard/admin/MgtViewUser";
import FAQ from "@/screens/FAQ";

const AppRoutes = () => {
  const location = useLocation();
  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path={`/`} element={<GeneralLayout />}>
          <Route index element={<Home />} />
          <Route path={ABOUT_US} element={<AboutUs />} />
          <Route path={CONTACT_US} element={<ContactUs />} />
          <Route path={FAQ_P} element={<FAQ />} />

          <Route path={"test"} element={<Test />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path={SESSION_BASE} element={<GeneralLayout />}>
          <Route index element={<Login />} />
          <Route path={CLIENT_UPDATE_ACCOUNT} element={<UpdateProfile />} />
          <Route path={FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route path={REGISTER} element={<Register />} />
          <Route path={ACCESS_RESTRICTED} element={<AccessRestricted />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path={DASHBOARD_BASE} element={<DashboardLayout />}>
          <Route index element={<ClientDashboard />} />
          <Route path={CLIENT_TRANSACTIONS} element={<ClientTransaction />} />
          <Route
            path={`${CLIENT_TRANSACTIONS}/:id`}
            element={<ClientViewTransaction />}
          />
          <Route path={CLIENT_METERS} element={<ClientMeter />} />
          <Route path={CLIENT_ADD_METER} element={<ClientAddMeter />} />
          <Route path={CLIENT_ACCOUNT} element={<ClientAccount />} />
          <Route path={CLIENT_WALLET} element={<ClientWallet />} />
          <Route path={CLIENT_BUY_TOKEN} element={<ClientBuyToken />} />
          <Route path={CASH_PAYMENT} element={<CashPayment />} />
          {/* Admin */}
          <Route path={ADMIN_MGT_METER} element={<MgtMeters />} />
          <Route path={ADMIN_DASHBOARD} element={<MgtDashboard />} />
          <Route path={ADMIN_MGT_ACCOUNT} element={<MgtAccounts />} />
          <Route path={`${ADMIN_MGT_ACCOUNT}/:id`} element={<MgtViewUser />} />
          <Route path={ADMIN_MGT_REPORTS} element={<MgtReports />} />
          <Route path={ADMIN_MGT_TRANSACTIONS} element={<MgtTransactions />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </AnimatePresence>
  );
};

export default AppRoutes;
