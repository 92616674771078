import { useState } from "react";
import Button from "@mui/material/Button";
import { Stack } from "@mui/material";
import WalletAddBankAccountModal from "./WalletAddBankAccountModal";
import WalletWithdrawFundModal from "./WalletWithdrawFundModal";
import WalletCouponCodeModal from "./WalletCouponCodeModal";
import Spacer from "@/components/common/Spacer";
import WalletAddFundsModal from "./WalletAddFundsModal";
import Confirmation from "@/components/modals/Confirmation";
import { showSnackbar } from "@/helpers/snackbar-helpers";
import { walletServices } from "@/services/root";
import { IAccountWallet } from "@/types/session-types";
import { pageTypes } from "@/types/pages-types";
import { reloadPage } from "@/utils/funcs";

interface Props {
  wallet: IAccountWallet | undefined;
  persona: pageTypes;
  userId: string;
}
const WalletActions = ({ wallet, persona, userId }: Props) => {
  const [addBankAccountModal, setAddBankAccountModal] = useState(false);
  const [withdrawFundsModal, setWithdrawFundsModal] = useState(false);
  const [addFundsModal, setAddFundsModal] = useState(false);
  const [couponCodeModal, setCouponCodeModal] = useState(false);
  const [confirmRemoved, setConfirmRemoved] = useState(false);

  return (
    <Stack alignItems={"center"}>
      <Confirmation
        visible={confirmRemoved}
        close={() => {
          setConfirmRemoved(!confirmRemoved);
        }}
        action={async () => {
          if (persona === "customer") {
            const { status, errorMessage, successMessage } =
              await walletServices.removeBankAccount(userId);
            if (status === "success") {
              reloadPage();
              showSnackbar({
                openSnackbar: true,
                status: "success",
                msg: successMessage,
              });
            }
            if (status === "error") {
              showSnackbar({
                openSnackbar: true,
                status: "error",
                msg: errorMessage,
              });
            }
          }

          if (persona === "mgt") {
            const { status, errorMessage, successMessage } =
              await walletServices.removeMgtBankAccount();
            if (status === "success") {
              reloadPage();
              showSnackbar({
                openSnackbar: true,
                status: "success",
                msg: successMessage,
              });
            }
            if (status === "error") {
              showSnackbar({
                openSnackbar: true,
                status: "error",
                msg: errorMessage,
              });
            }
          }
        }}
        caption="You are about to remove this bank account, proceed?"
      />

      <WalletAddBankAccountModal
        visible={addBankAccountModal}
        close={() => setAddBankAccountModal(!addBankAccountModal)}
        persona={persona}
        userId={userId}
      />
      <WalletWithdrawFundModal
        visible={withdrawFundsModal}
        close={() => setWithdrawFundsModal(!withdrawFundsModal)}
        persona={persona}
        userId={userId}
      />
      <WalletAddFundsModal
        visible={addFundsModal}
        close={() => setAddFundsModal(!addFundsModal)}
      />

      <WalletCouponCodeModal
        visible={couponCodeModal}
        close={() => setCouponCodeModal(!couponCodeModal)}
      />

      {/* {wallet?.bankAccount === undefined && (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setAddBankAccountModal(!addBankAccountModal)}
            sx={{ color: "#fff" }}
          >
            ADD BANK ACCOUNT
          </Button>
        </>
      )} */}
      {/* {wallet?.bankAccount !== undefined && (
        <> */}

      {/* <Button
        variant="contained"
        color="primary"
        onClick={() => setConfirmRemoved(!confirmRemoved)}
        sx={{ color: "#fff" }}
      >
        REMOVE BANK ACCOUNT
      </Button> */}
      {persona === "customer" && (
        <>
          <Spacer space={30} />
          <Button
            variant="contained"
            color="primary"
            onClick={() => setAddFundsModal(!addFundsModal)}
            sx={{ color: "#fff" }}
          >
            TOP UP WALLET
          </Button>
          <Spacer space={30} />
          <Button
            variant="contained"
            color="primary"
            sx={{ color: "#fff" }}
            onClick={() => {
              setCouponCodeModal(!couponCodeModal);
            }}
          >
            CREDIT FROM COUPON
          </Button>
        </>
      )}
      {/* </>
      )} */}

      {wallet?.bankAccount !== undefined &&
        wallet.balance !== undefined &&
        wallet.balance >= 1000 && (
          <>
            <Spacer space={30} />
            <Button
              variant="contained"
              color="primary"
              onClick={() => setWithdrawFundsModal(!withdrawFundsModal)}
              sx={{ color: "#fff" }}
            >
              WITHDRAW FUNDS
            </Button>
          </>
        )}
    </Stack>
  );
};

export default WalletActions;
