import { CLIENT_TRANSACTIONS, DASHBOARD_BASE } from "@/routes/dashboard-routes";
import { IHaskeTokenDocument } from "@/types/transaction-types";
import { Box, Grid, Typography, Button, Stack } from "@mui/material";
import moment from "moment";

interface Props {
  transaction: IHaskeTokenDocument;
}
const TransactionListItem = ({ transaction }: Props) => {
  return (
    <Box boxShadow={2} sx={{ my: 1, p: 1 }}>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} md={2}>
          <Typography
            variant="caption"
            color="textPrimary"
            sx={{ fontSize: 8 }}
          >
            {moment(transaction?.addedOn.toDate()).format("lll")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="caption" color="textPrimary">
            {transaction?.meter?.meterName} (
            {transaction?.meterType !== "Postpaid" ? "Prepaid" : "Postpaid"})
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Stack>
            <Typography variant="caption" color="textPrimary">
              {new Intl.NumberFormat(undefined, {
                style: "currency",
                currency: "NGN",
              }).format(transaction?.tokenAmount + transaction.serviceFee)}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={1}>
          <Box
            sx={{
              bgcolor:
                transaction.status === "success"
                  ? "green"
                  : transaction.status === "pending"
                  ? "orange"
                  : transaction.status === "fail"
                  ? "red"
                  : "white",
              color: "#fff",
              width: "fit-content",
              p: 1,
              borderRadius: 4,
              fontSize: 8,
            }}
          >
            {transaction.status === "success"
              ? "SUCCESS"
              : transaction.status === "pending"
              ? "PENDING"
              : transaction.status === "fail"
              ? "FAIL"
              : "FAIL"}
          </Box>
        </Grid>
        <Grid item xs={12} md={1}>
          <Button
            variant="text"
            color="primary"
            href={`/${DASHBOARD_BASE}/${CLIENT_TRANSACTIONS}/${transaction.transactionID}`}
            target="_blank"
          >
            View
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TransactionListItem;
