import GeneralHeader from "@/components/headers/GeneralHeader";
import { Container, Grid } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";

const FAQ = () => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      <GeneralHeader title="Frequently Asked Questions" />
      <Container>
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} md={6}>
            <div>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ flexShrink: 0 }}>
                    What is Haske ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Haske is a secured power token vending channel that allows
                    you purchase electricity token from the comfort of your
                    home.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography sx={{ flexShrink: 0 }}>
                    How do I get started?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Simply input your phone number in the “Enter phone number”
                    box to get started. You’re only required to do this ones.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <Typography sx={{ flexShrink: 0 }}>
                    Can I view my transaction history?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Kindly click on the transaction on your dashboard to view
                    all your transaction history. The receipt for all
                    transactions can be viewed by clicking on the receipt button
                    under status
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography sx={{ flexShrink: 0 }}>
                    How can I request for Meter?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Kindly scroll to the top page and tap on our phone number to
                    reach us via WhatsApp. Our agents are always available to
                    attend to you.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5bh-content"
                  id="panel5bh-header"
                >
                  <Typography sx={{  flexShrink: 0 }}>
                    Why is the meter rejecting my token?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Check that the meter number you purchased is right for your
                    meter. The meter has not yet been activated- An activation
                    code will be required from the distribution company.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default FAQ;
