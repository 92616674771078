import { Box, Grid, Paper, Typography } from "@mui/material";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  caption: string;
}

const SessionFormLayout = ({ children, caption }: Props) => {
  return (
    <Box sx={{ p: { xs: 1, md: 0 } }}>
      <Grid container>
        <Grid item xs={12} md={4}></Grid>
        <Grid item xs={12} md={4}>
          <Typography
            variant="subtitle2"
            color="textPrimary"
            textAlign="center"
          >
            {caption}
          </Typography>
          <Paper sx={{ p: 1 }}>{children}</Paper>
        </Grid>
        <Grid item xs={12} md={4}></Grid>
      </Grid>
    </Box>
  );
};

export default SessionFormLayout;
