import {
  Box,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
  Button,
  Divider,
  Link,
  // TextField,
  // InputAdornment,
} from "@mui/material";
import AppBrand from "../global/AppBrand";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocationOnIcon from "@mui/icons-material/LocationOn";
// import SmartphoneIcon from "@mui/icons-material/Smartphone";
// import { ChangeEvent, useState } from "react";
// import { showSnackbar } from "@/helpers/snackbar-helpers";
// import SendIcon from "@mui/icons-material/Send";

import { ABOUT_US, CONTACT_US, FAQ_P } from "@/routes/guest-routes";

const GeneralFooter = () => {
  // const [needMeterPhonenumber, setNeedMeterPhonenumber] = useState("");

  return (
    <>
      <Container>
        <Stack alignItems="center" sx={{ my: 8 }}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            textAlign="center"
            sx={{ mb: 1, fontSize: "2em", fontWeight: "bold" }}
          >
            Do you need a meter?
            {/* <Typography color="primary" component="b" sx={{ fontSize: "1em" }}>
              Apply now
            </Typography> */}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            href="https://api.whatsapp.com/send?phone=234903811840&text=%F0%9F%91%8D%20I%20need%20a%20meter"
            sx={{ fontSize: "2em", color: "#fff" }}
          >
            {" "}
            Apply now
          </Button>
          {/* <Box sx={{ borderRadius: 20, width: { xs: "90vw", md: 400 } }}>
            <TextField
              type="text"
              value={needMeterPhonenumber}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setNeedMeterPhonenumber(event.target.value as string);
              }}
              fullWidth
              helperText="Enter phone number"
              name="phoneNumber"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SmartphoneIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      disabled={needMeterPhonenumber.length < 5}
                      // href={`mailto:kayodematthew56@gmail.com?subject = I need a meter&body = My phone number is ${needMeterPhonenumber}`}
                      href={`mailto:customercare@virtualswitch.org?subject = I need a meter&body = My phone number is ${needMeterPhonenumber}`}
                      onClick={() => {
                        if (needMeterPhonenumber.length < 5) {
                          showSnackbar({
                            status: "error",
                            msg: "Kindly enter a valid phone number",
                            openSnackbar: true,
                          });
                        } else {
                        }
                      }}
                    >
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box> */}
        </Stack>
      </Container>
      <Box component="footer" sx={{ bgcolor: "background.default", pb: 10 }}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Stack sx={{ alignItems: { xs: "center", md: "flex-start" } }}>
                <AppBrand size="large" />
              </Stack>
            </Grid>
            <Grid item xs={12} md={2}>
              <Stack sx={{ alignItems: { xs: "center", md: "flex-start" } }}>
                <Link
                  color="inherit"
                  href={`/`}
                  sx={{ pl: 1, textDecoration: "none",mb:2 }}
                >
                  Home
                </Link>
                <Link
                  color="inherit"
                  href={`/${ABOUT_US}`}
                  sx={{ pl: 1, textDecoration: "none",mb:2 }}
                >
                  About us
                </Link>
                <Link
                  color="inherit"
                  href={`/${CONTACT_US}`}
                  sx={{ pl: 1, textDecoration: "none",mb:2 }}
                >
                  Contact us
                </Link>
                <Link
                  color="inherit"
                  href={`/${FAQ_P}`}
                  sx={{ pl: 1, textDecoration: "none",mb:2 }}
                >
                  FAQ
                </Link>
              </Stack>
            </Grid>
            <Grid item xs={12} md={2}>
              <Stack sx={{ alignItems: { xs: "center", md: "flex-start" } }}>
                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  sx={{ mb: 1 }}
                >
                  Privacy Policy
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  sx={{ mb: 1 }}
                >
                  Terms and Condition
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack sx={{ alignItems: { xs: "center", md: "flex-start" } }}>
                <Stack direction="row">
                  <IconButton>
                    <FacebookIcon />
                  </IconButton>
                  <IconButton>
                    <InstagramIcon />
                  </IconButton>
                  <IconButton>
                    <TwitterIcon />
                  </IconButton>
                  <IconButton>
                    <LinkedInIcon />
                  </IconButton>
                </Stack>

                <Stack sx={{ alignItems: { xs: "center", md: "flex-start" } }}>
                  <Button href="mailto:contact@haske.com" variant="text">
                    contact@haske.com
                  </Button>

                  <Button href="tel:+2349039218110" variant="text">
                    012279295
                  </Button>
                </Stack>
                <Stack direction="row">
                  <LocationOnIcon />
                  <Typography variant="subtitle1" color="textPrimary">
                    36 Nouchotte street, Wuse Zone 1. Abuja , Nigeria.
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Container>
        <Divider sx={{ my: 5 }} />
        <Typography variant="body1" color="textPrimary" textAlign="center">
          &copy;2023 Haske. All Rights Reserved.
        </Typography>
      </Box>
    </>
  );
};

export default GeneralFooter;
