// import LoginForm from "@/components/forms/session/LoginForm";
import SignInForm from "@/components/forms/session/SignInForm";
import GeneralHeader from "@/components/headers/GeneralHeader";
import SessionFormLayout from "@/components/session/SessionFormLayout";

const Login = () => {
  return (
    <>
      <GeneralHeader title="Login" />
      <SessionFormLayout caption="Welcome back.">
        <SignInForm />
      </SessionFormLayout>
    </>
  );
};

export default Login;
