import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";
import { Divider, IconButton } from "@mui/material";
import WithdrawFundsForm from "@/components/forms/wallet/WithdrawFundsForm";
import { Close } from "@mui/icons-material";
import { pageTypes } from "@/types/pages-types";

interface Props {
  setVisible?: any;
  visible: boolean;
  close?: any;
  persona: pageTypes;
  userId: string;
}

const WalletWithdrawFundModal = ({
  visible,
  close,
  persona,
  userId,
  setVisible,
}: Props) => {
  return (
    <>
      {" "}
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 4,
          marginLeft: 0,
        }}
        open={visible}
      >
        <Box
          sx={{
            minHeight: 100,
            width: { xs: "100vw", md: 600 },
            borderRadius: 5,
            bgcolor: "background.paper",
            pt: 2,
            position: "relative",
          }}
        >
          <div style={{ position: "absolute", top: 0, left: 0 }}>
            <IconButton
              onClick={() => {
                if (close) {
                  close();
                } else {
                  setVisible(!visible);
                }
              }}
            >
              <Close />
            </IconButton>
          </div>
          <Typography
            variant="subtitle2"
            color="textPrimary"
            textAlign={"center"}
          >
            Withdraw funds
          </Typography>
          <Divider sx={{ my: 2 }} />
          <WithdrawFundsForm persona={persona} userId={userId} />
        </Box>
      </Backdrop>
    </>
  );
};

export default WalletWithdrawFundModal;
