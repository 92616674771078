import { ReactNode, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "@/configs/firebase";
import { resetProfile, setProfile } from "@/helpers/session-helpers";
import { IAccountDocument } from "@/types/session-types";
import { collectionServices } from "@/services/root";

// declare global {
//   interface Window {
//     recaptchaVerifier: RecaptchaVerifier;
//   }
// }

type props = {
  children: ReactNode;
};
const AppAuth = ({ children }: props) => {
  useEffect(() => {
    onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        // User is signed in

        if (currentUser.uid !== undefined) {
          const { status, item } = await collectionServices.getDoc(
            "Users",
            currentUser.uid
          );

          if (status === "success" && item) {
            const data = item as Omit<IAccountDocument, "uid">;

            const profile: IAccountDocument & {
              isLoaded: boolean;
              isEmpty: boolean;
            } = {
              uid: currentUser.uid,
              firstName: data.firstName,
              lastName: data.lastName,
              username: data.username,
              email: data.email,
              phoneNumber: data.phoneNumber,
              persona: data.persona,
              hash:data.hash,
              uniqueId:data.uniqueId,
              photo: data.photo ? data.photo : { name: "", url: "" },
              address: data.address
                ? data.address
                : {
                    state: "",
                    city: "",
                  },
              wallet: data.wallet
                ? data.wallet
                : {
                    balance: 0,
                    pendingPayout: 0,
                    totalCommission: 0,
                    credit: 0,
                  },
              metadata: {
                createdOn: data.metadata.createdOn,
                status: data.metadata.status,
                query: data.metadata.query,
                queryType: data.metadata.queryType,
              },

              isLoaded: true,
              isEmpty: false,
            };
            setProfile(profile);
          }
        }
      } else {
        // User is signed out
        resetProfile();
      }
    });
  }, []);
  return <>{children}</>;
};

export default AppAuth;
