import GeneralHeader from "@/components/headers/GeneralHeader";
import {
  Button,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const ContactUs = () => {
  return (
    <>
      <GeneralHeader title="Contact us" />;
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6}>
            <Stack alignItems="center">
              <Stack direction="row">
                <IconButton>
                  <FacebookIcon />
                </IconButton>
                <IconButton>
                  <InstagramIcon />
                </IconButton>
                <IconButton>
                  <TwitterIcon />
                </IconButton>
                <IconButton>
                  <LinkedInIcon />
                </IconButton>
              </Stack>

              <Button href="mailto:contact@haske.tech" variant="text">
                contact@haske.tech
              </Button>

              <Button href="tel:+2349039218110" variant="text">
                012279295
              </Button>

              <Stack direction="row">
                <LocationOnIcon />
                <Typography variant="subtitle1" color="textPrimary">
                  36 Nouakchott Street, wuse zone 1
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ContactUs;
