import DashboardHeader from "@/components/headers/DashboardHeader";
import WalletContainer from "@/components/wallet/WalletContainer";
import { setPageState } from "@/helpers/collection-helpers";
import { useSession } from "@/hooks/app-hooks";
import { useEffect } from "react";

const ClientWallet = () => {
  useEffect(() => {
    setPageState("customer");
  }, []);

  const profileID = useSession().uid;
  const wallet = useSession().wallet;
  if (!profileID) return <p>Loading...</p>;

  return (
    <>
      <DashboardHeader title="My Wallet" />
      <WalletContainer userId={profileID} persona="customer" wallet={wallet} />
    </>
  );
};

export default ClientWallet;
