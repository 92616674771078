import { useCollection } from "@/hooks/app-hooks";
import { generateUUIDV4 } from "@/utils/funcs";
import { Grid, Typography } from "@mui/material";
import MeterListItem from "./MeterListItem";

const MeterList = () => {
  const collection = useCollection();
  return (
    <div>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} md={2}>
          <Typography variant="caption" color="textPrimary">
            Date
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant="caption" color="textPrimary">
            Meter number
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant="caption" color="textPrimary">
            Meter name
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="caption" color="textPrimary">
            Meter Type
          </Typography>
        </Grid>

        <Grid item xs={12} md={1}>
          <Typography variant="caption" color="textPrimary">
            Action
          </Typography>
        </Grid>
      </Grid>
      {collection.view.list?.map((meter) => (
        <MeterListItem key={generateUUIDV4()} meter={meter} />
      ))}
    </div>
  );
};

export default MeterList;
