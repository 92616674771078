import {
  IAddToUserWallet,
  ICreatePSTransferReceiptCode,
  IGetAccountDetailsParams,
  IGetBankParams,
  IGetCustomerTransactions,
  IInstantPSInitiateTransfer,
  IMGTFinance,
  IMoveFundFromCommissionToBalance,
  IWalletTransactionDocument,
  IWalletTransactionInput,
} from "@/types/wallet-types";

import { FirebaseError } from "firebase/app";
import {
  DocQueryResponse,
  listQueryAsyncResponse,
  mutationResponse,
} from "@/types/promise-types";
import WalletRepository from "@/repositories/wallet-repository";
import { generateUUIDV4 } from "@/utils/funcs";
import { APP_CURRENCY } from "@/constants/app";

class WalletServices {
  private respository: WalletRepository;

  constructor() {
    this.respository = new WalletRepository();
  }
  async getbanks({
    country,
    currency,
  }: IGetBankParams): Promise<mutationResponse> {
    try {
      const { status, data } = await this.respository.getbanks({
        currency,
        country,
      });
      if (status === 200) {
        return {
          status: "success",
          successMessage: "Bank list recieved",
          data: data.data,
          errorMessage: "",
        };
      }
      return {
        status: "error",
        errorMessage: "Error! Bank list could not be recieved",
        data: {},
        successMessage: "",
      };
    } catch (error: unknown) {
      let errorMsg = "Error! Bank list could not be recieved";
      if (error instanceof FirebaseError) errorMsg = error.message;
      return {
        status: "error",
        errorMessage: errorMsg,
        successMessage: "",
      };
    }
  }
  async resolveAccountDetails({
    accountNumber,
    bankCode,
  }: IGetAccountDetailsParams): Promise<mutationResponse> {
    try {
      const { status, data } = await this.respository.resolveAccountDetails({
        accountNumber,
        bankCode,
      });
      if (status === 200) {
        return {
          status: "success",
          successMessage: "resolved account successfully",
          data: data.data,
          errorMessage: "",
        };
      }
      return {
        status: "error",
        errorMessage: "Error! could not resolve account",
        data: {},
        successMessage: "",
      };
    } catch (error: any) {
      // let errorMsg = "Error! could not resolve account";
      // if (error instanceof FirebaseError) errorMsg = error.message;
      return {
        status: "error",
        errorMessage: error.message,
        successMessage: "",
      };
    }
  }
  async getCustomerTransactions({
    customer,
    page,
    perPage,
  }: IGetCustomerTransactions): Promise<mutationResponse> {
    try {
      const { status, data } = await this.respository.getCustomerTransactions({
        customer,
        page,
        perPage,
      });
      if (status === 200) {
        return {
          status: "success",
          successMessage: "transaction records recieved successfully",
          data: data,
          errorMessage: "",
        };
      }
      return {
        status: "error",
        errorMessage: "Error! could not recieve transaction records",
        data: {},
        successMessage: "",
      };
    } catch (error: unknown) {
      // console.log("error", error);

      let errorMsg = "Error! could not recieve transaction records";
      if (error instanceof FirebaseError) errorMsg = error.message;
      return {
        status: "error",
        errorMessage: errorMsg,
        successMessage: "",
      };
    }
  }
  async createPSTransferReceiptCode({
    persona,
    accountName,
    accountNumber,
    bankCode,
    userId,
    bankName,
  }: ICreatePSTransferReceiptCode): Promise<mutationResponse> {
    try {
      const { status, data } =
        await this.respository.createPSTransferReceiptCode({
          accountName,
          accountNumber,
          bankCode,
          persona,
        });

      await this.respository.updateUserPSTransferReceiptCode({
        userId: userId ? userId : "empty",
        accountName,
        accountNumber,
        bankCode,
        bankName: bankName ? bankName : "empty",
        psrecieptCode: data.data.recipient_code,
        persona,
      });

      if (status === 201) {
        return {
          status: "success",
          successMessage: "created account transfer reciept successfully",
          data: data.data,
          errorMessage: "",
        };
      }
      return {
        status: "error",
        errorMessage: "Error! could not create account transfer reciept",
        data: {},
        successMessage: "",
      };
    } catch (error: unknown) {
      let errorMsg = "Error! could not create account transfer reciept";
      if (error instanceof FirebaseError) errorMsg = error.message;
      return {
        status: "error",
        errorMessage: errorMsg,
        successMessage: "",
      };
    }
  }
  async createBrandPSTransferReceiptCode({
    persona,
    accountName,
    accountNumber,
    bankCode,
    userId,
    bankName,
  }: ICreatePSTransferReceiptCode): Promise<mutationResponse> {
    try {
      const { status, data } =
        await this.respository.createPSTransferReceiptCode({
          accountName,
          accountNumber,
          bankCode,
          persona,
        });

      await this.respository.updateBrandPSTransferReceiptCode({
        userId: userId ? userId : "empty",
        accountName,
        accountNumber,
        bankCode,
        bankName: bankName ? bankName : "empty",
        psrecieptCode: data.data.recipient_code,
        persona,
      });

      if (status === 201) {
        return {
          status: "success",
          successMessage: "created account transfer reciept successfully",
          data: data.data,
          errorMessage: "",
        };
      }
      return {
        status: "error",
        errorMessage: "Error! could not create account transfer reciept",
        data: {},
        successMessage: "",
      };
    } catch (error: unknown) {
      let errorMsg = "Error! could not create account transfer reciept";
      if (error instanceof FirebaseError) errorMsg = error.message;
      return {
        status: "error",
        errorMessage: errorMsg,
        successMessage: "",
      };
    }
  }
  async createMgtPSTransferReceiptCode({
    persona,
    accountName,
    accountNumber,
    bankCode,
    userId,
    bankName,
  }: ICreatePSTransferReceiptCode): Promise<mutationResponse> {
    try {
      const { status, data } =
        await this.respository.createPSTransferReceiptCode({
          accountName,
          accountNumber,
          bankCode,
          persona,
        });

      await this.respository.updateMgtPSTransferReceiptCode({
        userId: userId ? userId : "empty",
        accountName,
        accountNumber,
        bankCode,
        bankName: bankName ? bankName : "empty",
        psrecieptCode: data.data.recipient_code,
        persona,
      });

      if (status === 201) {
        return {
          status: "success",
          successMessage: "created account transfer reciept successfully",
          data: data.data,
          errorMessage: "",
        };
      }
      return {
        status: "error",
        errorMessage: "Error! could not create account transfer reciept",
        data: {},
        successMessage: "",
      };
    } catch (error: unknown) {
      let errorMsg = "Error! could not create account transfer reciept";
      if (error instanceof FirebaseError) errorMsg = error.message;
      return {
        status: "error",
        errorMessage: errorMsg,
        successMessage: "",
      };
    }
  }

  async removeBankAccount(userId: string): Promise<mutationResponse> {
    try {
      await this.respository.removeBankAccount(userId);

      return {
        status: "success",
        successMessage: "bank account removed successfully",
        errorMessage: "",
      };
    } catch (error: unknown) {
      let errorMsg = "Error! could not remove bank account";
      if (error instanceof FirebaseError) errorMsg = error.message;
      return {
        status: "error",
        errorMessage: errorMsg,
        successMessage: "",
      };
    }
  }
  async removeBrandBankAccount(userId: string): Promise<mutationResponse> {
    try {
      await this.respository.removeBrandBankAccount(userId);

      return {
        status: "success",
        successMessage: "bank account removed successfully",
        errorMessage: "",
      };
    } catch (error: unknown) {
      let errorMsg = "Error! could not remove bank account";
      if (error instanceof FirebaseError) errorMsg = error.message;
      return {
        status: "error",
        errorMessage: errorMsg,
        successMessage: "",
      };
    }
  }
  async removeMgtBankAccount(): Promise<mutationResponse> {
    try {
      await this.respository.removeMgtBankAccount();

      return {
        status: "success",
        successMessage: "bank account removed successfully",
        errorMessage: "",
      };
    } catch (error: unknown) {
      let errorMsg = "Error! could not remove bank account";
      if (error instanceof FirebaseError) errorMsg = error.message;
      return {
        status: "error",
        errorMessage: errorMsg,
        successMessage: "",
      };
    }
  }
  async instantPSInitiateTransfer({
    amount,
    psrecieptCode,
    reason,
    userId,
  }: IInstantPSInitiateTransfer): Promise<mutationResponse> {
    try {
      const instantPSInitiateTransferPromise =
        this.respository.instantPSInitiateTransfer({
          amount,
          psrecieptCode,
          reason,
          userId,
        });
      const deductFromUserWalletPromise = this.respository.deductFromUserWallet(
        {
          userId,
          amount,
        }
      );

      await Promise.all([
        instantPSInitiateTransferPromise,
        deductFromUserWalletPromise,
      ]);

      return {
        status: "success",
        successMessage: "Tranfer of funds processed",
        errorMessage: "",
      };
    } catch (error: any) {
      return {
        status: "error",
        errorMessage: error.message,
        successMessage: "",
      };
    }
  }
  async instantBrandPSInitiateTransfer({
    amount,
    psrecieptCode,
    reason,
    userId,
  }: IInstantPSInitiateTransfer): Promise<mutationResponse> {
    try {
      const instantPSInitiateTransferPromise =
        this.respository.instantPSInitiateTransfer({
          amount,
          psrecieptCode,
          reason,
          userId,
        });
      const deductFromBrandWalletPromise =
        this.respository.deductFromBrandWallet({
          userId,
          amount,
        });

      await Promise.all([
        instantPSInitiateTransferPromise,
        deductFromBrandWalletPromise,
      ]);

      return {
        status: "success",
        successMessage: "Tranfer of funds processed",
        errorMessage: "",
      };
    } catch (error: any) {
      return {
        status: "error",
        errorMessage: error.message,
        successMessage: "",
      };
    }
  }

  async topUpWallet({
    userId,
    amount,
  }: IAddToUserWallet): Promise<mutationResponse> {
    try {
      const addToUserWalletPromise = this.respository.addToUserWallet({
        userId,
        amount,
      });
      const recordWalletTransactionUserPromise =
        this.respository.recordWalletTransaction({
          uid: generateUUIDV4(),
          userId: userId,
          amount: amount,
          type: "top-up",
          space: "balance",
          persona: "customer",
          remark: `Account was funded with ${APP_CURRENCY}${amount}`,
        });

      await Promise.all([
        addToUserWalletPromise,
        recordWalletTransactionUserPromise,
      ]);

      return {
        status: "success",
        successMessage: "wallet top up successfully",
        errorMessage: "",
      };
    } catch (error: unknown) {
      let errorMsg = "Error! could not top up wallet";
      if (error instanceof FirebaseError) errorMsg = error.message;
      return {
        status: "error",
        errorMessage: errorMsg,
        successMessage: "",
      };
    }
  }
  async addToUserWallet({
    userId,
    amount,
  }: IAddToUserWallet): Promise<mutationResponse> {
    try {
      await this.respository.addToUserWallet({
        userId,
        amount,
      });

      return {
        status: "success",
        successMessage: "wallet top up successfully",
        errorMessage: "",
      };
    } catch (error: unknown) {
      let errorMsg = "Error! could not top up wallet";
      if (error instanceof FirebaseError) errorMsg = error.message;
      return {
        status: "error",
        errorMessage: errorMsg,
        successMessage: "",
      };
    }
  }
  async addToUserCredit({
    userId,
    amount,
  }: IAddToUserWallet): Promise<mutationResponse> {
    try {
      await this.respository.addToUserCredit({
        userId,
        amount,
      });

      return {
        status: "success",
        successMessage: "wallet top up successfully",
        errorMessage: "",
      };
    } catch (error: unknown) {
      let errorMsg = "Error! could not top up wallet";
      if (error instanceof FirebaseError) errorMsg = error.message;
      return {
        status: "error",
        errorMessage: errorMsg,
        successMessage: "",
      };
    }
  }
  async moveFundFromCommissionToBalance({
    userId,
    amount,
  }: IMoveFundFromCommissionToBalance): Promise<mutationResponse> {
    try {
      const moveFundFromCommissionToBalancePromise =
        this.respository.moveFundFromCommissionToBalance({ userId, amount });
      //   const recordWalletTransactionUserPromise = recordWalletTransaction({
      //     uid: generateUUIDV4(),
      //     userId: userId,
      //     amount: amount,
      //     type: "tranfer",
      //     space: "total-commission",
      //     persona: "user",
      //     remark: "",
      //   });
      await Promise.all([
        moveFundFromCommissionToBalancePromise,
        // recordWalletTransactionUserPromise,
      ]);

      return {
        status: "success",
        successMessage: "wallet top up successfully",
        errorMessage: "",
      };
    } catch (error: any) {
      return {
        status: "error",
        errorMessage: error.message,
        successMessage: "",
      };
    }
  }
  async recordWalletTransaction(
    values: IWalletTransactionInput
  ): Promise<mutationResponse> {
    try {
      await this.respository.recordWalletTransaction(values);

      return {
        status: "success",
        successMessage: "wallet transaction recorded successfully",
        errorMessage: "",
      };
    } catch (error: any) {
      return {
        status: "error",
        errorMessage: error.message,
        successMessage: "",
      };
    }
  }

  async getWalletTransactions(
    userId: string
  ): Promise<listQueryAsyncResponse<IWalletTransactionDocument>> {
    try {
      const querySnapshot = await this.respository.getWalletTransactions(
        userId
      );
      const isCollectionEmpty = querySnapshot.size === 0;

      const list: any[] = [];
      if (!isCollectionEmpty) {
        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
      }

      const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

      return {
        status: "success",
        errorMessage: "no error",
        list: list,
        lastDoc: lastDoc,
        loading: false,
        isEmpty: isCollectionEmpty,
      };
    } catch (error: any) {
      return {
        status: "error",
        errorMessage: error.message,
        list: [],
        lastDoc: {},
        loading: false,
        isEmpty: true,
      };
    }
  }
  async fetchMoreWalletTransactions(
    userId: string,
    lstDoc: any
  ): Promise<listQueryAsyncResponse<IWalletTransactionDocument>> {
    try {
      const querySnapshot = await this.respository.fetchMoreWalletTransactions(
        userId,
        lstDoc
      );
      const isCollectionEmpty = querySnapshot.size === 0;

      const list: any[] = [];
      if (!isCollectionEmpty) {
        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
      }

      const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

      return {
        status: "success",
        errorMessage: "no error",
        list: list,
        lastDoc: lastDoc,
        loading: false,
        isEmpty: isCollectionEmpty,
      };
    } catch (error: any) {
      return {
        status: "error",
        errorMessage: error.message,
        list: [],
        lastDoc: {},
        loading: false,
        isEmpty: true,
      };
    }
  }
  async getMgtWalletTransactions(): Promise<
    listQueryAsyncResponse<IWalletTransactionDocument>
  > {
    try {
      const querySnapshot = await this.respository.getMgtWalletTransactions();
      const isCollectionEmpty = querySnapshot.size === 0;

      const list: any[] = [];
      if (!isCollectionEmpty) {
        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
      }

      const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

      return {
        status: "success",
        errorMessage: "no error",
        list: list,
        lastDoc: lastDoc,
        loading: false,
        isEmpty: isCollectionEmpty,
      };
    } catch (error: any) {
      return {
        status: "error",
        errorMessage: error.message,
        list: [],
        lastDoc: {},
        loading: false,
        isEmpty: true,
      };
    }
  }
  async fetchMoreMgtWalletTransactions(
    lstDoc: any
  ): Promise<listQueryAsyncResponse<IWalletTransactionDocument>> {
    try {
      const querySnapshot =
        await this.respository.fetchMoreMgtWalletTransactions(lstDoc);
      const isCollectionEmpty = querySnapshot.size === 0;

      const list: any[] = [];
      if (!isCollectionEmpty) {
        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
      }

      const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

      return {
        status: "success",
        errorMessage: "no error",
        list: list,
        lastDoc: lastDoc,
        loading: false,
        isEmpty: isCollectionEmpty,
      };
    } catch (error: any) {
      return {
        status: "error",
        errorMessage: error.message,
        list: [],
        lastDoc: {},
        loading: false,
        isEmpty: true,
      };
    }
  }

  async getMgtFinance(): Promise<DocQueryResponse<IMGTFinance>> {
    try {
      const doc = await this.respository.getMgtFinance();
      if (doc.exists()) {
        const data = doc.data() as IMGTFinance;
        return {
          status: "success",
          item: {
            ...data,
          },
        };
      }
      return {
        status: "success",
      };
    } catch (error: any) {
      return {
        status: "error",
        errorMessage: error.message,
      };
    }
  }
}

export default WalletServices;
