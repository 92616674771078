import { CLIENT_BUY_TOKEN, DASHBOARD_BASE } from "@/routes/dashboard-routes";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const BuyTokenBtn = () => {
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => {
        navigate(`/${DASHBOARD_BASE}/${CLIENT_BUY_TOKEN}`);
      }}
      variant="contained"
    >
      Buy electricity
    </Button>
  );
};

export default BuyTokenBtn;
