import { Grid } from "@mui/material";
import Spacer from "../common/Spacer";
import UserSearch from "./UserSearch";
import UsersFilter from "./UsersFilter";
import UsersList from "./UsersList";
import UsersLoadMore from "./UsersLoadMore";

const UsersContainer = () => {
  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={12} md={6}>
          <UserSearch />
        </Grid>
        <Grid item xs={12} md={6}>
          <UsersFilter />
        </Grid>
      </Grid>
      <Spacer space={20} />
      <UsersList />
      <Spacer space={20} />
      <UsersLoadMore />
    </>
  );
};

export default UsersContainer;
