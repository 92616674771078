import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import { useState } from "react";
import Stack from "@mui/material/Stack";
import LaunchIcon from "@mui/icons-material/Launch";
import { useNavigate } from "react-router-dom";
import { CLIENT_BUY_TOKEN, DASHBOARD_BASE } from "@/routes/dashboard-routes";
import { collectionServices, powerPayServices } from "@/services/root";
import Alert from "@mui/material/Alert";
import { SESSION_BASE } from "@/routes/session-routes";
import { LoadingButton } from "@mui/lab";
import LoadingCircle from "@/components/common/LoadingCircle";
import { showSnackbar } from "@/helpers/snackbar-helpers";
// import { generateUUIDV4 } from "@/utils/funcs";
import { sessionRepository } from "@/repositories/root";
import { serverTimestamp } from "firebase/firestore";
import { generateUUIDV4, stringToArray } from "@/utils/funcs";
import { auth } from "@/configs/firebase";

interface ButtonProps {
  valid?: boolean;
  action?: () => void;
}

const QuickBuyTokenForm = () => {
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [phoneNumber, setPhoneNumber] = useState("");
  const [checkingPhonenumber, setCheckingPhonenumber] = useState(false);
  const [phoneNumberExists, setPhoneNumberExists] = useState(false);

  const [password, setpassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const [creatingAccount, setCreatingAccount] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const Buttons = ({ valid = false, action }: ButtonProps) => {
    return (
      <Box sx={{ mb: 2 }}>
        <div>
          <LoadingButton
            loading={creatingAccount}
            variant="contained"
            onClick={() => {
              if (action) {
                action();
              } else {
                handleNext();
              }
            }}
            sx={{ mt: 1, mr: 1, color: "#fff" }}
            disabled={!valid}
          >
            Continue
          </LoadingButton>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mt: 1, mr: 1 }}
          >
            Back
          </Button>
        </div>
      </Box>
    );
  };

  return (
    <Paper sx={{ p: { sm: 2, md: 5 }, borderRadius: 2 }}>
      <Typography
        variant="h1"
        sx={{
          fontWeight: "bold",
          mb: 2,
          fontSize: "2em",
          textAlign: { xs: "center", md: "left" },
        }}
      >
        Top-up your electricity meter instantly
      </Typography>
      {auth.currentUser?.uid === undefined && (
        <>
          <Stepper activeStep={activeStep} orientation="vertical">
            <Step>
              <StepLabel>Enter phone number to get started</StepLabel>
              <StepContent>
                <TextField
                  id="phonenumber"
                  value={phoneNumber}
                  onChange={async (
                    event: React.ChangeEvent<HTMLInputElement>
                  ) => {
                    let val = event.target.value;
                    setPhoneNumber(val);
                    if (val.length > 10) {
                      setCheckingPhonenumber(true);
                      let response = await collectionServices.getDocCount(
                        "Users",
                        [
                          {
                            uid: val,
                            uField: "phoneNumber",
                          },
                        ]
                      );
                      setCheckingPhonenumber(false);
                      if (response.status === "success") {
                        const count = response.count;
                        if (count > 0) {
                          setPhoneNumberExists(true);
                        }
                      }
                      //   if (response.status === "error") {
                      //     console.log(response.errorMessage);
                      //   }
                    }
                  }}
                  type="text"
                  variant="filled"
                  fullWidth
                  label="Enter phone number (i.e 0812000000)"
                  name="phoneNumber"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneIphoneIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                {phoneNumberExists && (
                  <Alert
                    severity="warning"
                    action={
                      <Button
                        color="inherit"
                        size="small"
                        onClick={() => {
                          navigate(`/${SESSION_BASE}`);
                        }}
                      >
                        Login
                      </Button>
                    }
                  >
                    This phonenumber already exists in our database.
                  </Alert>
                )}
                {checkingPhonenumber && <LoadingCircle />}
                {!phoneNumberExists &&
                  phoneNumber.length > 10 &&
                  checkingPhonenumber === false &&
                  Buttons({ valid: phoneNumberExists ? false : true })}
              </StepContent>
            </Step>
            <Step>
              <StepLabel>Enter a password</StepLabel>
              <StepContent>
                <FormControl fullWidth variant="filled">
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input
                    id="password"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={password}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setpassword(event.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                {Buttons({ valid: password.length > 7 ? true : false })}
              </StepContent>
            </Step>
            <Step>
              <StepLabel>Your personal details</StepLabel>
              <StepContent>
                <Stack spacing={2}>
                  <TextField
                    id="firstName"
                    value={firstName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setFirstName(event.target.value);
                    }}
                    type="text"
                    variant="filled"
                    fullWidth
                    label="First name"
                    name="firstName"
                  />
                  <TextField
                    id="lastName"
                    value={lastName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setLastName(event.target.value);
                    }}
                    type="text"
                    variant="filled"
                    fullWidth
                    label="Last name"
                    name="lastName"
                  />
                  <TextField
                    id="email"
                    value={email}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setEmail(event.target.value);
                    }}
                    type="text"
                    variant="filled"
                    fullWidth
                    label="Email (optional but important for  notifications)"
                    name="email"
                  />
                </Stack>

                {Buttons({
                  valid: true,
                  async action() {
                    setCreatingAccount(true);
                    let hashPasswordResponse =
                      await powerPayServices.hashPassword(password);
                    if (hashPasswordResponse.status === "success") {
                      const hash = hashPasswordResponse.item;
                      // console.log("hash->", hash);
                      const uniqueId = generateUUIDV4();
                      let tokenResponse =
                        await powerPayServices.createCustomToken(uniqueId);
                      if (tokenResponse.status === "success") {
                        let token = tokenResponse.item;
                        // console.log("token->", token);
                        let signInResponse =
                          await sessionRepository.signInWithCustomToken(
                            token ?? ""
                          );

                        if (signInResponse.status === "success") {
                          const credentials = signInResponse.data;

                          // console.log("credentials->", credentials.user.uid);

                          let createAccountResponse =
                            await collectionServices.addDoc(
                              "Users",
                              credentials.user.uid,
                              {
                                uid: credentials.user.uid,
                                firstName: firstName,
                                lastName: lastName,
                                email: email,
                                phoneNumber: phoneNumber,
                                hash,
                                uniqueId,
                                persona: "customer",
                                photo: { name: "", url: "" },
                                wallet: {
                                  balance: 0,
                                  pendingPayout: 0,
                                  totalCommission: 0,
                                  credit: 0,
                                },
                                metadata: {
                                  createdOn: serverTimestamp(),
                                  status: "active",
                                  queryType: "customer",
                                  query: stringToArray(phoneNumber),
                                },
                              }
                            );

                          if (createAccountResponse.status === "success") {
                            handleNext();
                          }

                          if (createAccountResponse.status === "error") {
                            showSnackbar({
                              status: "error",
                              msg: createAccountResponse.errorMessage ?? "",
                              openSnackbar: true,
                            });
                          }
                        }

                        if (signInResponse.status === "error") {
                          showSnackbar({
                            status: "error",
                            msg: signInResponse.errorMessage ?? "",
                            openSnackbar: true,
                          });
                        }
                      }
                      if (tokenResponse.status === "error") {
                        showSnackbar({
                          status: "error",
                          msg: tokenResponse.errorMessage ?? "",
                          openSnackbar: true,
                        });
                      }
                    }
                    if (hashPasswordResponse.status === "error") {
                      showSnackbar({
                        status: "error",
                        msg: hashPasswordResponse.errorMessage ?? "",
                        openSnackbar: true,
                      });
                    }
                    setCreatingAccount(false);
                  },
                })}
              </StepContent>
            </Step>
            {activeStep === 3 && (
              <Paper square elevation={0} sx={{ p: 3 }}>
                <Stack alignItems="center">
                  <Button
                    onClick={() => {
                      navigate(`/${DASHBOARD_BASE}/${CLIENT_BUY_TOKEN}`);
                    }}
                    sx={{ textTransform: "capitalize" }}
                    endIcon={<LaunchIcon />}
                  >
                    Buy electricity
                  </Button>
                </Stack>
              </Paper>
            )}
          </Stepper>
        </>
      )}

      {auth.currentUser?.uid !== undefined && (
        <Stack alignItems="center">
          <Button
            onClick={() => {
              navigate(`/${DASHBOARD_BASE}/${CLIENT_BUY_TOKEN}`);
            }}
            sx={{ textTransform: "capitalize" }}
            endIcon={<LaunchIcon />}
          >
            Buy electricity
          </Button>
        </Stack>
      )}
    </Paper>
  );
};

export default QuickBuyTokenForm;
