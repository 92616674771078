import { useCollection } from "@/hooks/app-hooks";
import { IAccountDocument } from "@/types/session-types";
import { generateUUIDV4, reloadPage } from "@/utils/funcs";
import {
  Box,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { APP_CURRENCY } from "@/constants/app";
import ViewUserTokenTransactionStats from "./ViewUserTokenTransactionStats";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { collectionServices } from "@/services/root";
import { showSnackbar } from "@/helpers/snackbar-helpers";

const ViewUserContainer = () => {
  const current = useCollection().current as IAccountDocument;

  const [processing, setProcessing] = useState(false);

  const DETAILS = [
    {
      title: "First Name",
      value: current.firstName ? current.firstName : "-",
    },
    {
      title: "Last Name",
      value: current.lastName ? current.lastName : "-",
    },
    {
      title: "Username",
      value: current.username ? current.username : "-",
    },
    {
      title: "Phonenumber",
      value: current.phoneNumber ? current.phoneNumber : "-",
    },
    {
      title: "Email",
      value: current.email ? current.email : "-",
    },
    {
      title: "Location",
      value: ` ${current?.address?.city ? current?.address?.city : "-"}, ${
        current?.address?.state ? current?.address?.state : "-"
      }`,
    },
    {
      title: "Persona",
      value: current.persona ? current.persona : "-",
    },
    {
      title: "Balance",
      value: `${APP_CURRENCY}${
        current?.wallet?.balance ? current?.wallet?.balance : "-"
      }`,
    },
    {
      title: "Credit",
      value: `${APP_CURRENCY}${
        current?.wallet?.credit ? current?.wallet?.credit : "-"
      }`,
    },
    {
      title: "Joined on",
      value: moment(current?.addedOn?.toDate()).format("lll"),
    },
  ];

  return (
    <Container>
      <Stack direction="row" justifyContent="center" sx={{ mt: 2 }}>
        {current?.photo?.url ? (
          <LazyLoadImage
            src={current?.photo?.url}
            alt={current.username}
            style={{ width: 100, height: 100 }}
          />
        ) : (
          <Box sx={{ width: 100, height: 100 }}>
            <AccountCircleIcon sx={{ fontSize: 100 }} />
          </Box>
        )}
      </Stack>
      <Stack direction="row" justifyContent="center">
        {current.persona === "customer" && (
          <LoadingButton
            loading={processing}
            variant="text"
            onClick={async () => {
              setProcessing(true);
              const { status, errorMessage } = await collectionServices.editDoc(
                "Users",
                current.uid,
                {
                  persona: "mgt",
                }
              );
              if (status === "success") {
                showSnackbar({
                  status,
                  msg: "Customer to Admin changed successfully!",
                  openSnackbar: true,
                });
                reloadPage();
              }
              if (status === "error") {
                showSnackbar({
                  status,
                  msg: errorMessage,
                  openSnackbar: true,
                });
              }
              setProcessing(false);
            }}
          >
            Make Admin
          </LoadingButton>
        )}
      </Stack>
      <Grid container spacing={1} alignItems="center" justifyContent="center">
        <Grid item xs={12} md={6}>
          <Divider sx={{ my: 2 }} />
          {DETAILS.map((item) => (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              key={generateUUIDV4()}
            >
              <Typography variant="caption" color="textPrimary">
                {item.title}
              </Typography>
              <Typography variant="overline" color="textPrimary">
                {item.value}
              </Typography>
            </Stack>
          ))}
          <Divider sx={{ my: 2 }} />
        </Grid>
      </Grid>
      <ViewUserTokenTransactionStats userId={current.uid} />
    </Container>
  );
};

export default ViewUserContainer;
