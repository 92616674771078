import { useEffect } from "react";
import {
  Box,
  Divider,
  Drawer,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { User as UserIcon } from "../icons/user";
import { Users as UsersIcon } from "../icons/users";
import { useLocation } from "react-router-dom";
import AppBrand from "../global/AppBrand";
import { NavItem } from "./NavItem";
import {
  ADMIN_DASHBOARD,
  ADMIN_MGT_ACCOUNT,
  ADMIN_MGT_METER,
  ADMIN_MGT_TRANSACTIONS,
  CLIENT_ACCOUNT,
  CLIENT_BUY_TOKEN,
  CLIENT_METERS,
  CLIENT_TRANSACTIONS,
  CLIENT_WALLET,
  DASHBOARD_BASE,
} from "@/routes/dashboard-routes";
import PointOfSaleTwoToneIcon from "@mui/icons-material/PointOfSaleTwoTone";
import ReceiptLongTwoToneIcon from "@mui/icons-material/ReceiptLongTwoTone";
import WalletTwoToneIcon from "@mui/icons-material/WalletTwoTone";
import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { useSession } from "@/hooks/app-hooks";
import ElectricMeterIcon from "@mui/icons-material/ElectricMeter";

const clientLinks = [
  {
    href: `/${DASHBOARD_BASE}/${CLIENT_BUY_TOKEN}`,
    icon: <BatteryChargingFullIcon fontSize="small" />,
    title: "Buy Token",
  },
  {
    href: `/${DASHBOARD_BASE}/${CLIENT_TRANSACTIONS}`,
    icon: <ReceiptLongTwoToneIcon fontSize="small" />,
    title: "Transactions",
  },
  {
    href: `/${DASHBOARD_BASE}/${CLIENT_METERS}`,
    icon: <PointOfSaleTwoToneIcon fontSize="small" />,
    title: "My Meters",
  },
  {
    href: `/${DASHBOARD_BASE}/${CLIENT_WALLET}`,
    icon: <WalletTwoToneIcon fontSize="small" />,
    title: "Wallet",
  },
  {
    href: `/${DASHBOARD_BASE}/${CLIENT_ACCOUNT}`,
    icon: <UserIcon fontSize="small" />,
    title: "Account",
  },
];
const adminLinks = [
  {
    href: `/${DASHBOARD_BASE}/${ADMIN_DASHBOARD}`,
    icon: <DashboardIcon fontSize="small" />,
    title: "Dashboard",
  },
  {
    href: `/${DASHBOARD_BASE}/${ADMIN_MGT_ACCOUNT}`,
    icon: <UsersIcon fontSize="small" />,
    title: "Manage Accounts",
  },
  {
    href: `/${DASHBOARD_BASE}/${ADMIN_MGT_TRANSACTIONS}`,
    icon: <ReceiptLongTwoToneIcon fontSize="small" />,
    title: "Manage Transactions",
  },
  {
    href: `/${DASHBOARD_BASE}/${ADMIN_MGT_METER}`,
    icon: <ElectricMeterIcon fontSize="small" />,
    title: "Manage Meters",
  },
];

interface Props {
  onClose(): void;
  open: boolean;
}

export const DashboardSidebar = (props: Props) => {
  const { open, onClose } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"), {
    defaultMatches: true,
    noSsr: false,
  });

  useEffect(() => {
    if (!location.pathname) {
      return;
    }

    if (open) {
      onClose?.();
    }
  }, [location]);

  const profile = useSession();

  const content = (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              width: "100%",
              height: 100,
            }}
          >
            <AppBrand size="large" />
          </Stack>
        </div>
        <Divider
          sx={{
            borderColor: "primary.main",
            my: 1,
          }}
        />
        <Box sx={{ flexGrow: 1 }}>
          {clientLinks.map((item) => (
            <NavItem
              key={item.title}
              icon={item.icon}
              href={item.href}
              title={item.title}
            />
          ))}
        </Box>
        {profile.persona === "mgt" && (
          <>
            <Divider sx={{ borderColor: "primary.main", my: 2 }} />
            <Box sx={{ flexGrow: 1 }}>
              <Typography
                variant="subtitle2"
                color="primary.main"
                textAlign="center"
              >
                Admin
              </Typography>
              {adminLinks.map((item) => (
                <NavItem
                  key={item.title}
                  icon={item.icon}
                  href={item.href}
                  title={item.title}
                />
              ))}
            </Box>
          </>
        )}
      </Box>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "rgb(17, 24, 39)",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "rgb(17, 24, 39)",
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};
