import GeneralHeader from "@/components/headers/GeneralHeader";
import { Container, Grid, Typography } from "@mui/material";

const AboutUs = () => {
  return (
    <>
      <GeneralHeader title="About us" />
      <Container>
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h2"
              color="primary"
              sx={{ fontSize: "2rem", fontWeight: "bold", textAlign: "center" }}
            >
              {" "}
              GET ELECTRICITY TOKEN WHEN YOU NEED IT!
            </Typography>
            <Typography
              variant="body1"
              color="textPrimary"
              sx={{ textAlign: "center" }}
            >
              Haske is a secured power token vending channel that allows you
              purchase electricity token from the comfort of your home. Haske
              provides token vending via Automated kiosk system, Web vending and
              mobile POS. Haske is developed with intentions of providing
              comfort through fast and reliable vending channels.{" "}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default AboutUs;
