import Backdrop from "@mui/material/Backdrop";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Box, Divider, Typography } from "@mui/material";
import CouponCodeForm from "@/components/forms/coupon/CouponCodeForm";

interface Props {
  setVisible?: any;
  visible: boolean;
  close?: any;
}

const WalletCouponCodeModal = (props: Props) => {
  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 4,
        marginLeft: 0,
      }}
      open={props.visible}
    >
      <Box
        sx={{
          bgcolor: "background.paper",
          borderRadius: 2,
          p: 3,
          position: "relative",
          width: { md: 400, xs: "90vw" },
        }}
        boxShadow={3}
      >
        <div style={{ position: "absolute", top: 0, left: 0 }}>
          <IconButton
            onClick={() => {
              if (props.close) {
                props.close();
              } else {
                props.setVisible(!props.visible);
              }
            }}
          >
            <Close />
          </IconButton>
        </div>
        <Typography variant="subtitle1" color="textPrimary">
          Enter coupon code here
        </Typography>
        <Divider />
        <CouponCodeForm />
      </Box>
    </Backdrop>
  );
};

export default WalletCouponCodeModal;
