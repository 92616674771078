import EmptyList from "@/components/common/EmptyList";
import ViewUserContainer from "@/components/users/view-user/ViewUserContainer";
import { APP_NAME } from "@/constants/app";
import { collectionActions } from "@/db/collection-slice";
import { useAppDispatch } from "@/hooks/db-hooks";
import { collectionServices } from "@/services/root";
import { IAccountDocument } from "@/types/session-types";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";

const MgtViewUser = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [account, setAccount] = useState<IAccountDocument | null>(null);

  useEffect(() => {
    (async () => {
      if (id) {
        const {
          status: _status,
          errorMessage,
          item,
        } = await collectionServices.getDoc("Users", id);

        if (_status === "success" && item) {
          setIsLoading(false);
          setAccount(item);
          dispatch(collectionActions.setCollectionStateCurrent(item));
        }
        if (_status === "error") {
          setIsLoading(false);
          if (errorMessage) {
            setErrorMessage(errorMessage);
          }
        }
        setStatus(_status);
      }
    })();
  }, [id, dispatch]);

  if (isLoading) return <p>Loading...</p>;
  if (status === "error") return <EmptyList title={errorMessage} caption="." />;

  return (
    <div>
      <Helmet>
        <title>
          {account?.phoneNumber} - {APP_NAME}
        </title>
        <meta name="description" content="Haske" />
      </Helmet>

      {status === "success" && isEmpty(account) === false && account && (
        <ViewUserContainer />
      )}
      {status === "success" && isEmpty(account) === true && (
        <EmptyList title="User not found" caption="." />
      )}
    </div>
  );
};

export default MgtViewUser;
