import { collectionActions } from "@/db/collection-slice";
import { getAllDocs, getDocs } from "@/helpers/collection-helpers";
import { useAppDispatch } from "@/hooks/db-hooks";
import { LoadingButton } from "@mui/lab";
import { Box, Stack, Typography } from "@mui/material";
import { useState } from "react";

const UsersFilter = () => {
  const [isLoadingAll, setIsLoadingAll] = useState(false);
  const [isLoadingCustomers, setIsLoadingCustomers] = useState(false);
  const [isLoadingMgt, setIsLoadingMgt] = useState(false);

  const dispatch = useAppDispatch();

  return (
    <Stack alignItems="center">
      <Box>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="caption" color="textPrimary">
            Filter
          </Typography>
          <LoadingButton
            variant="contained"
            sx={{ borderRadius: 20, bgcolor: "gray", color: "#fff" }}
            size={"small"}
            onClick={async () => {
              setIsLoadingAll(true);
              dispatch(collectionActions.setUserFilter("all"));

              await getAllDocs("Users", true);

              setIsLoadingAll(false);
            }}
            loading={isLoadingAll}
          >
            All
          </LoadingButton>
          <LoadingButton
            variant="contained"
            sx={{ borderRadius: 20, bgcolor: "gray", color: "#fff" }}
            size={"small"}
            onClick={async () => {
              setIsLoadingCustomers(true);
              dispatch(collectionActions.setUserFilter("customer"));

              await getDocs(
                "Users",
                [
                  {
                    uField: "persona",
                    uid: "customer",
                  },
                ],
                100,
                true
              );

              setIsLoadingCustomers(false);
            }}
            loading={isLoadingCustomers}
          >
            Customers
          </LoadingButton>
          <LoadingButton
            variant="contained"
            sx={{ borderRadius: 20, bgcolor: "gray", color: "#fff" }}
            size={"small"}
            onClick={async () => {
              setIsLoadingMgt(true);
              dispatch(collectionActions.setUserFilter("mgt"));

              await getDocs(
                "Users",
                [
                  {
                    uField: "persona",
                    uid: "mgt",
                  },
                ],
                100,
                true
              );

              setIsLoadingMgt(false);
            }}
            loading={isLoadingMgt}
          >
            Admins
          </LoadingButton>
        </Stack>
      </Box>
    </Stack>
  );
};

export default UsersFilter;
