import { CHART_TIMEFRAME_OPTIONS } from "@/constants/stats-contants";
import { IBarChartData, IChartTimeFrame } from "@/types/stat-stypes";
import {
  generateUUIDV4,
  getGroupedListByDay,
  numberWithCommas,
} from "@/utils/funcs";
import { faker } from "@faker-js/faker";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Brush,
  PieChart,
  Pie,
  Cell,
  Legend,
} from "recharts";
import { sample } from "lodash";
import { flatMapDeep,  round, sum } from "lodash";
import { APP_CURRENCY } from "@/constants/app";
const COLORS = ["#00C49F", "#FF8042"];

interface IPieChartData {
  name: string;
  count: number;
}

const TRANSACTION_DATA = [
  {
    id: faker.datatype.uuid(),
    name: faker.name.firstName(),
    amount: faker.datatype.number({ min: 100, max: 20000 }),
    commission: faker.datatype.number({ min: 100, max: 1000 }),
    addedOn: moment()
      .clone()
      .subtract(sample([2, 5, 6, 7, 8]), "days"),
  },
  {
    id: faker.datatype.uuid(),
    name: faker.name.firstName(),
    amount: faker.datatype.number({ min: 100, max: 20000 }),
    commission: faker.datatype.number({ min: 100, max: 1000 }),
    addedOn: moment()
      .clone()
      .subtract(sample([2, 3, 5, 6, 7, 8]), "days"),
  },
  {
    id: faker.datatype.uuid(),
    name: faker.name.firstName(),
    amount: faker.datatype.number({ min: 100, max: 20000 }),
    commission: faker.datatype.number({ min: 100, max: 1000 }),
    addedOn: moment()
      .clone()
      .subtract(sample([2, 3, 6, 7, 8]), "days"),
  },
  {
    id: faker.datatype.uuid(),
    name: faker.name.firstName(),
    amount: faker.datatype.number({ min: 100, max: 20000 }),
    commission: faker.datatype.number({ min: 100, max: 1000 }),
    addedOn: moment()
      .clone()
      .subtract(sample([2, 3, 4, 6, 7, 8]), "days"),
  },
  {
    id: faker.datatype.uuid(),
    name: faker.name.firstName(),
    amount: faker.datatype.number({ min: 100, max: 20000 }),
    commission: faker.datatype.number({ min: 100, max: 1000 }),
    addedOn: moment()
      .clone()
      .subtract(sample([2, 3, 4, 5, 6]), "days"),
  },
  {
    id: faker.datatype.uuid(),
    name: faker.name.firstName(),
    amount: faker.datatype.number({ min: 100, max: 20000 }),
    commission: faker.datatype.number({ min: 100, max: 1000 }),
    addedOn: moment()
      .clone()
      .subtract(sample([2, 5, 6, 7, 8]), "days"),
  },
  {
    id: faker.datatype.uuid(),
    name: faker.name.firstName(),
    amount: faker.datatype.number({ min: 100, max: 20000 }),
    commission: faker.datatype.number({ min: 100, max: 1000 }),
    addedOn: moment()
      .clone()
      .subtract(sample([2, 3, 5, 6, 7, 8]), "days"),
  },
  {
    id: faker.datatype.uuid(),
    name: faker.name.firstName(),
    amount: faker.datatype.number({ min: 100, max: 20000 }),
    commission: faker.datatype.number({ min: 100, max: 1000 }),
    addedOn: moment()
      .clone()
      .subtract(sample([2, 3, 6, 7, 8]), "days"),
  },
  {
    id: faker.datatype.uuid(),
    name: faker.name.firstName(),
    amount: faker.datatype.number({ min: 100, max: 20000 }),
    commission: faker.datatype.number({ min: 100, max: 1000 }),
    addedOn: moment()
      .clone()
      .subtract(sample([2, 3, 4, 6, 7, 8]), "days"),
  },
  {
    id: faker.datatype.uuid(),
    name: faker.name.firstName(),
    amount: faker.datatype.number({ min: 100, max: 20000 }),
    commission: faker.datatype.number({ min: 100, max: 1000 }),
    addedOn: moment()
      .clone()
      .subtract(sample([2, 3, 4, 5, 6]), "days"),
  },
  {
    id: faker.datatype.uuid(),
    name: faker.name.firstName(),
    amount: faker.datatype.number({ min: 100, max: 20000 }),
    commission: faker.datatype.number({ min: 100, max: 1000 }),
    addedOn: moment()
      .clone()
      .subtract(sample([2, 5, 6, 7, 8]), "days"),
  },
  {
    id: faker.datatype.uuid(),
    name: faker.name.firstName(),
    amount: faker.datatype.number({ min: 100, max: 20000 }),
    commission: faker.datatype.number({ min: 100, max: 1000 }),
    addedOn: moment()
      .clone()
      .subtract(sample([2, 3, 5, 6, 7, 8]), "days"),
  },
  {
    id: faker.datatype.uuid(),
    name: faker.name.firstName(),
    amount: faker.datatype.number({ min: 100, max: 20000 }),
    commission: faker.datatype.number({ min: 100, max: 1000 }),
    addedOn: moment()
      .clone()
      .subtract(sample([2, 3, 6, 7, 8]), "days"),
  },
  {
    id: faker.datatype.uuid(),
    name: faker.name.firstName(),
    amount: faker.datatype.number({ min: 100, max: 20000 }),
    commission: faker.datatype.number({ min: 100, max: 1000 }),
    addedOn: moment()
      .clone()
      .subtract(sample([2, 3, 4, 6, 7, 8]), "days"),
  },
  {
    id: faker.datatype.uuid(),
    name: faker.name.firstName(),
    amount: faker.datatype.number({ min: 100, max: 20000 }),
    commission: faker.datatype.number({ min: 100, max: 1000 }),
    addedOn: moment()
      .clone()
      .subtract(sample([2, 3, 4, 5, 6]), "days"),
  },
  {
    id: faker.datatype.uuid(),
    name: faker.name.firstName(),
    amount: faker.datatype.number({ min: 100, max: 20000 }),
    commission: faker.datatype.number({ min: 100, max: 1000 }),
    addedOn: moment()
      .clone()
      .subtract(sample([2, 5, 6, 7, 8]), "days"),
  },
  {
    id: faker.datatype.uuid(),
    name: faker.name.firstName(),
    amount: faker.datatype.number({ min: 100, max: 20000 }),
    commission: faker.datatype.number({ min: 100, max: 1000 }),
    addedOn: moment()
      .clone()
      .subtract(sample([2, 3, 5, 6, 7, 8]), "days"),
  },
  {
    id: faker.datatype.uuid(),
    name: faker.name.firstName(),
    amount: faker.datatype.number({ min: 100, max: 20000 }),
    commission: faker.datatype.number({ min: 100, max: 1000 }),
    addedOn: moment()
      .clone()
      .subtract(sample([2, 3, 6, 7, 8]), "days"),
  },
  {
    id: faker.datatype.uuid(),
    name: faker.name.firstName(),
    amount: faker.datatype.number({ min: 100, max: 20000 }),
    commission: faker.datatype.number({ min: 100, max: 1000 }),
    addedOn: moment()
      .clone()
      .subtract(sample([2, 3, 4, 6, 7, 8]), "days"),
  },
  {
    id: faker.datatype.uuid(),
    name: faker.name.firstName(),
    amount: faker.datatype.number({ min: 100, max: 20000 }),
    commission: faker.datatype.number({ min: 100, max: 1000 }),
    addedOn: moment()
      .clone()
      .subtract(sample([2, 3, 4, 5, 6]), "days"),
  },
];
const MgtTransactionReport = () => {
  const [selectTimeFrame, setSelectTimeFrame] =
    useState<IChartTimeFrame>("This week");
  const handleChange = (event: SelectChangeEvent) => {
    setSelectTimeFrame(event.target.value as IChartTimeFrame);
  };
  const [processing, setProcessing] = useState(false);

  const [transactionCountbarchartData, setTransactionCountbarchartData] =
    useState<any[]>([]);

  const [pieChartAmountData, setPieChartAmountData] = useState<IPieChartData[]>(
    []
  );

  useEffect(() => {
    (async () => {
      const formattedData = getGroupedListByDay(
        TRANSACTION_DATA
      ) as IBarChartData<any>[];

      const _data = formattedData.map((_d) => {
        return {
          name: moment(_d.key).format("MMM DD"),
          transactionCount: _d.data.length,
        };
      });
      setTransactionCountbarchartData(_data);

      setPieChartAmountData([
        {
          name: "Token Total",
          count: sum(
            flatMapDeep(
              formattedData.map((_d) =>
                _d.data.map((_ddd) => round(_ddd.amount))
              )
            )
          ),
        },

        {
          name: "Commission Total",
          count: sum(
            flatMapDeep(
              formattedData.map((_d) =>
                _d.data.map((_ddd) => round(_ddd.commission))
              )
            )
          ),
        },
      ]);
      setProcessing(true);
      if (selectTimeFrame === "This week") {
      }
      if (selectTimeFrame === "Last 30 days") {
      }
      if (selectTimeFrame === "Last 90 days") {
      }
      if (selectTimeFrame === "This Year") {
      }
      if (selectTimeFrame === "Today") {
      }
      setProcessing(false);
    })();
  }, [selectTimeFrame]);

  return (
    <Paper sx={{ position: "relative", flex: 1 }}>
      <Typography variant="h4" color="textPrimary" textAlign="center">
        Transaction Stats
      </Typography>
      <div>
        <FormControl
          variant="filled"
          fullWidth
          sx={{ m: 1, width: 200, fontWeight: "bold" }}
        >
          <InputLabel id="transactions-filled-label">
            Select a timeframe
          </InputLabel>
          <Select
            labelId="transactions-filled-label"
            id="transactions-filled"
            value={selectTimeFrame}
            onChange={handleChange}
          >
            {CHART_TIMEFRAME_OPTIONS.map((option) => (
              <MenuItem key={generateUUIDV4()} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {processing && <p>loading...</p>}
      {!processing && transactionCountbarchartData.length > 0 && (
        <>
          <div style={{ flex: 1, width: "100%", height: 500 }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={300}
                data={transactionCountbarchartData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />

                <Bar
                  dataKey="transactionCount"
                  fill="#FF8042"
                  maxBarSize={40}
                  stackId="a"
                />
                <Legend height={36} layout="horizontal" />
                <Brush dataKey="name" height={30} stroke="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </div>

          <Stack sx={{ flex: 1, width: "100%", height: 500 }}>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart width={400} height={400} key={"2"}>
                <Legend height={36} layout="vertical" />
                <Pie
                  data={pieChartAmountData}
                  cx="50%"
                  cy="50%"
                  innerRadius={110}
                  outerRadius={130}
                  fill="#8884d8"
                  paddingAngle={1}
                  dataKey="count"
                  label={({
                    cx,
                    cy,
                    midAngle,
                    innerRadius,
                    outerRadius,
                    index,
                  }) => {
                    const RADIAN = Math.PI / 180;
                    // eslint-disable-next-line
                    const radius =
                      25 + innerRadius + (outerRadius - innerRadius);
                    // eslint-disable-next-line
                    const x = cx + radius * Math.cos(-midAngle * RADIAN);
                    // eslint-disable-next-line
                    const y = cy + radius * Math.sin(-midAngle * RADIAN);

                    return (
                      <text
                        x={x}
                        y={y}
                        fill="#8884d8"
                        textAnchor={x > cx ? "start" : "end"}
                        dominantBaseline="central"
                      >
                        {APP_CURRENCY}
                        {numberWithCommas(pieChartAmountData[index].count)}
                      </text>
                    );
                  }}
                >
                  {pieChartAmountData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </Stack>
        </>
      )}
    </Paper>
  );
};

export default MgtTransactionReport;
