import { IMeterDocument } from "@/types/meter-types";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Stack,
  Collapse,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import Confirmation from "../modals/Confirmation";
import { collectionServices, powerPayServices } from "@/services/root";
import { reloadPage } from "@/utils/funcs";
import { showSnackbar } from "@/helpers/snackbar-helpers";
import LoadingCircle from "../common/LoadingCircle";
import { DeleteForever } from "@mui/icons-material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { KadunaCustomerDoc } from "@/types/customer-types";

interface Props {
  meter: IMeterDocument;
}
const MeterListItem = ({ meter }: Props) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [collapse, setCollapse] = useState(false);

  const [kadcoCustomerInfo, setKadcoCustomerInfo] =
    useState<KadunaCustomerDoc | undefined>(undefined);
  const [loadingKadcoCustomerInfo, setLoadingKadcoCustomerInfo] =
    useState(false);

  useEffect(() => {
    (async () => {
      if (collapse === true && kadcoCustomerInfo === undefined) {
        setLoadingKadcoCustomerInfo(true);
        let response = await powerPayServices.getCustomer(meter.meterNumber);
        setLoadingKadcoCustomerInfo(false);

        if (response.status === "success") {
          setKadcoCustomerInfo(response.item);
        }
      }
    })();
  }, [collapse, kadcoCustomerInfo, meter.meterNumber]);
  return (
    <Box boxShadow={2} sx={{ my: 1, p: 1 }}>
      <Confirmation
        visible={confirmDelete}
        close={() => {
          setConfirmDelete(false);
        }}
        action={async () => {
          setDeleting(true);
          let response = await collectionServices.deleteDoc(
            "Meters",
            meter.uid
          );
          setDeleting(false);

          if (response.status === "success") {
            reloadPage();
          }

          if (response.status === "error") {
            showSnackbar({
              openSnackbar: true,
              msg: response.errorMessage,
              status: "error",
            });
          }
        }}
      />
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} md={2}>
          <Typography
            variant="caption"
            color="textPrimary"
            sx={{ fontSize: 8 }}
          >
            {moment(meter.addedOn.toDate()).format("lll")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant="caption" color="textPrimary">
            {meter.meterNumber}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant="caption" color="textPrimary">
            {meter.meterName}
          </Typography>
        </Grid>

        <Grid item xs={12} md={3}>
          <Typography variant="caption" color="textPrimary">
            {meter.meterType !== "Postpaid" ? "Prepaid" : "Postpaid"}
          </Typography>
        </Grid>

        <Grid item xs={12} md={2}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            sx={{ px: 1 }}
          >
            {deleting ? (
              <LoadingCircle />
            ) : (
              <IconButton
                onClick={() => {
                  setConfirmDelete(true);
                }}
              >
                <DeleteForever />
              </IconButton>
            )}

            {collapse ? (
              <IconButton
                onClick={() => {
                  setCollapse(!collapse);
                }}
              >
                <KeyboardArrowDownIcon />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => {
                  setCollapse(!collapse);
                }}
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            )}
          </Stack>
        </Grid>
      </Grid>
      <Collapse in={collapse}>
        <Stack alignItems="center">
          {loadingKadcoCustomerInfo && <LoadingCircle />}
        </Stack>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} md={5}>
            {kadcoCustomerInfo !== undefined && (
              <Stack spacing={1} sx={{ py: 2 }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="caption"
                    color="textPrimary"
                    sx={{
                      width: 100,
                      textAlign: "left",
                      lineHeight: 1,
                    }}
                  >
                    Customer name:
                  </Typography>
                  <Typography
                    variant="overline"
                    color="textPrimary"
                    sx={{
                      fontWeight: "bold",
                      width: 200,
                      textAlign: "right",
                      lineHeight: 1,
                    }}
                  >
                    {kadcoCustomerInfo.customerName}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="caption"
                    color="textPrimary"
                    sx={{
                      width: 100,
                      textAlign: "left",
                      lineHeight: 1,
                    }}
                  >
                    Customer address:
                  </Typography>
                  <Typography
                    variant="overline"
                    color="textPrimary"
                    sx={{
                      fontWeight: "bold",
                      width: 200,
                      textAlign: "right",
                      lineHeight: 1,
                    }}
                  >
                    {kadcoCustomerInfo.customerAddress}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="caption"
                    color="textPrimary"
                    sx={{
                      width: 100,
                      textAlign: "left",
                      lineHeight: 1,
                    }}
                  >
                    Customer mobile number:
                  </Typography>
                  <Typography
                    variant="overline"
                    color="textPrimary"
                    sx={{
                      fontWeight: "bold",
                      width: 200,
                      textAlign: "right",
                      lineHeight: 1,
                    }}
                  >
                    {kadcoCustomerInfo.customerMobileNo || "nil"}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="caption"
                    color="textPrimary"
                    sx={{
                      width: 100,
                      textAlign: "left",
                      lineHeight: 1,
                    }}
                  >
                    Customer State:
                  </Typography>
                  <Typography
                    variant="overline"
                    color="textPrimary"
                    sx={{
                      fontWeight: "bold",
                      width: 200,
                      textAlign: "right",
                      lineHeight: 1,
                    }}
                  >
                    {kadcoCustomerInfo.customerState}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="caption"
                    color="textPrimary"
                    sx={{
                      width: 100,
                      textAlign: "left",
                      lineHeight: 1,
                    }}
                  >
                    Transformer:
                  </Typography>
                  <Typography
                    variant="overline"
                    color="textPrimary"
                    sx={{
                      fontWeight: "bold",
                      width: 200,
                      textAlign: "right",
                      lineHeight: 1,
                    }}
                  >
                    {kadcoCustomerInfo.customerTransformer}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="caption"
                    color="textPrimary"
                    sx={{
                      width: 100,
                      textAlign: "left",
                      lineHeight: 1,
                    }}
                  >
                    OutStanding Debt Balance:
                  </Typography>
                  <Typography
                    variant="overline"
                    color="textPrimary"
                    sx={{
                      fontWeight: "bold",
                      width: 200,
                      textAlign: "right",
                      lineHeight: 1,
                    }}
                  >
                    {kadcoCustomerInfo.customerOutStandingDebtBalance || "nil"}
                  </Typography>
                </Stack>
              </Stack>
            )}
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  );
};

export default MeterListItem;
