import { ICollectionState } from "@/db/collection-slice";
import {
  fetchMoreDocs,
  setShowLoadMoreBtn,
} from "@/helpers/collection-helpers";
import { useCollection } from "@/hooks/app-hooks";
import { pageTypes } from "@/types/pages-types";
import { IAccountDocument } from "@/types/session-types";
import { useState } from "react";
import LoadMoreBtn from "../common/LoadMoreBtn";

interface Props {
  userId: string;
  persona: pageTypes;
}
const WalletLoadMoreBtn = ({ userId, persona }: Props) => {
  const collectionState = useCollection() as ICollectionState<IAccountDocument>;
  const [isLoading, setIsLoading] = useState(false);

  if (collectionState.showLoadMoreBtn === false) return <></>;
  return (
    <LoadMoreBtn
      isEmpty={collectionState.isEmpty}
      isLoading={isLoading}
      action={async () => {
        setIsLoading(true);
        await fetchMoreDocs("WalletTransactions", [
          {
            uField: "userId",
            uid: userId,
            operator: "==",
          },
          {
            uField: "persona",
            uid: persona,
            operator: "==",
          },
        ]);
        setIsLoading(false);
        setShowLoadMoreBtn(true);
      }}
      endText="That is all"
    />
  );
};

export default WalletLoadMoreBtn;
