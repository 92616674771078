import { useCollection } from "@/hooks/app-hooks";
import { generateUUIDV4 } from "@/utils/funcs";
import { Box, Grid, Typography } from "@mui/material";
import EmptyList from "../common/EmptyList";
import TransactionListItem from "./TransactionListItem";

const TransactionList = () => {
  const collectionState = useCollection();

  if (collectionState.isLoading) return <p>Loading...</p>;
  if (collectionState.status === "error")
    return <EmptyList title={"An error occured"} caption="." />;
  return (
    <div>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} md={2}>
            <Typography variant="caption" color="textPrimary">
              Date
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="caption" color="textPrimary">
              Meter type
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="caption" color="textPrimary">
              Amount
            </Typography>
          </Grid>
          <Grid item xs={12} md={1}>
            <Typography variant="caption" color="textPrimary">
              Status
            </Typography>
          </Grid>
          <Grid item xs={12} md={1}>
            <Typography variant="caption" color="textPrimary">
              Action
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ px: 1 }}>
        {collectionState.status === "success" &&
          collectionState.all.count > 0 &&
          collectionState.all.list.map((transaction) => (
            <TransactionListItem
              transaction={transaction}
              key={generateUUIDV4()}
            />
          ))}
      </Box>
      {/* {collectionState.status === "success" &&
        collectionState.all.count > 0 && (
          <WalletLoadMoreBtn persona={persona} userId={userId} />
        )} */}

      {collectionState.status === "success" &&
        collectionState.all.count === 0 && (
          <EmptyList title="No transactions yet" caption="." />
        )}
    </div>
  );
};

export default TransactionList;
