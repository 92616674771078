import RegisterForm from "@/components/forms/session/RegisterForm";
import GeneralHeader from "@/components/headers/GeneralHeader";
import SessionFormLayout from "@/components/session/SessionFormLayout";

const Register = () => {
  return (
    <>
      <GeneralHeader title="Register" />
      <SessionFormLayout caption="Kindly input all fields to register">
        <RegisterForm />
      </SessionFormLayout>
    </>
  );
};

export default Register;
