import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import AppBrand from "../global/AppBrand";
import ToggleThemeMode from "../common/ToggleThemeMode";
import Button from "@mui/material/Button";
import { SESSION_BASE } from "@/routes/session-routes";
import { ABOUT_US, CONTACT_US, FAQ_P } from "@/routes/guest-routes";
import { Stack } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { useState } from "react";
import AccountNavThumb from "../account/AccountNavThumb";
import AccountLogOut from "../account/AccountLogOut";
import { useSession } from "@/hooks/app-hooks";
import {
  CLIENT_ACCOUNT,
  CLIENT_BUY_TOKEN,
  CLIENT_METERS,
  CLIENT_TRANSACTIONS,
  CLIENT_WALLET,
  DASHBOARD_BASE,
} from "@/routes/dashboard-routes";
import Spacer from "../common/Spacer";

const GeneralNav = () => {
  const [mobileMenuDrawer, setMobileMenuDrawer] = useState(false);
  const profile = useSession();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Drawer
        anchor="left"
        open={mobileMenuDrawer}
        onClose={() => {
          setMobileMenuDrawer(!mobileMenuDrawer);
        }}
      >
        <Box
          role="presentation"
          onClick={() => {
            setMobileMenuDrawer(false);
          }}
          onKeyDown={() => {
            setMobileMenuDrawer(false);
          }}
          sx={{ width: 300, height: "100vh", bgcolor: "background.default" }}
        >
          <Stack
            sx={{ width: "100%", height: 200 }}
            alignItems="center"
            justifyContent="center"
          >
            <AppBrand size="large" />
          </Stack>
          <Stack sx={{ p: 1 }}>
            {profile.uid !== "" ? (
              <>
                <Button
                  variant="text"
                  sx={{ mr: 1, color: "primary.dark" }}
                  href={`/${DASHBOARD_BASE}/${CLIENT_ACCOUNT}`}
                >
                  My account
                </Button>
                <Button
                  variant="text"
                  sx={{ mr: 1, color: "primary.dark" }}
                  href={`/${DASHBOARD_BASE}/${CLIENT_TRANSACTIONS}`}
                >
                  Transactions
                </Button>
                <Button
                  variant="text"
                  sx={{ mr: 1, color: "primary.dark" }}
                  href={`/${DASHBOARD_BASE}/${CLIENT_METERS}`}
                >
                  My Meters
                </Button>
                <Button
                  variant="text"
                  sx={{ mr: 1, color: "primary.dark" }}
                  href={`/${DASHBOARD_BASE}/${CLIENT_WALLET}`}
                >
                  My Wallet
                </Button>
                <AccountLogOut />
                <Button
                  variant="contained"
                  color="secondary"
                  href={`/${DASHBOARD_BASE}/${CLIENT_BUY_TOKEN}`}
                >
                  Buy electricity
                </Button>
              </>
            ) : (
              <Button
                variant="text"
                sx={{ mr: 1, color: "primary.dark" }}
                href={`/${SESSION_BASE}`}
              >
                Login
              </Button>
            )}
            <Spacer space={50} />
            <Button
              variant="text"
              sx={{ mr: 1, color: "primary.dark" }}
              href={`/${FAQ_P}`}
            >
              FAQ
            </Button>
            <Button
              variant="text"
              sx={{ mr: 1, color: "primary.dark" }}
              href={`/${ABOUT_US}`}
            >
              About us
            </Button>
            <Button
              variant="text"
              sx={{ mr: 1, color: "primary.dark" }}
              href={`/${CONTACT_US}`}
            >
              Contact us
            </Button>
          </Stack>
        </Box>
      </Drawer>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          backgroundColor: "background.paper",
        }}
      >
        <Container>
          <Toolbar>
            <Box sx={{ display: { xs: "block", md: "none" } }}>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={() => {
                  setMobileMenuDrawer(!mobileMenuDrawer);
                }}
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Stack
                alignItems="center"
                justifyContent="flex-start"
                direction="row"
              >
                <AppBrand size="large" />
              </Stack>
            </Box>
            <ToggleThemeMode />
            <Stack direction="row" sx={{ display: { xs: "none", md: "flex" } }}>
              <Button
                variant="text"
                sx={{
                  mr: 1,
                  color: "primary.dark",
                  fontSize: 12,
                  // lineHeight: 24,
                }}
                href={`/${FAQ_P}`}
              >
                FAQ
              </Button>
              <Button
                variant="text"
                sx={{ mr: 1, color: "primary.dark", fontSize: 12 }}
                href={`/${ABOUT_US}`}
              >
                About us
              </Button>
              <Button
                variant="text"
                sx={{ mr: 1, color: "primary.dark", fontSize: 12 }}
                href={`/${CONTACT_US}`}
              >
                Contact us
              </Button>
              {profile.uid !== "" ? (
                <>
                  <AccountLogOut />
                  <Button
                    variant="contained"
                    sx={{ color: "#fff", fontSize: 12 }}
                    href={`/${DASHBOARD_BASE}/${CLIENT_BUY_TOKEN}`}
                  >
                    Buy electricity
                  </Button>
                </>
              ) : (
                <Button
                  variant="contained"
                  sx={{
                    mr: 1,
                    color: "#fff",
                    // fontWeight: "bold",
                    fontSize: 12,
                  }}
                  href={`/${SESSION_BASE}`}
                >
                  Login
                </Button>
              )}

              <AccountNavThumb />
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
};

export default GeneralNav;
