import { IWalletTransactionDocument } from "@/types/wallet-types";
import { Grid, Paper, Typography } from "@mui/material";
import moment from "moment";
import Price from "../common/Price";
interface Props {
  transaction: IWalletTransactionDocument;
}
const WalletTransactionListItem = ({ transaction }: Props) => {
  return (
    <Paper
      sx={{
        my: 1,
        p: 1,
        bgcolor:
          transaction.type === "withdraw" || transaction.type === "deduction"
            ? `rgba(255,0,0,0.2)`
            : transaction.type === "sale" ||
              transaction.type === "top-up" ||
              transaction.type === "credit"
            ? `rgba(0,255,0,0.2)`
            : "background.paper",
      }}
    >
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={12} md={3}>
          <Typography
            variant="caption"
            color="textPrimary"
            sx={{ fontWeight: "bolder" }}
          >
            {transaction.type === "withdraw"
              ? "You made a withdrawal"
              : transaction.type === "sale"
              ? "You made a sale"
              : transaction.type === "top-up"
              ? "Top up"
              : transaction.type === "deduction"
              ? "Debit"
              : transaction.type}
          </Typography>
        </Grid>

        <Grid item xs={12} md={2}>
          <Price amount={transaction.amount} />
        </Grid>
        <Grid item xs={12} md={7}>
          <Typography variant="caption" color="textPrimary">
            Remark
          </Typography>
          <br />
          {transaction.remark !== "" ? (
            <Typography variant="caption" color="textPrimary">
              {transaction.remark}
            </Typography>
          ) : (
            <Typography variant="caption" color="textPrimary">
              none
            </Typography>
          )}
        </Grid>
      </Grid>
      <Typography
        variant="caption"
        color="textPrimary"
        sx={{ textAlign: "right" }}
      >
        {moment(transaction.addedOn.toDate()).format("lll")}
      </Typography>
    </Paper>
  );
};

export default WalletTransactionListItem;
