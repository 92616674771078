import { useCollection } from "@/hooks/app-hooks";
import { generateUUIDV4 } from "@/utils/funcs";
import { Grid, Typography } from "@mui/material";
import UserListItem from "./UserListItem";

const UsersList = () => {
  const collection = useCollection();
  return (
    <div>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} md={2}>
          <Typography variant="caption" color="textPrimary">
            Date
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant="caption" color="textPrimary">
            Full Name
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant="caption" color="textPrimary">
            Location
          </Typography>
        </Grid>
        <Grid item xs={12} md={1}>
          <Typography variant="caption" color="textPrimary">
            Phone number
          </Typography>
        </Grid>
        <Grid item xs={12} md={1}>
          <Typography variant="caption" color="textPrimary">
            Persona
          </Typography>
        </Grid>

        <Grid item xs={12} md={1}>
          <Typography variant="caption" color="textPrimary">
            Action
          </Typography>
        </Grid>
      </Grid>
      {collection.view.list?.map((account) => (
        <UserListItem key={generateUUIDV4()} account={account} />
      ))}
    </div>
  );
};

export default UsersList;
