import { LoadingButton } from "@mui/lab";
import { MenuItem, Skeleton, Stack } from "@mui/material";
import { Formik, Form, Field } from "formik";
import { TextField, Select } from "formik-mui";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { ISessionState } from "@/db/session-slice";
import { UpdateAccountValidation } from "@/validations/session/profileFormValidation";
import { sessionRepository } from "@/repositories/root";
import { showSnackbar } from "@/helpers/snackbar-helpers";
import Spacer from "@/components/common/Spacer";
import { useSession } from "@/hooks/app-hooks";

//@ts-ignore
import {
  getStatesByShort,
  getCities,
  //@ts-ignore
} from "countrycitystatejson";
import { generateUUIDV4, stringToArray } from "@/utils/funcs";

const abujaCities = [
  "Brains & Hammers, City",
  "Brains & Hammers, Life Camp",
  "Brains & Hammers, Galadimawa",
  "The Capital Hub",
  "The District By Dantata",
  "Earthpoint, Lifecamp",
  "The Palms Estate, Dawaki",
  "M. I Ahmad Plaza",
  "Uptown Apartments",
  "Norren Court, Gwarinpa",
  "Bellavue 2, Lifecamp",
  "Pacific Height",
  "Cosgrove, Mabushi",
  "Brains & Hammers, Games Village, Kaura",
  "Jinifa Plaza",
  "Cosgrove, Wuye",
];

const ProfileForm = () => {
  const profile = useSession() as ISessionState;
  if (profile.uid === "")
    return (
      <Skeleton variant="rectangular" sx={{ height: 300, borderRadius: 2 }} />
    );

  return (
    <div>
      <Formik
        validationSchema={UpdateAccountValidation}
        initialValues={{
          uid: profile.uid, 
          firstName: profile.firstName,
          lastName: profile.lastName,
          username: profile.username,
          phoneNumber: profile.phoneNumber,
          state: profile.address.state,
          city: profile.address.city,
          metadata: {},
        }}
        onSubmit={async (values, { setSubmitting }) => {
          values.metadata = {
            query: stringToArray(
              `${values.phoneNumber} ${values.firstName} ${values.lastName} ${values.username} ${values.state} ${values.phoneNumber}`
            ),
          };
          const { status, successMessage, errorMessage } =
            await sessionRepository.updateAccount({
              ...values,
              address: { state: values.state, city: values.city },
            });
          if (status === "success") {
            setSubmitting(false);
            showSnackbar({
              status,
              openSnackbar: true,
              msg: successMessage,
            });
          }
          if (status === "error") {
            setSubmitting(false);
            showSnackbar({
              status,
              openSnackbar: true,
              msg: errorMessage,
            });
          }
        }}
      >
        {({
          submitForm,
          isSubmitting,
          values,
          setFieldValue,
          errors,
          touched,
        }) => (
          <Form style={{ padding: "10px" }}>
            <Field
              component={TextField}
              name="firstName"
              type="text"
              label="First name"
              fullWidth
              variant="filled"
            />
            <Spacer space={10} />
            <Field
              component={TextField}
              name="lastName"
              type="text"
              label="Last name"
              fullWidth
              variant="filled"
            />
            <Spacer space={10} />

            <Field
              component={TextField}
              name="username"
              type="text"
              label="Username"
              fullWidth
              variant="filled"
            />
            <Spacer space={10} />

            <PhoneInput
              inputProps={{
                name: "phoneNumber",
              }}
              country={"ng"}
              enableSearch={true}
              value={values.phoneNumber}
              onChange={(phone) => {
                setFieldValue("phoneNumber", phone, true);
              }}
              inputStyle={{
                width: "100%",
                backgroundColor: "transparent",
                border: "1px solid white",
                borderBottom: "1px solid gray",
              }}
              disabled
            />

            {touched.phoneNumber && errors.phoneNumber && (
              <p
                style={{
                  color: "#d32f2f",
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                  fontWeight: 400,
                  fontSize: "0.75rem",
                  lineHeight: 1.66,
                  letterSpacing: "0.03333em",
                  marginTop: 0,
                }}
              >
                {errors.phoneNumber}
              </p>
            )}
            <Spacer space={10} />
            <Field
              component={Select}
              formControl={{ fullWidth: true, variant: "filled" }}
              id="address-state"
              labelId="address-state"
              label="Select your state"
              name="state"
            >
              <MenuItem value={""}>Select a State</MenuItem>
              {getStatesByShort("NG")?.map((item: any) => (
                <MenuItem key={generateUUIDV4()} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Field>
            <Spacer space={10} />

            <Field
              component={Select}
              formControl={{ fullWidth: true, variant: "filled" }}
              id="address-city"
              name="city"
              labelId="address-city"
              label="Select your city"
            >
              <MenuItem value={""}>Select a City</MenuItem>
              {getCities("NG", values.state)?.map((item: any) => (
                <MenuItem key={generateUUIDV4()} value={item}>
                  {item}
                </MenuItem>
              ))}
              {values.state === "Abuja (Federal Capital Territory)" &&
                abujaCities?.map((item: any) => (
                  <MenuItem key={generateUUIDV4()} value={item}>
                    {item}
                  </MenuItem>
                ))}
            </Field>
            <Spacer space={10} />
            <Stack alignItems={"center"}>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitForm}
                color="primary"
                variant="contained"
              >
                Update
              </LoadingButton>
            </Stack>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ProfileForm;
