import Spacer from "@/components/common/Spacer";
import { APP_CURRENCY } from "@/constants/app";
import { ISessionState } from "@/db/session-slice";
import { showSnackbar } from "@/helpers/snackbar-helpers";
import { useSession } from "@/hooks/app-hooks";
import { collectionServices, walletServices } from "@/services/root";
import { ICoupon } from "@/types/coupon-types";
import { generateUUIDV4, reloadPage } from "@/utils/funcs";
import { LoadingButton } from "@mui/lab";
import { Stack, TextField } from "@mui/material";
import { increment } from "firebase/firestore";
import { ChangeEvent, useState } from "react";

interface Props {
  close?: any;
}
const CouponCodeForm = ({ close }: Props) => {
  const [code, setCode] = useState("");
  const profile = useSession() as ISessionState;
  const [isSubmitting, setIsSubmitting] = useState(false);
  return (
    <Stack alignItems="center">
      <TextField
        value={code}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setCode(event.target.value);
        }}
        variant="filled"
        fullWidth
      />
      <Spacer space={20} />
      <LoadingButton
        loading={isSubmitting}
        disabled={isSubmitting}
        variant="contained"
        color="primary"
        onClick={async () => {
          setIsSubmitting(true);
          if (code === "") {
            showSnackbar({
              status: "error",
              msg: "coupon code is empty",
              openSnackbar: true,
            });
            setIsSubmitting(false);
          } else {
            const {
              status: CouponRecordByEmailAndCodeStatus,
              errorMessage: CouponRecordByEmailAndCodeErrorMessage,
              list: CouponRecordByEmailAndCodeList,
            } = await collectionServices.getDocs("CouponRecords", [
              {
                uField: "email",
                uid: profile.email,
              },
              {
                uField: "code",
                uid: code,
              },
            ]);

            setIsSubmitting(false);
            if (CouponRecordByEmailAndCodeStatus === "success") {
              if (CouponRecordByEmailAndCodeList.length > 0) {
                showSnackbar({
                  status: CouponRecordByEmailAndCodeStatus,
                  msg: "You have used this coupon code already!",
                  openSnackbar: true,
                });
                setIsSubmitting(false);
              } else {
                setIsSubmitting(true);
                const { status, errorMessage, list } =
                  await collectionServices.getDocs("Coupons", [
                    {
                      uField: "code",
                      uid: code,
                    },
                  ]);
                if (status === "success" && list) {
                  const _coupon = list[0] as ICoupon;
                  setIsSubmitting(false);
                  if (list.length === 0) {
                    showSnackbar({
                      status: "error",
                      msg: "Coupon code does not exist",
                      openSnackbar: true,
                    });
                  } else if (_coupon.status === "inactive") {
                    showSnackbar({
                      status: "info",
                      msg: "Coupon code is inactive",
                      openSnackbar: true,
                    });
                  } else if (_coupon.threshold === _coupon.totalCount) {
                    showSnackbar({
                      status: "warning",
                      msg: "Coupon code is expired!",
                      openSnackbar: true,
                    });
                  } else {
                    const _coupon = list[0] as ICoupon;
                    const _couponInput = {
                      id: generateUUIDV4(),
                      couponId: _coupon.id,
                      email: profile.email,
                      credit: _coupon.credit,
                      userId: profile.uid,
                      code: _coupon.code,
                      source: "in-app",
                      userName: `${profile.firstName} ${profile.lastName}`,
                    };
                    const addCouponRecordPromise =
                      await collectionServices.addDoc(
                        "CouponRecords",
                        _couponInput.id,
                        _couponInput
                      );
                    const addCreditPromise = await collectionServices.editDoc(
                      "Users",
                      profile.uid,
                      {
                        wallet: {
                          credit:
                            profile.wallet?.credit && profile.wallet?.credit > 0
                              ? increment(_coupon.credit)
                              : _coupon.credit,
                          overallIn: increment(_coupon.credit),
                        },
                      }
                    );
                    const incrementCouponTotalCountPromise =
                      await collectionServices.editDocWithTransaction(
                        "Coupons",
                        _couponInput.couponId,
                        {
                          totalCount: increment(1),
                        }
                      );
                    const recordTransactionPromise =
                      walletServices.recordWalletTransaction({
                        amount: _coupon.credit,
                        persona: "customer",
                        uid: generateUUIDV4(),
                        remark: `Credit of ${APP_CURRENCY}${_coupon.credit} from coupon code ${code}.`,
                        space: "coupon",
                        type: "credit",
                        userId: profile.uid,
                      });

                    const results = await Promise.all([
                      addCouponRecordPromise,
                      addCreditPromise,
                      incrementCouponTotalCountPromise,
                      recordTransactionPromise,
                    ]);

                    const allGood = results.every(
                      (item) => item.status === "success"
                    );

                    if (allGood === true) {
                      setIsSubmitting(false);
                      showSnackbar({
                        status,
                        msg: `Your credit balance has been credited with ${APP_CURRENCY}${_coupon.credit}`,
                        openSnackbar: true,
                      });
                      setTimeout(() => {
                        reloadPage();
                      }, 1000);
                    }

                    results.forEach((result) => {
                      if (result.status === "error") {
                        setIsSubmitting(false);
                        showSnackbar({
                          status,
                          msg: result.errorMessage,
                          openSnackbar: true,
                        });
                      }
                    });
                  }
                }
                if (status === "error") {
                  setIsSubmitting(false);
                  showSnackbar({
                    status,
                    msg: errorMessage,
                    openSnackbar: true,
                  });
                }
              }
            }
            if (CouponRecordByEmailAndCodeStatus === "error") {
              setIsSubmitting(false);
              showSnackbar({
                status: CouponRecordByEmailAndCodeStatus,
                msg: CouponRecordByEmailAndCodeErrorMessage,
                openSnackbar: true,
              });
            }
          }
        }}
      >
        Go
      </LoadingButton>
    </Stack>
  );
};

export default CouponCodeForm;
