import Spacer from "@/components/common/Spacer";
import { Box, Grid, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
// import BGImg from "@/assets/protruding-squares.png";
import { motion } from "framer-motion";
interface Props {
  title: string;
}
const GeneralHeader = ({ title }: Props) => {
  return (
    <Box
      sx={{
        position: "relative",
        height: 300,
        backgroundImage: `url(${require("@/assets/4858838.jpg")})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        mb:7
      }}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Box
        sx={{
          position: "absolute",
          top: "40%",
          left: 0,
          width: "100%",
        }}
      >
        <Grid container>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={6}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              <Typography
                variant="h1"
                color="textPrimary"
                textAlign="center"
                sx={{ fontSize: 60, fontWeight: "bolder" }}
              >
                {title}
              </Typography>
            </motion.div>

            <Spacer space={20} />
          </Grid>
          <Grid item xs={12} md={3}></Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default GeneralHeader;
