import { Paper, Stack, Typography } from "@mui/material";
import { ReactElement } from "react";

interface Props {
  title: string;
  count: number;
  icon: ReactElement;
}
const MgtStatCount = ({ title, icon, count }: Props) => {
  return (
    <Paper sx={{p:2}}>
      <Stack alignItems='center'>
        {icon}
        <Typography variant="subtitle1" color="textPrimary" sx={{fontSize:"2em"}}>
          {count}
        </Typography>
        <Typography variant="caption" color="textPrimary">
          {title}
        </Typography>
      </Stack>
    </Paper>
  );
};

export default MgtStatCount;
