import {
  KadcoThirdPartyPayment,
  RequestTokenAPIModel,
} from "@/types/transaction-types";
import axios from "axios";

class PowerPayRepository {
  async generateToken(requestTokenAPIModel: RequestTokenAPIModel) {
    return axios.post(
      `${process.env.REACT_APP_POWER_PAY_API_HOST}/kadco-vending/generate-token`,
      {
        ...requestTokenAPIModel,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Methods": "POST, GET, PUT",
        },
      }
    );
  }
  async getCustomer(meterNumber: string) {
    return axios.get(
      `${process.env.REACT_APP_POWER_PAY_API_HOST}/kadco-vending/get-customer/${meterNumber}`,
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Methods": "POST, GET, PUT",
        },
      }
    );
  }

  async thirdPartyPayment(kadcoThirdPartyPaymentDto: KadcoThirdPartyPayment) {
    return axios.post(
      `${process.env.REACT_APP_POWER_PAY_API_HOST}/kadco-vending/third-party-payment`,
      {
        ...kadcoThirdPartyPaymentDto,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Methods": "POST, GET, PUT",
        },
      }
    );
  }

  async hashPassword(plaintextPassword: string) {
    return axios.post(
      `${process.env.REACT_APP_POWER_PAY_API_HOST}/auth/hash-password`,
      {
        plaintextPassword,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Methods": "POST, GET, PUT",
        },
      }
    );
  }

  async comparePassword(plaintextPassword: string, hash: string) {
    return axios.post(
      `${process.env.REACT_APP_POWER_PAY_API_HOST}/auth/compare-password`,
      {
        plaintextPassword,
        hash,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Methods": "POST, GET, PUT",
        },
      }
    );
  }
  async createCustomToken(uid: string) {
    return axios.post(
      `${process.env.REACT_APP_POWER_PAY_API_HOST}/auth/create-custom-token`,
      { uid },
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Methods": "POST, GET, PUT",
        },
      }
      // `https://us-central1-electricity-vending-web-app.cloudfunctions.net/powerPayAPI/create-custom-token`,
    );
  }
}

export default PowerPayRepository;
