import EmptyList from "@/components/common/EmptyList";

import { APP_NAME } from "@/constants/app";
import { collectionActions } from "@/db/collection-slice";
import { setPageState } from "@/helpers/collection-helpers";
import { collectionServices } from "@/services/root";
import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ViewTransactionContainer from "@/components/transaction/view-transaction/ViewTransactionContainer";
import { IHaskeTokenDocument } from "@/types/transaction-types";
// import Button from "@mui/material/Button";
// import { Stack } from "@mui/material";
// import { useReactToPrint } from "react-to-print";

const ClientViewTransaction = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [transaction, setTransaction] =
    useState<IHaskeTokenDocument | undefined>(undefined);

    const componentRef = useRef(null);
    // const handlePrint = useReactToPrint({
    //   content: () => componentRef.current,
    // });
  

  useEffect(() => {
    (async () => {
      if (id) {
        setPageState("customer");
        const {
          status: _status,
          errorMessage,
          item,
        } = await collectionServices.getDoc("TokenTransactions", id);

        if (_status === "success" && item) {
          setIsLoading(false);
          setTransaction(item);
          dispatch(collectionActions.setCollectionStateCurrent(item));
        }
        if (_status === "error") {
          setIsLoading(false);
          if (errorMessage) {
            setErrorMessage(errorMessage);
          }
        }
        setStatus(_status);
      }
    })();
  }, [id, dispatch]);

  if (isLoading) return <p>Loading...</p>;
  if (status === "error") return <EmptyList title={errorMessage} caption="." />;

  return (
    <div>
      <Helmet>
        <title>
          {transaction?.transactionID} - {APP_NAME}
        </title>
        <meta name="description" content="Haske" />
      </Helmet>
      {status === "success" && isEmpty(transaction) === false && transaction && (
        <>
          <ViewTransactionContainer   ref={componentRef}/>
          {/* <Stack alignItems="center">
            <Button variant="contained" color="primary" sx={{ color: "#fff" }} onClick={handlePrint}>
              Print
            </Button>
          </Stack> */}
        </>
      )}
      {status === "success" && isEmpty(transaction) === true && (
        <EmptyList title="Product not found" caption="." />
      )}
    </div>
  );
};

export default ClientViewTransaction;
