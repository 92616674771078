import AccountContainer from "@/components/account/AccountContainer";
import DashboardSectionLayout from "@/components/dashboard/DashboardSectionLayout";
import DashboardHeader from "@/components/headers/DashboardHeader";

const ClientAccount = () => {
  return (
    <>
      <DashboardHeader title="My Account" />
      <DashboardSectionLayout>
        <AccountContainer />
      </DashboardSectionLayout>
    </>
  );
};

export default ClientAccount;
