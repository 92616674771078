import {Grid } from "@mui/material";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const DashboardSectionLayout = ({ children }: Props) => {
  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={12} md={1}></Grid>
      <Grid item xs={12} md={10}>
      {children}
        {/* <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              position: "absolute",
              top: -100,
              left: 0,
              width: "96%",
              p: 1,
            }}
          >
            {children}
          </Box>
        </Box> */}
      </Grid>
      <Grid item xs={12} md={1}></Grid>
    </Grid>
  );
};

export default DashboardSectionLayout;
