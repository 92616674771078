import EmptyList from "@/components/common/EmptyList";
import LoadingScreen from "@/components/common/LoadingScreen";
import DashboardSectionLayout from "@/components/dashboard/DashboardSectionLayout";
import DashboardHeader from "@/components/headers/DashboardHeader";
import BuyTokenBtn from "@/components/token/BuyTokenBtn";
import TransactionContainer from "@/components/transaction/TransactionContainer";
import {
  getDocs,
  setPageState,
  setShowLoadMoreBtn,
} from "@/helpers/collection-helpers";
import { useCollection, useSession } from "@/hooks/app-hooks";
import { useEffect } from "react";

const ClientTransaction = () => {
  const collectionState = useCollection();
  const profile = useSession();

  useEffect(() => {
    (async () => {
      if (profile.uid) {
        await getDocs("TokenTransactions", [
          {
            uField: "userId",
            uid: profile.uid ? profile.uid : "sjj",
          },
        ]);

        setPageState("customer");
        setShowLoadMoreBtn(true);
      }
    })();
  }, [profile.uid]);

  if (collectionState.isLoading) return <LoadingScreen />;
  if (collectionState.status === "error")
    return (
      <EmptyList
        title={"An error occured"}
        caption="Click on the button below to buy token"
        action={<BuyTokenBtn />}
      />
    );

  return (
    <>
      {/* {collectionState.status === "success" && collectionState.all.count > 0 && ( */}
        <>
          <DashboardHeader title="My Transactions" />
          <DashboardSectionLayout>
            <TransactionContainer />
          </DashboardSectionLayout>
        </>
      {/* // )} */}
      {/* {collectionState.status === "success" &&
        collectionState.all.count === 0 && (
          <EmptyList
            title={"Transaction list is empty"}
            caption="Click on the button below to buy token"
            action={<BuyTokenBtn />}
          />
        )} */}
    </>
  );
};

export default ClientTransaction;
