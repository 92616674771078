import { auth } from "@/configs/firebase";
import {
  CLIENT_ACCOUNT,
  CLIENT_METERS,
  CLIENT_TRANSACTIONS,
  CLIENT_WALLET,
  DASHBOARD_BASE,
} from "@/routes/dashboard-routes";
import { AccountCircle } from "@mui/icons-material";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { MouseEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

const AccountNavThumb = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  if (!auth.currentUser) return <></>;
  return (
    <div>
      {auth && (
        <div>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {/* <MenuItem
              onClick={() => {
                navigate(`/${DASHBOARD_BASE}`);
              }}
            >
              Overview
            </MenuItem> */}
            <MenuItem
              onClick={() => {
                navigate(`/${DASHBOARD_BASE}/${CLIENT_ACCOUNT}`);
              }}
            >
              My account
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate(`/${DASHBOARD_BASE}/${CLIENT_TRANSACTIONS}`);
              }}
            >
              Transactions
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate(`/${DASHBOARD_BASE}/${CLIENT_METERS}`);
              }}
            >
              My Meters
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate(`/${DASHBOARD_BASE}/${CLIENT_WALLET}`);
              }}
            >
              My Wallet
            </MenuItem>
          </Menu>
        </div>
      )}
    </div>
  );
};

export default AccountNavThumb;
