import DashboardSectionLayout from "@/components/dashboard/DashboardSectionLayout";
import MeterForm from "@/components/forms/meter/MeterForm";
import DashboardHeader from "@/components/headers/DashboardHeader";
import { Grid, Paper } from "@mui/material";

const ClientAddMeter = () => {
  return (
    <>
      <DashboardHeader title="Add Meter" />
      <DashboardSectionLayout>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 1 }}>
              <MeterForm />
            </Paper>
          </Grid>
        </Grid>
      </DashboardSectionLayout>
    </>
  );
};

export default ClientAddMeter;
