import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Timestamp } from "firebase/firestore";
import { IAccountDocument } from "types/session-types";

export interface ISessionState extends IAccountDocument {
  isLoaded: boolean;
  isEmpty: boolean;
}

export interface ISetProfile extends ISessionState {}

const initialState: ISessionState = {
  uid: "",
  firstName: "",
  lastName: "",
  username: "",
  email: "",
  phoneNumber: "",
  hash:"",
  uniqueId:"",
  persona: "customer",
  address: {
    state:"",
    city: ""
  },
  wallet: {
    balance: 0,
    pendingPayout: 0,
    totalCommission: 0,
    credit: 0,
  },
  metadata: {
    status: "inactive",
    createdOn: Timestamp.now(),
    query: [],
    queryType: "customer",
  },
  isLoaded: false,
  isEmpty: true,
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<ISetProfile>) => {
      state = { ...action.payload };
      return state;
    },
    reset: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const sessionName = sessionSlice.name;
export const sessionActions = sessionSlice.actions;
export const sessionReducer = sessionSlice.reducer;
