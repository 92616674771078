import { ISessionState } from "@/db/session-slice";
import { showSnackbar } from "@/helpers/snackbar-helpers";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { isUndefined } from "lodash";
import { useEffect, useState } from "react";
import { generateUUIDV4, parseOptionWithMatch } from "@/utils/funcs";
import { useCollection, useSession } from "@/hooks/app-hooks";
import { collectionServices } from "@/services/root";
import { setShowLoadMoreBtn } from "@/helpers/collection-helpers";
import ListAsyncSearchBar from "../common/list/ListAsyncSearchBar";
import { ICollectionState } from "@/db/collection-slice";
import { IAccountDocument } from "@/types/session-types";

const UserSearch = () => {
  const [list, setList] = useState<IAccountDocument[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [query, setQuery] = useState("");
  const collectionState = useCollection() as ICollectionState<IAccountDocument>;
  const profile = useSession() as ISessionState;

  // const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      setList([]);
      setIsSearching(true);
      if (collectionState.page === "mgt") {
        const {
          status,
          list: _list,
          errorMessage,
        } = await collectionServices.queryAllDocs("Users", query);
        if (status === "success") {
          setIsSearching(false);
          const result = _list as IAccountDocument[];
          setList(result);
        }
        if (status === "error") {
          setIsSearching(false);
          showSnackbar({
            status: "error",
            msg: errorMessage,
            openSnackbar: true,
          });
        }
      }
    })();
  }, [query, profile.uid, collectionState]);

  const contains = (meter: IAccountDocument, query: string) => {
    if (!isUndefined(query)) {
      const formattedQuery = query?.toLowerCase();
      const { phoneNumber } = meter;

      if (phoneNumber?.includes(formattedQuery)) {
        return true;
      }
    }

    return false;
  };

  const renderOption = (
    props: any,
    option: IAccountDocument,
    { inputValue }: { inputValue: string }
  ) => {
    const partsPhoneNumber = parseOptionWithMatch(
      `${option.phoneNumber}`,
      inputValue
    );
    // const partsMeterName = parseOptionWithMatch(
    //   `${option.meterName}`,
    //   inputValue
    // );

    return (
      <Box
        component="li"
        {...props}
        key={generateUUIDV4()}
        onClick={() => {
          // navigate(
          //   `/${DASHBOARD_BASE}/${CLIENT_TRANSACTIONS}/${option.transactionID}`
          // );

          setShowLoadMoreBtn(false);
        }}
      >
        <Stack>
          <Typography variant="subtitle2">
            {partsPhoneNumber?.map((part, index) => (
              <span
                key={index}
                style={{
                  color: part.highlight ? "#1b5e20" : "#81c784",
                }}
              >
                {part.text}
              </span>
            ))}
          </Typography>
          {/* <Typography variant="subtitle2">
            {partsMeterName?.map((part, index) => (
              <span
                key={index}
                style={{
                  color: part.highlight ? "#1b5e20" : "#81c784",
                }}
              >
                {part.text}
              </span>
            ))}
          </Typography> */}
        </Stack>
        <Divider sx={{ my: 1 }} />
      </Box>
    );
  };

  const stringify = (option: IAccountDocument) => {
    return ` ${option.phoneNumber?.toLowerCase()}`;
  };

  return (
    <>
      <ListAsyncSearchBar
        list={list}
        contains={contains}
        renderOption={renderOption}
        stringify={stringify}
        placeHolderText={"Search for phonenumber"}
        isSearching={isSearching}
        query={query}
        setQuery={setQuery}
      />
    </>
  );
};

export default UserSearch;
