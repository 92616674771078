import { object, string } from "yup";

export const UpdateAccountValidation = object().shape({
  firstName: string()
    .max(35, "its too long")
    .min(2, "its too short")
    .required("Your first name is Required"),
  lastName: string()
    .max(35, "its too long")
    .min(2, "its too short")
    .required("Your last name is Required"),
  username: string()
    .max(35, "its too long")
    .min(2, "its too short")
    .required("Your user name is Required"),
  phoneNumber: string().required("Your phone number is Required"),
  state: string().required("Your State is Required"),
  city: string().required("Your City is Required"),
});
