import { Grid } from "@mui/material";
import Spacer from "../common/Spacer";
import TransactionFilter from "./TransactionFilter";
import TransactionList from "./TransactionList";
import TransactionsLoadMore from "./TransactionsLoadMore";
import TransactionsSearch from "./TransactionsSearch";

const TransactionContainer = () => {
  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={12} md={6} sx={{ pb: { xs: 5 }, px: { xs: 2 } }}>
          <TransactionsSearch />
        </Grid>
        <Grid item xs={12} md={6}>
          <TransactionFilter />
        </Grid>
      </Grid>

      <Spacer space={20} />
      <TransactionList />
      <Spacer space={20} />
      <TransactionsLoadMore />
    </>
  );
};

export default TransactionContainer;
