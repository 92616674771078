import DashboardHeader from "@/components/headers/DashboardHeader";
import MgtDasboardComponent from "@/components/mgt/mgtDashboard/MgtDasboardComponent";

const MgtDashboard = () => {
  return (
    <>
      <DashboardHeader title="Management" />
      <MgtDasboardComponent />
    </>
  );
};

export default MgtDashboard;
