import DashboardSectionLayout from "@/components/dashboard/DashboardSectionLayout";
import BuyTokenForm from "@/components/forms/token/BuyTokenForm";
import DashboardHeader from "@/components/headers/DashboardHeader";

const ClientBuyToken = () => {
  return (
    <>
      <DashboardHeader title="Buy token" />
      <DashboardSectionLayout>
        <BuyTokenForm />
      </DashboardSectionLayout>
    </>
  );
};

export default ClientBuyToken;
