import { db, auth } from "@/configs/firebase";
import {
  deleteDoc,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import {
  updateProfile,
  updateEmail,
  sendEmailVerification,
  updatePassword,
  sendPasswordResetEmail,
  deleteUser,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  UserInfo,
  signInWithCustomToken,
} from "firebase/auth";
import { COLLECTIONS } from "@/constants/collection-constants";
import { IImage } from "types/global-types";
import { IAccountDocument, IAccountInput } from "types/session-types";
import { stringToArray } from "@/utils/funcs";

class SessionModel {
  async getAccount(uid: string) {
    const docRef = doc(db, COLLECTIONS.users, uid);
    return getDoc(docRef);
  }
  async createAccount(values: Omit<IAccountDocument, "metadata">) {
    const newValues: Partial<IAccountInput> = values;

    delete newValues["password"];
    delete newValues["confirmpassword"];

    return setDoc(doc(db, COLLECTIONS.users, values.uid), {
      ...newValues,
      metadata: {
        status: "active",
        createdOn: serverTimestamp(),
        query: [
          ...stringToArray(
            `${values.firstName.toLowerCase()} 
                ${values.lastName.toLowerCase()} 
                ${values.username.toLowerCase()} 
                ${values.phoneNumber.toLowerCase()} 
                ${values.email.toLowerCase()}
                ${values.persona.toLowerCase()}
                `
          ),
          values.firstName.toLowerCase(),
          values.lastName.toLowerCase(),
          values.username.toLowerCase(),
          values.phoneNumber.toLowerCase(),
          values.email.toLowerCase(),
          values.persona.toLowerCase(),
        ],
        queryType: "account",
      },
    });
  }
  async updateAccount(
    values: Required<Pick<IAccountInput, "uid">> & Partial<IAccountInput>
  ) {
    return setDoc(
      doc(db, COLLECTIONS.users, values.uid),
      {
        ...values,
      },
      { merge: true }
    );
  }
  async updateAccountPhoto(values: IImage & { id: string }) {
    return setDoc(
      doc(db, COLLECTIONS.users, values.id),
      {
        photo: {
          name: values.name,
          url: values.url,
        },
      },
      { merge: true }
    );
  }

  async updateUserCredential(values: UserInfo) {
    if (auth.currentUser)
      return updateProfile(auth.currentUser, {
        ...values,
      });
  }
  async updateUserEmail(email: string) {
    if (auth.currentUser) return updateEmail(auth.currentUser, email);
  }
  async sendEmailVerificationToUser() {
    if (auth.currentUser) return sendEmailVerification(auth.currentUser);
  }
  async updateUserPassword(newPassword: string) {
    if (auth.currentUser) return updatePassword(auth.currentUser, newPassword);
  }
  async sendPasswordResetEmailToUser(email: string) {
    return sendPasswordResetEmail(auth, email);
  }
  async deleteUserForever() {
    if (auth.currentUser) return deleteUser(auth.currentUser);
  }

  async createUserAccount(email: string, password: string) {
    return createUserWithEmailAndPassword(auth, email, password);
  }
  async signIn(email: string, password: string) {
    return signInWithEmailAndPassword(auth, email, password);
  }
  async signInWithCustomToken(token: string) {
    return signInWithCustomToken(auth, token);
  }

  async logOut() {
    return signOut(auth);
  }

  // make active
  async makeUserActive(id: string) {
    const docRef = doc(db, COLLECTIONS.users, id);
    return setDoc(
      docRef,
      {
        metadata: {
          status: "active",
        },
      },
      {
        merge: true,
      }
    );
  }

  // make inactive
  async makeUserInActive(id: string) {
    const docRef = doc(db, COLLECTIONS.users, id);
    return setDoc(
      docRef,
      {
        metadata: {
          status: "inactive",
        },
      },
      {
        merge: true,
      }
    );
  }

  // delete permanently
  async deleteUserPermanently(id: string) {
    const docRef = doc(db, COLLECTIONS.users, id);
    return deleteDoc(docRef);
  }

  // make blocked
  async makeUserBlocked(id: string) {
    const docRef = doc(db, COLLECTIONS.users, id);
    return setDoc(
      docRef,
      {
        metadata: {
          status: "blocked",
        },
      },
      {
        merge: true,
      }
    );
  }
}

export default SessionModel;
