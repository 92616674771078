import {
  Backdrop,
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import vSwitchLogo from "@/assets/v-switch.jpg";

interface Props {
  visible: boolean;
  close: () => void;
  data: {
    recieptNo: string;
    meterNumber: string;
    customerName: string;
    customerPhonenumber: string;
    transactionId: string;
    units: string;
    cost: number;
    address: string;
    token: string;
  };
}
const TokenTransactionReciept = ({ data, visible, close }: Props) => {
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [copied, setCopied] = useState(false);

  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 4,
        marginLeft: 0,
      }}
      open={visible}
    >
      <div>
        <Box
          sx={{
            minHeight: 600,
            width: { xs: "86vw", md: 300 },
            borderRadius: 5,
            bgcolor: "background.paper",
            p: 2,
          }}
          ref={componentRef}
        >
          <Stack alignItems="center" spacing={1}>
            <Typography
              variant="caption"
              color="textPrimary"
              textAlign="center"
            >
              **** CUSTOMER'S COPY ****
            </Typography>
            <img src={vSwitchLogo} alt="virtual switch" width={170} />

            <Typography variant="subtitle2" color="textPrimary">
              Kaduna Electric.
            </Typography>
            <Typography variant="subtitle2" color="textPrimary">
              PREPAID
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="caption"
                color="textPrimary"
                sx={{
                  fontWeight: "bold",
                  width: 100,
                  textAlign: "left",
                  lineHeight: 1,
                }}
              >
                RECIEPT NO:
              </Typography>
              <Typography
                variant="overline"
                color="textPrimary"
                sx={{
                  fontWeight: "bold",
                  width: 200,
                  textAlign: "right",
                  lineHeight: 1,
                }}
              >
                {data.recieptNo}
              </Typography>
            </Stack>
            <Typography variant="body2" color="textPrimary">
              ****************************************
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="caption"
                color="textPrimary"
                sx={{
                  width: 100,
                  textAlign: "left",
                  lineHeight: 1,
                }}
              >
                DATE:
              </Typography>
              <Typography
                variant="overline"
                color="textPrimary"
                sx={{
                  fontWeight: "bold",
                  width: 200,
                  textAlign: "right",
                  lineHeight: 1,
                }}
              >
                {new Date().toDateString()}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="caption"
                color="textPrimary"
                sx={{
                  width: 100,
                  textAlign: "left",
                  lineHeight: 1,
                }}
              >
                BILLER:
              </Typography>
              <Typography
                variant="overline"
                color="textPrimary"
                sx={{
                  fontWeight: "bold",
                  width: 200,
                  textAlign: "right",
                  lineHeight: 1,
                }}
              >
                Kaduna Electric Distribution Prepaid
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="caption"
                color="textPrimary"
                sx={{
                  width: 100,
                  textAlign: "left",
                  lineHeight: 1,
                }}
              >
                METER NUMBER:
              </Typography>
              <Typography
                variant="overline"
                color="textPrimary"
                sx={{
                  fontWeight: "bold",
                  width: 200,
                  textAlign: "right",
                  lineHeight: 1,
                }}
              >
                {data.meterNumber}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="caption"
                color="textPrimary"
                sx={{
                  width: 100,
                  textAlign: "left",
                  lineHeight: 1,
                }}
              >
                CUSTOMER NAME:
              </Typography>
              <Typography
                variant="overline"
                color="textPrimary"
                sx={{
                  fontWeight: "bold",
                  width: 200,
                  textAlign: "right",
                  lineHeight: 1,
                }}
              >
                {data.customerName}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="caption"
                color="textPrimary"
                sx={{
                  width: 100,
                  textAlign: "left",
                  lineHeight: 1,
                }}
              >
                CUSTOMER PHONENUMBER:
              </Typography>
              <Typography
                variant="overline"
                color="textPrimary"
                sx={{
                  fontWeight: "bold",
                  width: 200,
                  textAlign: "right",
                  lineHeight: 1,
                }}
              >
                {data.customerPhonenumber || "nil"}
              </Typography>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="caption"
                color="textPrimary"
                sx={{
                  width: 100,
                  textAlign: "left",
                  lineHeight: 1,
                }}
              >
                REFERENCE:
              </Typography>
              <Typography
                variant="overline"
                color="textPrimary"
                sx={{
                  fontWeight: "bold",
                  width: 200,
                  textAlign: "right",
                  lineHeight: 1,
                }}
              >
                {data.transactionId}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="caption"
                color="textPrimary"
                sx={{
                  width: 100,
                  textAlign: "left",
                  lineHeight: 1,
                }}
              >
                UNITS:
              </Typography>
              <Typography
                variant="overline"
                color="textPrimary"
                sx={{
                  fontWeight: "bold",
                  width: 200,
                  textAlign: "right",
                  lineHeight: 1,
                }}
              >
                {data.units} kWh
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="caption"
                color="textPrimary"
                sx={{
                  width: 100,
                  textAlign: "left",
                  lineHeight: 1,
                }}
              >
                COST:
              </Typography>
              <Typography
                variant="overline"
                color="textPrimary"
                sx={{
                  fontWeight: "bold",
                  width: 200,
                  textAlign: "right",
                  lineHeight: 1,
                }}
              >
                {new Intl.NumberFormat(undefined, {
                  style: "currency",
                  currency: "NGN",
                }).format(data.cost)}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="caption"
                color="textPrimary"
                sx={{
                  width: 100,
                  textAlign: "left",
                  lineHeight: 1,
                }}
              >
                ADDRESS:
              </Typography>
              <Typography
                variant="overline"
                color="textPrimary"
                sx={{
                  fontWeight: "bold",
                  width: 200,
                  textAlign: "right",
                  lineHeight: 1,
                }}
              >
                {data.address}
              </Typography>
            </Stack>
            <Typography variant="caption" color="textPrimary">
              *********************************
            </Typography>
            <Typography color="textPrimary" textAlign="center" sx={{ my: 3 }}>
              <code>TOKEN</code>
            </Typography>
            <Box
              boxShadow={1}
              sx={{
                borderRadius: 10,
                p: 1,
                position: "relative",
              }}
            >
              <Typography color="textPrimary" textAlign="center">
                {copied ? (
                  <span style={{ color: "green" }}>Copied.</span>
                ) : (
                  <code> {data.token}</code>
                )}
              </Typography>
              <Box sx={{ position: "absolute", right: -40, bottom: 0 }}>
                <CopyToClipboard
                  text={data.token}
                  onCopy={() => {
                    setCopied(true);
                    setTimeout(() => {
                      setCopied(false);
                    }, 1000);
                  }}
                >
                  <Tooltip title="copy token">
                    <IconButton>
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                </CopyToClipboard>
                <br />
              </Box>
            </Box>

            <Typography variant="caption" color="textPrimary">
              *********************************
            </Typography>
            <Typography variant="caption" color="textPrimary">
              Thanks, Come again
            </Typography>
          </Stack>
        </Box>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{ mt: 1 }}
        >
          <Button
            variant="contained"
            color="primary"
            sx={{ color: "#fff" }}
            onClick={close}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ color: "#fff" }}
            onClick={handlePrint}
          >
            Print
          </Button>
        </Stack>
      </div>
    </Backdrop>
  );
};

export default TokenTransactionReciept;
