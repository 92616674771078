import DashboardSectionLayout from "@/components/dashboard/DashboardSectionLayout";
import DashboardHeader from "@/components/headers/DashboardHeader";
import MgtTransactionReport from "@/components/reports/MgtTransactionReport";

const MgtReports = () => {
  return (
    <>
      <DashboardHeader title="Manage Reports" />
      <DashboardSectionLayout>
        <MgtTransactionReport />
      </DashboardSectionLayout>
    </>
  );
};

export default MgtReports;
