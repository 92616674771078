import Spacer from "@/components/common/Spacer";
import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";
import {
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { useEffect, useState } from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { find } from "lodash";

import { IPSBank, IPSResolveAccountDetails } from "@/types/wallet-types";
import { showSnackbar } from "@/helpers/snackbar-helpers";
import { reloadPage } from "@/utils/funcs";
import { walletServices } from "@/services/root";
import { pageTypes } from "@/types/pages-types";

const Wrapper = styled.div`
  padding: 20px;
`;

interface Props {
  persona: pageTypes;
  userId: string;
  close?: any;
}

const AddBankForm = ({ close, persona, userId }: Props) => {
  const [banklist, setBanklist] = useState<IPSBank[]>([]);
  const [selectedBank, setSelectedBank] = useState<IPSBank>({
    active: false,
    code: "",
    id: 0,
    name: "",
  });

  const [resolvedBankAccountInfo, setResolvedBankAccountInfo] =
    useState<IPSResolveAccountDetails>({
      account_name: "",
      account_number: "",
    });

  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    const getBanks = async () => {
      const { errorMessage, data, status } = await walletServices.getbanks({
        country: "nigeria",
        currency: "NGN",
      });
      if (status === "success") {
        setBanklist(data);
      }
      if (status === "error") {
        showSnackbar({
          openSnackbar: true,
          status: "error",
          msg: errorMessage,
        });
      }
    };

    getBanks();
  }, []);

  return (
    <Wrapper>
      <Formik
        initialValues={{
          bank_code: "",
          account_number: "",
        }}
        onSubmit={async (values, { setSubmitting }) => {
          if (persona === "customer") {
            const { errorMessage, status, successMessage } =
              await walletServices.createPSTransferReceiptCode({
                accountName: resolvedBankAccountInfo.account_name,
                bankCode: selectedBank.code,
                accountNumber: resolvedBankAccountInfo.account_number,
                persona: persona,
                bankName: selectedBank.name,
                userId: userId,
              });
            if (status === "success") {
              close();
              showSnackbar({
                openSnackbar: true,
                status: "success",
                msg: successMessage,
              });

              setSubmitting(false);
              reloadPage();
            }
            if (status === "error") {
              showSnackbar({
                openSnackbar: true,
                status: "error",
                msg: errorMessage,
              });
              setSubmitting(false);
            }
          }
         
          if (persona === "mgt") {
            const { errorMessage, status, successMessage } =
              await walletServices.createMgtPSTransferReceiptCode({
                accountName: resolvedBankAccountInfo.account_name,
                bankCode: selectedBank.code,
                accountNumber: resolvedBankAccountInfo.account_number,
                persona: persona,
                bankName: selectedBank.name,
                userId: userId,
              });
            if (status === "success") {
              close();
              showSnackbar({
                openSnackbar: true,
                status: "success",
                msg: successMessage,
              });

              setSubmitting(false);
              reloadPage();
            }
            if (status === "error") {
              showSnackbar({
                openSnackbar: true,
                status: "error",
                msg: errorMessage,
              });
              setSubmitting(false);
            }
          }
        }}
      >
        {({ submitForm, isSubmitting, values, resetForm }) => (
          <Form>
            <FormControl variant="filled" fullWidth>
              <InputLabel id="ps-bank-list-filled-label">
                {" "}
                Select bank
              </InputLabel>
              <Select
                labelId="ps-bank-list-filled-label"
                id="ps-bank-list-filled"
                value={selectedBank.code}
                onChange={async (event: SelectChangeEvent) => {
                  const findBank = find(banklist, { code: event.target.value });
                  if (findBank) {
                    setSelectedBank(findBank);
                  }
                }}
              >
                {banklist?.map((bank) => (
                  <MenuItem key={bank.id} value={bank.code}>
                    {bank.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Field
              component={TextField}
              name="account_number"
              type="text"
              label="Account number"
              fullWidth
              variant="standard"
            />

            {resolvedBankAccountInfo.account_name !== "" &&
              values.account_number !== "" && (
                <>
                  <Spacer space={10} />
                  <Typography variant="caption" color="textPrimary">
                    Account number verified!
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemText
                        primary={resolvedBankAccountInfo.account_name}
                        secondary={"Account name"}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={resolvedBankAccountInfo.account_number}
                        secondary={"Account number"}
                      />
                    </ListItem>
                  </List>
                </>
              )}
            <Spacer space={40} />
            <Stack
              alignItems={"center"}
              direction={"row"}
              justifyContent={
                resolvedBankAccountInfo.account_name !== "" &&
                values.account_number !== ""
                  ? "space-between"
                  : "center"
              }
            >
              <LoadingButton
                variant="contained"
                loading={isSubmitting || isLoading}
                onClick={async () => {
                  if (values.account_number.length < 2) {
                    showSnackbar({
                      openSnackbar: true,
                      status: "error",
                      msg: `Please enter your bank account`,
                    });
                  } else if (selectedBank.code === "") {
                    showSnackbar({
                      openSnackbar: true,
                      status: "error",
                      msg: `Please select your bank`,
                    });
                  } else if (resolvedBankAccountInfo.account_name === "") {
                    setisLoading(true);

                    const { errorMessage, status, data } =
                      await walletServices.resolveAccountDetails({
                        accountNumber: values.account_number,
                        bankCode: selectedBank.code,
                      });

                    if (status === "success") {
                      setResolvedBankAccountInfo(data);

                      setisLoading(false);
                    }
                    if (status === "error") {
                      showSnackbar({
                        openSnackbar: true,
                        status: "error",
                        msg: errorMessage,
                      });

                      setisLoading(false);
                    }
                  } else {
                    submitForm();
                  }
                }}
              >
                {values.account_number === "" || selectedBank.code === ""
                  ? "Verify bank account"
                  : resolvedBankAccountInfo.account_name !== ""
                  ? "Add bank account"
                  : "Verify"}
              </LoadingButton>
              {resolvedBankAccountInfo.account_name !== "" &&
                values.account_number !== "" && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      resetForm();
                      setResolvedBankAccountInfo({
                        account_name: "",
                        account_number: "",
                      });
                    }}
                  >
                    Reset
                  </Button>
                )}
            </Stack>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

export default AddBankForm;
