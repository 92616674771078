import { ChartTimeFrameOptions } from "@/constants/stats-contants";
import { collectionServices } from "@/services/root";
import { IAccountDocument } from "@/types/session-types";
import { IChartTimeFrame } from "@/types/stat-stypes";
import { generateUUIDV4 } from "@/utils/funcs";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { Timestamp } from "firebase/firestore";
import { groupBy, map, mapValues, omit } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Brush,
  Legend,
} from "recharts";

interface IChartData<T> {
  key: string;
  data: T[];
}

interface IBarChartData {
  name: string;
  meterCount: number;
}

const getGroupedListByDay = (list: any) => {
  const now = moment();
  const convertedTimestamp = map(list, (item: any) => {
    return {
      ...item,
      convertedTimestsamp: moment(item.addedOn.toDate()).format("YYYY-MM-DD"),
      formated: moment(now).isSame(item.addedOn.toDate(), "day"),
    };
  });
  const grouped = mapValues(
    groupBy(convertedTimestamp, "convertedTimestsamp"),
    (clist) => clist.map((chats) => omit(chats, "convertedTimestsamp"))
  );

  const formattedData = Object.keys(grouped).map((key) => {
    return {
      key,
      data: grouped[key],
    };
  });

  return formattedData;
};

const MgtMeterStats = () => {
  const [selectTimeFrame, setSelectTimeFrame] =
    useState<IChartTimeFrame>("This week");
  const handleChange = (event: SelectChangeEvent) => {
    setSelectTimeFrame(event.target.value as IChartTimeFrame);
  };

  const [processing, setProcessing] = useState(false);

  const [genericChartData, setGenericChartData] = useState<IBarChartData[]>([]);

  useEffect(() => {
    (async () => {
      setProcessing(true);
      if (selectTimeFrame === "Today") {
        const now = moment();
        const aDayFromNow = now.clone().subtract(1, "day").toDate();
        const timestamp = Timestamp.fromDate(aDayFromNow);
        const _today = timestamp;

        const { status, errorMessage, list } = await collectionServices.getDocs(
          "Users",
          [
            {
              uField: "addedOn",
              uid: _today,
              operator: ">=",
              type: "where",
            },
          ],
          10000,
          false
        );

        if (status === "success") {
          const formattedData = getGroupedListByDay(
            list
          ) as IChartData<IAccountDocument>[];

          const _data = formattedData.map((_d) => {
            return {
              name: moment(_d.key).format("MMM DD"),
              meterCount: _d.data.length,
            };
          });
          setGenericChartData(_data.reverse());

          setProcessing(false);
        }
        if (status === "error") {
          console.log(errorMessage);
          setProcessing(false);
        }
      }
      if (selectTimeFrame === "This week") {
        const now = moment();
        const aWeekAgoFromNow = now.clone().subtract(1, "week").toDate();
        const timestamp = Timestamp.fromDate(aWeekAgoFromNow);
        const _this_week = timestamp;

        const { status, errorMessage, list } = await collectionServices.getDocs(
          "Users",
          [
            {
              uField: "addedOn",
              uid: _this_week,
              operator: ">=",
              type: "where",
            },
          ],
          10000,
          false
        );

        if (status === "success") {
          const formattedData = getGroupedListByDay(
            list
          ) as IChartData<IAccountDocument>[];

          const _data = formattedData.map((_d) => {
            return {
              name: moment(_d.key).format("MMM DD"),
              meterCount: _d.data.length,
            };
          });
          setGenericChartData(_data.reverse());

          setProcessing(false);
        }
        if (status === "error") {
          console.log(errorMessage);
          setProcessing(false);
        }
      }
      if (selectTimeFrame === "This Month") {
        const now = moment();
        const aMonthAgoFromNow = now.clone().subtract(1, "month").toDate();
        const timestamp = Timestamp.fromDate(aMonthAgoFromNow);
        const _this_month = timestamp;

        const { status, errorMessage, list } = await collectionServices.getDocs(
          "Users",
          [
            {
              uField: "addedOn",
              uid: _this_month,
              operator: ">=",
              type: "where",
            },
          ],
          10000,
          false
        );

        if (status === "success") {
          const formattedData = getGroupedListByDay(
            list
          ) as IChartData<IAccountDocument>[];

          const _data = formattedData.map((_d) => {
            return {
              name: moment(_d.key).format("MMM DD"),
              meterCount: _d.data.length,
            };
          });
          setGenericChartData(_data.reverse());

          setProcessing(false);
        }
        if (status === "error") {
          console.log(errorMessage);
          setProcessing(false);
        }
      }
      if (selectTimeFrame === "This Year") {
        const now = moment();
        const aYearAgoFromNow = now.clone().subtract(1, "year").toDate();
        const timestamp = Timestamp.fromDate(aYearAgoFromNow);
        const _this_year = timestamp;

        const { status, errorMessage, list } = await collectionServices.getDocs(
          "Users",
          [
            {
              uField: "addedOn",
              uid: _this_year,
              operator: ">=",
              type: "where",
            },
          ],
          10000,
          false
        );

        if (status === "success") {
          const formattedData = getGroupedListByDay(
            list
          ) as IChartData<IAccountDocument>[];

          const _data = formattedData.map((_d) => {
            return {
              name: moment(_d.key).format("MMM DD"),
              meterCount: _d.data.length,
            };
          });
          setGenericChartData(_data.reverse());

          setProcessing(false);
        }
        if (status === "error") {
          console.log(errorMessage);
          setProcessing(false);
        }
      }
      if (selectTimeFrame === "Last 30 days") {
        const now = moment();
        const last30DaysFromNow = now.clone().subtract(30, "days").toDate();
        const timestamp = Timestamp.fromDate(last30DaysFromNow);
        const _last_30_days = timestamp;

        const { status, errorMessage, list } = await collectionServices.getDocs(
          "Users",
          [
            {
              uField: "addedOn",
              uid: _last_30_days,
              operator: ">=",
              type: "where",
            },
          ],
          10000,
          false
        );

        if (status === "success") {
          const formattedData = getGroupedListByDay(
            list
          ) as IChartData<IAccountDocument>[];

          const _data = formattedData.map((_d) => {
            return {
              name: moment(_d.key).format("MMM DD"),
              meterCount: _d.data.length,
            };
          });
          setGenericChartData(_data.reverse());

          setProcessing(false);
        }
        if (status === "error") {
          console.log(errorMessage);
          setProcessing(false);
        }
      }
      if (selectTimeFrame === "Last 90 days") {
        const now = moment();
        const last90DaysFromNow = now.clone().subtract(90, "days").toDate();
        const timestamp = Timestamp.fromDate(last90DaysFromNow);
        const _last_90_days = timestamp;

        const { status, errorMessage, list } = await collectionServices.getDocs(
          "Users",
          [
            {
              uField: "addedOn",
              uid: _last_90_days,
              operator: ">=",
              type: "where",
            },
          ],
          10000,
          false
        );

        if (status === "success") {
          const formattedData = getGroupedListByDay(
            list
          ) as IChartData<IAccountDocument>[];

          const _data = formattedData.map((_d) => {
            return {
              name: moment(_d.key).format("MMM DD"),
              meterCount: _d.data.length,
            };
          });
          setGenericChartData(_data.reverse());

          setProcessing(false);
        }
        if (status === "error") {
          console.log(errorMessage);
          setProcessing(false);
        }
      }
    })();
  }, [selectTimeFrame]);

  return (
    <>
      <Typography variant="h4" color="textPrimary" textAlign="center">
        Meter added Stats
      </Typography>
      <div>
        <FormControl
          variant="filled"
          fullWidth
          sx={{ m: 1, width: 200, fontWeight: "bold" }}
        >
          <InputLabel id="mgt-account-filled-label">
            Select a timeframe
          </InputLabel>
          <Select
            labelId="mgt-account-filled-label"
            id="mgt-account-filled"
            value={selectTimeFrame}
            onChange={handleChange}
          >
            {ChartTimeFrameOptions.map((option) => (
              <MenuItem key={generateUUIDV4()} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {processing && <p>loading...</p>}
      {!processing && genericChartData.length > 0 && (
        <>
          <div style={{ flex: 1, width: "100%", height: 500 }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={300}
                data={genericChartData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Bar
                  dataKey="meterCount"
                  fill="#00C49F"
                  maxBarSize={100}
                  stackId="a"
                />

                <Legend height={36} layout="horizontal" />
                <Brush dataKey="name" height={30} stroke="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </>
      )}
      {!processing && genericChartData.length === 0 && (
        <Stack alignItems="center">
          <LazyLoadImage
            src={require("@/assets/exclamation-mark.png")}
            style={{ width: 100, height: 100 }}
          />
          <Typography variant="subtitle2" color="textPrimary">
            No records
          </Typography>
        </Stack>
      )}
    </>
  );
};

export default MgtMeterStats;
