import { pageTypes } from "@/types/pages-types";
import { IAccountWallet } from "@/types/session-types";
import {
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import Price from "../common/Price";
import WalletActions from "./WalletActions";
import WalletBankAccountInfo from "./WalletBankAccountInfo";
import WalletTransactionList from "./WalletTransactionList";
import BalanceImg from "@/assets/bank-account.png";
import CouponImg from "@/assets/coupon.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface Props {
  userId: string;
  wallet: IAccountWallet | undefined;
  persona: pageTypes;
}
const WalletContainer = ({ userId, wallet, persona }: Props) => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          {/* <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item xs={12} md={6}>
              <Paper
                sx={{ borderRadius: 1, mb: 1, bgcolor: "rgba(0,255,0,0.4)" }}
              >
                <Typography variant="caption" color="textPrimary" sx={{ m: 1 }}>
                  Overall inflow
                </Typography>
                <Divider />
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  sx={{ m: 1 }}
                >
                  <Price amount={wallet?.overallIn ? wallet?.overallIn : 0} />
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper
                sx={{ borderRadius: 1, mb: 1, bgcolor: "rgba(255,0,0,0.4)" }}
              >
                <Typography variant="caption" color="textPrimary" sx={{ m: 1 }}>
                  Overall outflow
                </Typography>
                <Divider />
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  sx={{ m: 1 }}
                >
                  <Price amount={wallet?.overallOut ? wallet?.overallOut : 0} />
                </Typography>
              </Paper>
            </Grid>
          </Grid> */}
          <Stack>
            <Paper sx={{ borderRadius: 1, mb: 1, p: 1 }}>
              <Stack direction="row" alignItems="center" columnGap={1}>
                <LazyLoadImage
                  src={BalanceImg}
                  alt="account balance"
                  style={{ objectFit: "cover", width: 40, height: 40 }}
                />
                <Box sx={{ width: "100%" }}>
                  <Typography
                    variant="caption"
                    color="textPrimary"
                    sx={{ m: 1 }}
                  >
                    Balance
                  </Typography>
                  <Divider />
                  <Typography
                    variant="subtitle1"
                    color="textPrimary"
                    sx={{ m: 1 }}
                  >
                    <Price amount={wallet?.balance ? wallet?.balance : 0} />
                  </Typography>
                </Box>
              </Stack>
            </Paper>
            <Paper sx={{ borderRadius: 1, mb: 1, p: 1 }}>
              <Stack direction="row" alignItems="center" columnGap={1}>
                <LazyLoadImage
                  src={CouponImg}
                  alt="credit balance"
                  style={{ objectFit: "cover", width: 40, height: 40 }}
                />
                <Box sx={{ width: "100%" }}>
                  <Typography
                    variant="caption"
                    color="textPrimary"
                    sx={{ m: 1 }}
                  >
                    Credit
                  </Typography>
                  <Divider />
                  <Typography
                    variant="subtitle1"
                    color="textPrimary"
                    sx={{ m: 1 }}
                  >
                    <Price amount={wallet?.credit ? wallet?.credit : 0} />
                  </Typography>
                </Box>
              </Stack>
            </Paper>
          </Stack>
          {wallet !== undefined && (
            <>
              <WalletBankAccountInfo wallet={wallet} />
            </>
          )}
          <WalletActions wallet={wallet} persona={persona} userId={userId} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="subtitle2" color="textPrimary">
            Transaction history
          </Typography>
          <WalletTransactionList persona={persona} userId={userId} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default WalletContainer;
