import Spacer from "@/components/common/Spacer";
import { APP_CURRENCY } from "@/constants/app";
import { ISessionState } from "@/db/session-slice";
import { showSnackbar } from "@/helpers/snackbar-helpers";
import { useSession } from "@/hooks/app-hooks";
import { walletServices } from "@/services/root";
import { pageTypes } from "@/types/pages-types";
import { IAccountWallet } from "@/types/session-types";

import { generateUUIDV4, reloadPage } from "@/utils/funcs";
import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { forwardRef, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";

const NumberFormatCustom = forwardRef(function NumberFormatCustom(
  props: any,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix={APP_CURRENCY}
    />
  );
});

const Wrapper = styled.div`
  padding: 20px;
`;

interface Props {
  persona: pageTypes;
  userId: string;
}

const WithdrawFundsForm = ({ persona, userId }: Props) => {
  const profile = useSession() as ISessionState;
  const [wallet, setWallet] = useState<IAccountWallet | undefined>(undefined);

  useEffect(() => {
    if (persona === "customer") {
      setWallet(profile.wallet);
    }
  }, [profile, persona]);

  return (
    <Wrapper>
      <Formik
        initialValues={{
          amount: 500,
          userId: userId,
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          if (values.amount < 500) {
            showSnackbar({
              openSnackbar: true,
              status: "error",
              msg: `A minimum of ${APP_CURRENCY}500 can be withdrawn`,
            });

            setSubmitting(false);
            resetForm();
          } else if (
            wallet &&
            wallet?.balance !== undefined &&
            values.amount > wallet?.balance
          ) {
            showSnackbar({
              openSnackbar: true,
              status: "error",
              msg: `You cannot withdraw more than your available account balance of ${APP_CURRENCY}${
                wallet?.balance !== undefined ? wallet?.balance : 0
              } `,
            });

            setSubmitting(false);
            resetForm();
          } else {
            if (persona === "customer") {
              const { errorMessage, status, successMessage } =
                await walletServices.instantPSInitiateTransfer({
                  amount: values.amount,
                  psrecieptCode: wallet?.bankAccount
                    ? wallet?.bankAccount.paystack.psrecieptCode
                    : "empty",
                  reason: "Withdraw",
                  userId: values.userId,
                });

              if (status === "success") {
                const {
                  errorMessage: recordWalletErrorMsg,
                  status: recordStatus,
                } = await walletServices.recordWalletTransaction({
                  amount: values.amount,
                  persona: "customer",
                  uid: generateUUIDV4(),
                  remark: `Withdraw of ${APP_CURRENCY}${values.amount}`,
                  space: "balance",
                  type: "withdraw",
                  userId: userId,
                });
                if (recordStatus === "success") {
                  showSnackbar({
                    openSnackbar: true,
                    status: "success",
                    msg: successMessage,
                  });

                  setSubmitting(false);
                  reloadPage();
                }
                if (recordStatus === "error") {
                  showSnackbar({
                    openSnackbar: true,
                    status: "error",
                    msg: recordWalletErrorMsg,
                  });

                  setSubmitting(false);
                }
              }
              if (status === "error") {
                showSnackbar({
                  openSnackbar: true,
                  status: "error",
                  msg: errorMessage,
                });

                setSubmitting(false);
              }
            }
           
          }
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Field
              name="amount"
              component={TextField}
              fullWidth
              type="text"
              label="Enter the amount you want to withdraw from wallet"
              variant="standard"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />

            <Spacer space={40} />
            <Stack alignItems={"center"}>
              <LoadingButton
                variant="contained"
                loading={isSubmitting}
                onClick={submitForm}
              >
                Withdraw
              </LoadingButton>
            </Stack>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

export default WithdrawFundsForm;
