import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import GppBadIcon from "@mui/icons-material/GppBad";
import { Link } from "react-router-dom";
import Spacer from "@/components/common/Spacer";

const AccessRestricted = () => {
  return (
    <Stack sx={{ height: "100vh" }} alignItems="center" justifyContent="center">
      <GppBadIcon sx={{ fontSize: 80, color: "red" }} />
      <Typography variant="h5" color="error" sx={{ fontWeight: "700" }}>
        Access restricted!
      </Typography>
      <Typography variant="body1" color="#000">
        You do not have permission to view this page
      </Typography>
      <Spacer space={60} />
      <Link to="/dashboard" replace>
        Go to dashboard
      </Link>
    </Stack>
  );
};

export default AccessRestricted;
