import { useSession } from "@/hooks/app-hooks";
import { ADMIN_MGT_ACCOUNT, DASHBOARD_BASE } from "@/routes/dashboard-routes";
import { IAccountDocument } from "@/types/session-types";
import { Box, Grid, Typography, Button } from "@mui/material";
import moment from "moment";

interface Props {
  account: IAccountDocument;
}
const UserListItem = ({ account }: Props) => {
  const profileId = useSession().uid;

  return (
    <Box boxShadow={2} sx={{ my: 1, p: 1 }}>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} md={2}>
          <Typography variant="caption" color="textPrimary">
            {moment(account?.addedOn?.toDate()).format("lll")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant="caption" color="textPrimary">
            {account.firstName ? account.firstName : "-"}{" "}
            {account.lastName ? account.lastName : "-"}
            {account.uid === profileId && "(You)"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant="caption" color="textPrimary">
            {account?.address?.city ? account?.address?.city : "-"}
            {", "}
            {account?.address?.state ? account?.address?.state : "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={1}>
          <Typography variant="caption" color="textPrimary">
            {account.phoneNumber}
          </Typography>
        </Grid>
        <Grid item xs={12} md={1}>
          <Typography variant="caption" color="textPrimary">
            {account.persona}
          </Typography>
        </Grid>

        <Grid item xs={12} md={1}>
          <Button
            variant="text"
            color="primary"
            href={`/${DASHBOARD_BASE}/${ADMIN_MGT_ACCOUNT}/${account.uid}`}
            target="_blank"
          >
            View
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserListItem;
