import { useCollection } from "@/hooks/app-hooks";
import { Paper, Stack } from "@mui/material";
import Spacer from "../common/Spacer";
import AddMeterBtn from "./AddMeterBtn";
import MeterList from "./MeterList";
import MeterLoadMore from "./MeterLoadMore";
import MeterSearch from "./MeterSearch";

const MeterContainer = () => {
  const page = useCollection().page;
  return (
    <>
      {page === "customer" && (
        <Stack alignItems="center">
          <AddMeterBtn />
          <Spacer space={40} />
        </Stack>
      )}

      <MeterSearch />
      <Spacer space={40} />
      <Paper sx={{ p: 1 }}>
        <MeterList />
      </Paper>
      <Spacer space={40} />
      <MeterLoadMore />
    </>
  );
};

export default MeterContainer;
