import { InputAdornment, Stack, Typography } from "@mui/material";
import { Form, Formik, Field } from "formik";
import { TextField } from "formik-mui";
import PointOfSaleTwoToneIcon from "@mui/icons-material/PointOfSaleTwoTone";
import BadgeIcon from "@mui/icons-material/Badge";
import Spacer from "@/components/common/Spacer";
import { useSession } from "@/hooks/app-hooks";
import { LoadingButton } from "@mui/lab";
import { object, string } from "yup";
import { generateUUIDV4, stringToArray } from "@/utils/funcs";
import { collectionServices, powerPayServices } from "@/services/root";
import { showSnackbar } from "@/helpers/snackbar-helpers";
import { ChangeEvent, useEffect, useState } from "react";
import { KadunaCustomerDoc } from "@/types/customer-types";
import LoadingCircle from "@/components/common/LoadingCircle";

const MeterFormValidation = object({
  meterNumber: string()
    .required("Meter number is required")
    .max(20, "Meter number should be within 20 characters"),
  meterName: string()
    .required("Meter name is required")
    .max(70, "Meter name should be within 70 characters"),
});

interface Props {
  setFieldValue?: any;
  setCustomerMeternumber?: any;
}

const MeterForm = ({ setFieldValue, setCustomerMeternumber }: Props) => {
  const profile = useSession();

  const [selectedMeterNumber, setSelectedMeterNumber] = useState("");
  const [kadcoCustomerInfo, setKadcoCustomerInfo] =
    useState<KadunaCustomerDoc | undefined>(undefined);
  const [loadingKadcoCustomerInfo, setLoadingKadcoCustomerInfo] =
    useState(false);

  useEffect(() => {
    (async () => {
      if (selectedMeterNumber !== "") {
        setLoadingKadcoCustomerInfo(true);
        let response = await powerPayServices.getCustomer(selectedMeterNumber);
        setLoadingKadcoCustomerInfo(false);

        if (response.status === "success") {
          setKadcoCustomerInfo(response.item);
        }
      }
    })();
  }, [selectedMeterNumber]);

  if (!profile.uid) return <></>;
  return (
    <div>
      <Formik
        key="meter-form"
        validationSchema={MeterFormValidation}
        initialValues={{
          meterId: generateUUIDV4(),
          meterNumber: "",
          meterName: "",
          userId: profile.uid,
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const { status, errorMessage } = await collectionServices.addDoc(
            "Meters",
            values.meterId,
            {
              ...values,
              meterType:
                kadcoCustomerInfo === undefined
                  ? ""
                  : kadcoCustomerInfo?.isPPM
                  ? "Prepaid"
                  : "Postpaid",
              query: stringToArray(
                `${values.meterNumber} ${values.meterName} `
              ),
            }
          );
          if (status === "success") {
            if (setFieldValue) {
              setFieldValue("meterId", values.meterId, false);
              setFieldValue("meterNumber", values.meterNumber, false);
              setFieldValue("meterName", values.meterName, false);
            }

            if (setCustomerMeternumber) {
              setCustomerMeternumber(values.meterNumber);
            }

            showSnackbar({
              openSnackbar: true,
              msg: "Meter added succesfully",
              status,
            });
            setSubmitting(false);
            resetForm();
            setKadcoCustomerInfo(undefined);
          }
          if (status === "error") {
            showSnackbar({
              openSnackbar: true,
              msg: errorMessage,
              status,
            });
            setSubmitting(false);
          }
        }}
      >
        {({ submitForm, isSubmitting, values, setFieldValue }) => (
          <Form id="meter-form">
            <Field
              component={TextField}
              type="text"
              variant="filled"
              fullWidth
              label="Enter Meter number"
              name="meterNumber"
              value={values.meterNumber}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PointOfSaleTwoToneIcon />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                maxLength: 40,
              }}
              onClick={(event: ChangeEvent<HTMLInputElement>) => {
                let val = event.target.value;
                setFieldValue("meterNumber", val, false);

                if (val.length > 6) {
                  setSelectedMeterNumber(val);
                }
              }}
              onPaste={(event: ClipboardEvent) => {
                let val = event.clipboardData?.getData("text");

                if (val && val.length > 6) {
                  setSelectedMeterNumber(val);
                }
              }}
            />
            <Stack alignItems="center">
              {loadingKadcoCustomerInfo && <LoadingCircle />}
            </Stack>
            {kadcoCustomerInfo !== undefined && (
              <>
                <Stack spacing={1} sx={{ py: 2 }}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="caption"
                      color="textPrimary"
                      sx={{
                        width: 100,
                        textAlign: "left",
                        lineHeight: 1,
                      }}
                    >
                      Customer name:
                    </Typography>
                    <Typography
                      variant="overline"
                      color="textPrimary"
                      sx={{
                        fontWeight: "bold",
                        width: 200,
                        textAlign: "right",
                        lineHeight: 1,
                      }}
                    >
                      {kadcoCustomerInfo.customerName}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="caption"
                      color="textPrimary"
                      sx={{
                        width: 100,
                        textAlign: "left",
                        lineHeight: 1,
                      }}
                    >
                      Customer address:
                    </Typography>
                    <Typography
                      variant="overline"
                      color="textPrimary"
                      sx={{
                        fontWeight: "bold",
                        width: 200,
                        textAlign: "right",
                        lineHeight: 1,
                      }}
                    >
                      {kadcoCustomerInfo.customerAddress}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="caption"
                      color="textPrimary"
                      sx={{
                        width: 100,
                        textAlign: "left",
                        lineHeight: 1,
                      }}
                    >
                      Meter type:
                    </Typography>
                    <Typography
                      variant="overline"
                      color="textPrimary"
                      sx={{
                        fontWeight: "bold",
                        width: 200,
                        textAlign: "right",
                        lineHeight: 1,
                      }}
                    >
                      {kadcoCustomerInfo.isPPM ? "Prepaid" : "Postpaid"}
                    </Typography>
                  </Stack>
                </Stack>

                <Spacer space={10} />

                <Field
                  component={TextField}
                  type="text"
                  variant="filled"
                  fullWidth
                  label="Give your meter a name e.g House Meter"
                  name="meterName"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <BadgeIcon />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    maxLength: 70,
                  }}
                />
                <Spacer space={10} />

                <Stack>
                  <LoadingButton
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    onClick={submitForm}
                    variant="contained"
                    sx={{ color: "#fff" }}
                  >
                    Add
                  </LoadingButton>
                </Stack>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MeterForm;
