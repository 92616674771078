export const CHART_TIMEFRAME_OPTIONS = [
  "Today",
  "This week",
  "This Month",
  "This Year",
  "Last 30 days",
  "Last 90 days",
];


export const ChartTimeFrameOptions = [
  "Today",
  "This week",
  "This Month",
  "This Year",
  "Last 30 days",
  "Last 90 days",
  //   "Custom Period",
  //   "All Time",
];
