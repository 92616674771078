import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { DashboardSidebar } from "../nav/DashboardSidebar";
import { DashboardNavbar } from "../nav/DashboardNavbar";
import { motion } from "framer-motion";
import Spacer from "../common/Spacer";

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  paddingTop: 64,
  [theme.breakpoints.up("lg")]: {
    paddingLeft: 280,
  },
}));

const DashboardLayout = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  return (
    <Box sx={{bgcolor : "background.default", minHeight:"100vh"}}>
      <DashboardLayoutRoot>
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ ease: "easeInOut" }}
          >
            <Outlet />
            <Spacer space={100} />
          </motion.div>
        </Box>
      </DashboardLayoutRoot>
      <DashboardNavbar onSidebarOpen={() => setSidebarOpen(true)} />
      <DashboardSidebar
        onClose={() => setSidebarOpen(false)}
        open={isSidebarOpen}
      />
    </Box>
  );
};

export default DashboardLayout;
