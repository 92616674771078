import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./components/global/AppRoutes";
import AppThemeProvider from "./components/global/AppThemeProvider";
import "react-lazy-load-image-component/src/effects/blur.css";
import AppDb from "./components/global/AppDb";

function App() {
  return (
    <BrowserRouter>
      <AppDb>
        <AppThemeProvider>
          <AppRoutes />
        </AppThemeProvider>
      </AppDb>
    </BrowserRouter>
  );
}

export default App;
