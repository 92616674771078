import Spacer from "@/components/common/Spacer";
import { APP_CURRENCY } from "@/constants/app";
import { ISessionState } from "@/db/session-slice";
import { showSnackbar } from "@/helpers/snackbar-helpers";
import { useSession } from "@/hooks/app-hooks";
import { walletServices } from "@/services/root";
import { reloadPage } from "@/utils/funcs";
import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/material";
import { Field, Form, Formik, FormikValues } from "formik";
import { TextField } from "formik-mui";
import { forwardRef, useRef, useState } from "react";
import { NumericFormat } from "react-number-format";

const NumberFormatCustom = forwardRef(function NumberFormatCustom(
  props: any,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix={APP_CURRENCY}
    />
  );
});

const Wrapper = styled.div`
  padding: 20px;
`;

interface Props {
  close?: any;
}

const AddFundsForm = ({ close }: Props) => {
  const [amountTopay, setAmountTopay] = useState(500);

  const profile = useSession() as ISessionState;

  const formikRef = useRef<FormikValues | null>(null);

  const onClose = () => {
    close();
  };
  const onSuccess = async () => {
    if (profile.uid) {
      const { errorMessage, status, successMessage } =
        await walletServices.topUpWallet({
          userId: profile.uid,
          amount: amountTopay,
        });

      if (status === "success") {
        close();
        showSnackbar({
          openSnackbar: true,
          status: "success",
          msg: successMessage,
        });

        if (formikRef.current) {
          formikRef.current?.setSubmitting(false);
        }

        reloadPage();
      }
      if (status === "error") {
        showSnackbar({
          openSnackbar: true,
          status: "error",
          msg: errorMessage,
        });

        if (formikRef.current) {
          formikRef.current?.setSubmitting(false);
        }
      }
    }
  };

  function SquadPay() {
    // @ts-ignore
    const squadInstance = new squad({
      onClose: () => {
        onClose();
      },
      onLoad: () => {},
      onSuccess: () => onSuccess(),
      key: `${process.env.REACT_APP_SQUADCO_LIVE_PUBLIC_KEY}`,
      //Change key (test_pk_sample-public-key-1) to the key on your Squad Dashboard
      email:
        profile.email !== "" ? profile.email : "haskecustomer@gmail.com",
      amount: amountTopay * 100,
      //Enter amount in Naira or Dollar (Base value Kobo/cent already multiplied by 100)
      currency_code: "NGN",
    });
    squadInstance.setup();
    squadInstance.open();
  }

  return (
    <Wrapper>
      <Formik
        initialValues={{
          amount: 500,
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (values.amount < 500) {
            showSnackbar({
              openSnackbar: true,
              status: "error",
              msg: `A minimum of ${APP_CURRENCY}500 is needed to top up account`,
            });

            setSubmitting(false);
            resetForm();
          } else {
            SquadPay();
            //@ts-ignore
            // initializePayment((Response: any) => {
            //   async function onSuccess(paystackPaymentResponse: any) {
            //     if (paystackPaymentResponse.status === "success") {
            //       if (profile.uid) {
            //         const { errorMessage, status, successMessage } =
            //           await walletServices.topUpWallet({
            //             userId: profile.uid,
            //             amount: amountTopay,
            //           });

            //         if (status === "success") {
            //           close();
            //           showSnackbar({
            //             openSnackbar: true,
            //             status: "success",
            //             msg: successMessage,
            //           });

            //           setSubmitting(false);
            //           reloadPage();
            //         }
            //         if (status === "error") {
            //           showSnackbar({
            //             openSnackbar: true,
            //             status: "error",
            //             msg: errorMessage,
            //           });

            //           setSubmitting(false);
            //         }
            //       }
            //     } else {
            //       if (paystackPaymentResponse.status === "error") {
            //         showSnackbar({
            //           openSnackbar: true,
            //           status: "error",
            //           msg: paystackPaymentResponse.message,
            //         });
            //       }
            //     }
            //   }
            //   onSuccess(Response);
            // }, onClose);
            setSubmitting(false);
            resetForm();
          }
        }}
      >
        {({ submitForm, isSubmitting, values, setFieldValue }) => (
          <Form>
            <Field
              name="amount"
              component={TextField}
              fullWidth
              type="text"
              label="Enter the amount you want to fund the wallet"
              variant="standard"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              value={values.amount}
              onChange={(event: any) => {
                setFieldValue("amount", parseInt(event?.target?.value), false);
                setAmountTopay(parseInt(event?.target?.value));
              }}
            />

            <Spacer space={40} />
            <Stack alignItems={"center"}>
              <LoadingButton
                variant="contained"
                loading={isSubmitting}
                onClick={submitForm}
              >
                Top up
              </LoadingButton>
            </Stack>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

export default AddFundsForm;
