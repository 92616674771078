import { Grid, Paper } from "@mui/material";
import AccountProfilePhoto from "./AccountProfilePhoto";
import AccountUpdateProfile from "./AccountUpdateProfile";

const AccountContainer = () => {
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <Paper
            sx={{
              width: "100%",
              borderRadius: 2,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              <AccountProfilePhoto />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper sx={{ width: "100%", borderRadius: 2 }}>
            <AccountUpdateProfile />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default AccountContainer;
