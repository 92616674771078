import Spacer from "@/components/common/Spacer";
import { Box, Container, Grid, Typography } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import WalletIcon from "@mui/icons-material/Wallet";
import ElectricMeterIcon from "@mui/icons-material/ElectricMeter";
import TagIcon from "@mui/icons-material/Tag";
// import BuyTokenFormV2 from "@/components/forms/token/BuyTokenFormV2";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import QuickBuyTokenForm from "@/components/forms/token/QuickBuyTokenForm";

const Home = () => {
  return (
    <Box>
      <Box
        sx={{
          backgroundImage: `url(${require("@/assets/4858838.jpg")})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          width: "100vw",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid container justifyContent="center" spacing={4}>
          <Grid item xs={12} md={4} sx={{ pt: { sm: 20 } }}>
            <QuickBuyTokenForm />
            <Typography textAlign="center">
              Need Quick Help: 012279295
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Container>
        <Box
          sx={{
            py: 3,
            borderTop: "2px solid #FD3205",
            borderBottom: "2px solid #FD3205",
          }}
        >
          <Grid container>
            <Grid item xs={12} md={4} sx={{ mb: { xs: 4, md: 0 } }}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={2}>
                  <CheckCircleIcon color="primary" sx={{ fontSize: 50 }} />
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    sx={{ fontSize: "1.2rem" }}
                  >
                    {" "}
                    Input your correct meter number and tap the continue button.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} sx={{ mb: { xs: 4, md: 0 } }}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={2}>
                  <CheckCircleIcon color="primary" sx={{ fontSize: 50 }} />
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    sx={{ fontSize: "1.2rem" }}
                  >
                    {" "}
                    Enter the amount of token you wish to purchase.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={2}>
                  <CheckCircleIcon color="primary" sx={{ fontSize: 50 }} />
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    sx={{ fontSize: "1.2rem" }}
                  >
                    {" "}
                    Upon checkout select your preferred payment option.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
      {/* <Spacer space={100} /> */}
      {/* <Container> */}
      {/* <Typography
        variant="h5"
        color="textPrimary"
        textAlign="center"
        gutterBottom
        sx={{ fontWeight: "bold" }}
      >
        Generate token instantly in three easy steps
      </Typography> */}
      {/* <Paper sx={{ borderRadius: 1, py: 4, px: 2, mx: 4 }} elevation={4}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={4}>
            <Grid container sx={{ borderRight: "1px solid gray", py: 2 }}>
              <Grid item xs={2}>
                <Box
                  sx={{
                    width: 30,
                    height: 30,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: (theme) => theme.palette.primary.main,
                    color: "#fff",
                    borderRadius: 1,
                    fontSize: 14,
                  }}
                >
                  1
                </Box>
              </Grid>
              <Grid item xs={10}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Enter your meter number
                </Typography>
                <Typography variant="body2">
                  Input your correct meter number and tap the continue button.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={4}>
            <Grid container sx={{ borderRight: "1px solid gray", py: 2 }}>
              <Grid item xs={2}>
                <Box
                  sx={{
                    width: 30,
                    height: 30,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: (theme) => theme.palette.primary.main,
                    color: "#fff",
                    borderRadius: 1,
                    fontSize: 14,
                  }}
                >
                  2
                </Box>
              </Grid>
              <Grid item xs={10}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Enter Amount
                </Typography>
                <Typography variant="body2">
                  Enter the amount of token you wish to purchase and tap the
                  continue button.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={4}>
            <Grid container sx={{ py: 2 }} justifyContent="center">
              <Grid item xs={2}>
                <Box
                  sx={{
                    width: 30,
                    height: 30,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: (theme) => theme.palette.primary.main,
                    color: "#fff",
                    borderRadius: 1,
                    fontSize: 14,
                  }}
                >
                  3
                </Box>
              </Grid>
              <Grid item xs={10}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Make Payment
                </Typography>
                <Typography variant="body2">
                  Upon checkout select your preferred payment option.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper> */}
      {/* </Container> */}
      <Spacer space={100} />

      <Spacer space={100} />
      <Container>
        <Typography
          variant="h3"
          color="textPrimary"
          sx={{ textAlign: "center", fontWeight: "bold" }}
        >
          Get electricity token{" "}
          <Typography
            variant="h3"
            color="primary"
            component="b"
            sx={{ fontWeight: "bold" }}
          >
            when and where
          </Typography>{" "}
          you need it.
        </Typography>
      </Container>
      <Spacer space={100} />
      <Container>
        {/* <Typography
          variant="h4"
          color="textPrimary"
          textAlign="center"
          sx={{ fontWeight: "bold", mb: 2 }}
        >
          Delight customers with phenomenal <br /> features
        </Typography> */}
        <Grid container spacing={1} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={5}>
            <LazyLoadImage
              src={require("@/assets/swswsw.jpg")}
              alt={"haske"}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: 500,
              }}
            >
              <Grid container justifyContent="center">
                <Grid item xs={2}>
                  <Box
                    sx={{
                      width: 50,
                      height: 50,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: (theme) => theme.palette.primary.main,
                      color: "#fff",
                      borderRadius: 1,
                      fontSize: 30,
                    }}
                  >
                    <WalletIcon sx={{ fontSize: 30, color: "#fff" }} />
                  </Box>
                </Grid>
                <Grid item xs={7}>
                  <Typography
                    variant="subtitle2"
                    color="textPrimary"
                    sx={{ fontSize: 20, fontWeight: "bold" }}
                  >
                    Wallet System
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    Accelerate payment via our internal wallet system.
                  </Typography>
                </Grid>
              </Grid>
              <Spacer space={40} />
              <Grid container justifyContent="center">
                <Grid item xs={2}>
                  <Box
                    sx={{
                      width: 50,
                      height: 50,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: (theme) => theme.palette.primary.main,
                      color: "#fff",
                      borderRadius: 1,
                      fontSize: 30,
                    }}
                  >
                    <ElectricMeterIcon sx={{ fontSize: 30, color: "#fff" }} />
                  </Box>
                </Grid>
                <Grid item xs={7}>
                  <Typography
                    variant="subtitle2"
                    color="textPrimary"
                    sx={{ fontSize: 20, fontWeight: "bold" }}
                  >
                    Multiple meter
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    Add more than one meter to your profile and recharge on the
                    go.
                  </Typography>
                </Grid>
              </Grid>
              <Spacer space={40} />
              <Grid container justifyContent="center">
                <Grid item xs={2}>
                  <Box
                    sx={{
                      width: 50,
                      height: 50,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: (theme) => theme.palette.primary.main,
                      color: "#fff",
                      borderRadius: 1,
                      fontSize: 30,
                    }}
                  >
                    <TagIcon sx={{ fontSize: 30, color: "#fff" }} />
                  </Box>
                </Grid>
                <Grid item xs={7}>
                  <Typography
                    variant="subtitle2"
                    color="textPrimary"
                    sx={{ fontSize: 20, fontWeight: "bold" }}
                  >
                    USSD Top-up
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    You can purchase energy token without internet.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Home;
