import { ICollectionState } from "@/db/collection-slice";
import { ISessionState } from "@/db/session-slice";
import {
  fetchMoreAllDocs,
  fetchMoreDocs,
  setShowLoadMoreBtn,
} from "@/helpers/collection-helpers";
import { useCollection, useSession } from "@/hooks/app-hooks";
import { IAccountDocument } from "@/types/session-types";
import { useState } from "react";
import LoadMoreBtn from "../common/LoadMoreBtn";

const TransactionsLoadMore = () => {
  const profile = useSession() as ISessionState;
  const collectionState = useCollection() as ICollectionState<IAccountDocument>;
  const [isLoading, setIsLoading] = useState(false);

  if (collectionState.showLoadMoreBtn === false) return <></>;
  return (
    <LoadMoreBtn
      isEmpty={collectionState.isEmpty}
      isLoading={isLoading}
      action={async () => {
        setIsLoading(true);

        if (profile.uid) {
          if (collectionState.page === "customer") {
            if (collectionState.tokenTransactionFilter === "all") {
              await fetchMoreDocs("TokenTransactions", [
                {
                  uField: "userId",
                  uid: profile.uid,
                },
              ]);
            }
            if (collectionState.tokenTransactionFilter === "success") {
              await fetchMoreDocs("TokenTransactions", [
                {
                  uField: "status",
                  uid: "success",
                },
                {
                  uField: "userId",
                  uid: profile.uid,
                },
              ]);
            }
            if (collectionState.tokenTransactionFilter === "pending") {
              await fetchMoreDocs("TokenTransactions", [
                {
                  uField: "status",
                  uid: "pending",
                },
                {
                  uField: "userId",
                  uid: profile.uid,
                },
              ]);
            }
            if (collectionState.tokenTransactionFilter === "fail") {
              await fetchMoreDocs("TokenTransactions", [
                {
                  uField: "status",
                  uid: "fail",
                },
                {
                  uField: "userId",
                  uid: profile.uid,
                },
              ]);
            }

            setIsLoading(false);
            setShowLoadMoreBtn(true);
          }
        }

        if (collectionState.page === "mgt") {
          if (collectionState.tokenTransactionFilter === "all") {
            await fetchMoreAllDocs("TokenTransactions");
          }

          if (collectionState.tokenTransactionFilter === "success") {
            await fetchMoreDocs("TokenTransactions", [
              {
                uField: "status",
                uid: "success",
              },
            ]);
          }
          if (collectionState.tokenTransactionFilter === "pending") {
            await fetchMoreDocs("TokenTransactions", [
              {
                uField: "status",
                uid: "pending",
              },
            ]);
          }
          if (collectionState.tokenTransactionFilter === "fail") {
            await fetchMoreDocs("TokenTransactions", [
              {
                uField: "status",
                uid: "fail",
              },
            ]);
          }
          setIsLoading(false);
          setShowLoadMoreBtn(true);
        }
      }}
      endText="That is all"
    />
  );
};

export default TransactionsLoadMore;
