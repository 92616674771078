const DASHBOARD_BASE = "dashboard";
const CLIENT_DASHBOARD = "overview";
const CLIENT_TRANSACTIONS = "my-transactions";
const CLIENT_METERS = "my-meters";
const CLIENT_ADD_METER = "add-meter";
const CLIENT_ACCOUNT = "my-account";
const CLIENT_WALLET = "my-wallet";
const CLIENT_UPDATE_ACCOUNT = "update-profile";
const CLIENT_BUY_TOKEN = "buy-token";

const ADMIN_DASHBOARD = "mgt-overview";
const ADMIN_MGT_ACCOUNT = "manage-accounts";
const ADMIN_MGT_REPORTS = "manage-reports";
const ADMIN_MGT_TRANSACTIONS = "manage-transactions";
const ADMIN_MGT_METER = "manage-meters";

export {
  DASHBOARD_BASE,
  CLIENT_DASHBOARD,
  CLIENT_TRANSACTIONS,
  CLIENT_METERS,
  CLIENT_ACCOUNT,
  CLIENT_WALLET,
  CLIENT_UPDATE_ACCOUNT,
  CLIENT_BUY_TOKEN,
  ADMIN_DASHBOARD,
  ADMIN_MGT_ACCOUNT,
  ADMIN_MGT_REPORTS,
  ADMIN_MGT_TRANSACTIONS,
  CLIENT_ADD_METER,
  ADMIN_MGT_METER,
};
