import { deleteObject, ref } from "firebase/storage";
import { storage } from "@/configs/firebase";
import { v4 as uuidv4 } from "uuid";
// @ts-ignore
import referralCodeGenerator from "referral-code-generator";
import { Currency, Dinero, toFormat } from "dinero.js";
import moment from "moment";
import { groupBy, map, mapValues, omit } from "lodash";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

export const deleteImageFromStorage = (folder: string, image: string) => {
  const imageRef = ref(storage, `${folder}/${image}`);
  return deleteObject(imageRef);
};

export const parseOptionWithMatch = (option: string, inputValue: string) => {
  if (option) {
    const _match = match(option, inputValue);
    const parts = parse(option, _match);
    return parts;
  }
  return [];
};


export const generateTransactionId = () => {
  const ortxid = referralCodeGenerator.custom("uppercase", 3, 6, "PPTR");
  return ortxid;
};
export const generateCustomTransactionId = (prefix: string) => {
  const ortxid = referralCodeGenerator.custom("uppercase", 6, 10, prefix);
  return ortxid;
};
export const generateCouponCode = () => {
  const ortxid = referralCodeGenerator.custom("uppercase", 3, 6, "GLOF");
  return ortxid;
};

export const generateUUIDV4 = () => {
  return uuidv4();
};

export const customMathFloor = (value: number) => {
  return Math.floor(value * 100) / 100;
};

export const setToLocalStorage = (key: string, value: any) => {
  if (key && typeof key === "string") {
    typeof value === "object"
      ? localStorage.setItem(key, JSON.stringify(value))
      : localStorage.setItem(key, value);
  }
};
export const getToLocalStorage = (key: string) => {
  if (key && typeof key === "string") {
    try {
      const val: any = localStorage.getItem(`${key}`);

      return typeof val === "object" ? JSON.parse(val) : val;
    } catch {
      return localStorage.getItem(key);
    }
  } else throw new Error("Invalid key");
};

export const numberWithCommas = (num: number) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const compactNumber = (value: number) => {
  const suffixes = ["", "k", "m", "b", "t"];
  const suffixNum = Math.floor(("" + value).length / 3);

  let shortvalue = parseFloat(
    (suffixNum !== 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(2)
  );

  if (shortvalue % 1 !== 0) {
    shortvalue = parseFloat(shortvalue.toFixed(1));
  }
  return shortvalue + suffixes[suffixNum];
};

const THOUSAND = 1000;

export const numberFormatter = (value: number) => {
  return value < THOUSAND ? numberWithCommas(value) : compactNumber(value);
};

export const getNumberSuffix = (value: number) => {
  const suffixes = ["", "k", "m", "b", "t"];
  const suffixNum = Math.floor(("" + value).length / 3);

  return suffixes[suffixNum];
};
export const getNumberWholeBySuffix = (value: number) => {
  const suffixes = ["", "k", "m", "b", "t"];
  const suffixNum = Math.floor(("" + value).length / 3);
  return suffixes[suffixNum];
};

export const randomizeArray = (
  array: unknown[],
  randomArrLength: number = array.length
): unknown[] => {
  let length = randomArrLength || array.length;
  let range = array.length;
  let randomizedArray: unknown[] = [];
  let indexArray: number[] = [];
  while (indexArray.length < length) {
    let randomIndex = Math.floor(Math.random() * range);
    if (!indexArray.includes(randomIndex)) indexArray.push(randomIndex);
  }
  indexArray.forEach((el, id) => {
    randomizedArray[id] = array[el];
  });
  return randomizedArray;
};

export const calculateAmountFromDiscount = ({
  amount,
  discount,
  mode = "discount-from-amount",
}: {
  amount: number;
  discount: number;
  mode: "discount-from-amount" | "discount-amount-only";
}) => {
  if (mode === "discount-from-amount") {
    return amount - amount * (discount / 100);
  }
  if (mode === "discount-amount-only") {
    return amount * (discount / 100);
  }
  return amount;
};

export const stringToArray = (str: string) => {
  const _noSpace = str.toLowerCase().replace(/\s/g, "");
  const _stringToArray = Array.from(_noSpace);
  const _unique = Array.from(new Set(_stringToArray.map((item) => item)));
  // const _unique = uniq(_stringToArray)
  return _unique;
};

export const stringToArrayWithDuplicate = (str: string) => {
  const _noSpace = str.toLowerCase().replace(/\s/g, "");
  const _stringToArray = Array.from(_noSpace);
  return _stringToArray;
};

export const convertToPermalink = (sentence: string) => {
  const result_transform_to_lowercase = sentence
    .toLowerCase()
    .split(" ")
    .join("-");
  return result_transform_to_lowercase;
};
export const convertPermalinkToSentence = (sentence: string) => {
  const result_transform_to_lowercase = sentence.split("-").join(" ");
  return result_transform_to_lowercase;
};

export const convertToPlainName = (sentence: string) => {
  const result_transform_to_lowercase = sentence
    .toLowerCase()
    .split(" ")
    .join(" ")
    .replace(/\W/g, "");

  return result_transform_to_lowercase;
};

export const reloadPage = () => {
  setTimeout(() => {
    window.location.reload();
  }, 500);
};

export function intlFormat(
  dineroObject: Dinero<number>,
  locale: string,
  options = {}
) {
  function transformer({
    amount,
    currency,
  }: {
    amount: number;
    currency: Currency<number>;
  }) {
    return amount.toLocaleString(locale, {
      ...options,
      style: "currency",
      currency: currency.code,
    });
  }

  return toFormat(dineroObject, transformer);
}

export const getGroupedListByDay = (list: any) => {
  const now = moment();
  const convertedTimestamp = map(list, (item: any) => {
    return {
      ...item,
      convertedTimestsamp: moment(item.addedOn.toDate()).format("YYYY-MM-DD"),
      formated: moment(now).isSame(item.addedOn.toDate(), "day"),
    };
  });
  const grouped = mapValues(
    groupBy(convertedTimestamp, "convertedTimestsamp"),
    (clist) => clist.map((chats) => omit(chats, "convertedTimestsamp"))
  );

  const formattedData = Object.keys(grouped).map((key) => {
    return {
      key,
      data: grouped[key],
    };
  });

  return formattedData;
};
