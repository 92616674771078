import { CLIENT_ADD_METER, DASHBOARD_BASE } from "@/routes/dashboard-routes";
import { useNavigate } from "react-router-dom";
import AddEntityButton from "../common/AddEntityButton";
import PointOfSaleTwoToneIcon from "@mui/icons-material/PointOfSaleTwoTone";

const AddMeterBtn = () => {
  const navigate = useNavigate();
  return (
    <AddEntityButton
      title="Add a meter"
      action={() => {
        navigate(`/${DASHBOARD_BASE}/${CLIENT_ADD_METER}`);
      }}
      icon={<PointOfSaleTwoToneIcon sx={{ color: "#fff" }} />}
    />
  );
};

export default AddMeterBtn;
