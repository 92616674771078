import EmptyList from "@/components/common/EmptyList";
import LoadingScreen from "@/components/common/LoadingScreen";
import DashboardSectionLayout from "@/components/dashboard/DashboardSectionLayout";
import DashboardHeader from "@/components/headers/DashboardHeader";
import TransactionContainer from "@/components/transaction/TransactionContainer";
import {
  getAllDocs,
  setPageState,
  setShowLoadMoreBtn,
} from "@/helpers/collection-helpers";
import { useCollection } from "@/hooks/app-hooks";
import { useEffect } from "react";

const MgtTransactions = () => {
  const collectionState = useCollection();

  useEffect(() => {
    (async () => {
      await getAllDocs("TokenTransactions");

      setPageState("mgt");
      setShowLoadMoreBtn(true);
    })();
  }, []);

  if (collectionState.isLoading) return <LoadingScreen />;
  if (collectionState.status === "error")
    return <EmptyList title={"An error occured"} caption="." />;

  return (
    <>
      {/* {collectionState.status === "success" && collectionState.all.count > 0 && ( */}
        <>
          <DashboardHeader title="Manage Transactions" />
          <DashboardSectionLayout>
            <TransactionContainer />
          </DashboardSectionLayout>
        </>
      {/* )} */}
      {/* {collectionState.status === "success" &&
        collectionState.all.count === 0 && (
          <EmptyList title={"Transaction list is empty"} caption="." />
        )} */}
    </>
  );
};

export default MgtTransactions;
