import ClientDashboardContainer from "@/components/dashboard/ClientDashboardContainer";
import DashboardSectionLayout from "@/components/dashboard/DashboardSectionLayout";
import DashboardHeader from "@/components/headers/DashboardHeader";

const ClientDashboard = () => {
  return (
    <>
      <DashboardHeader title="Overview" />
      <DashboardSectionLayout>
        <ClientDashboardContainer />
      </DashboardSectionLayout>
    </>
  );
};

export default ClientDashboard;
