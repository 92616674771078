import PowerPayRepository from "@/repositories/power-pay-repository";
import { KadunaCustomerDoc } from "@/types/customer-types";
import { DocQueryResponse } from "@/types/promise-types";
import {
  KadcoThirdPartyPayment,
  KadcoThirdPartyPaymentResponse,
  RequestTokenAPIModel,
  TokenResponse,
} from "@/types/transaction-types";

class PowerPayServices {
  protected repository: PowerPayRepository;
  constructor() {
    this.repository = new PowerPayRepository();
  }

  async generateToken(
    requestTokenAPIModel: RequestTokenAPIModel
  ): Promise<DocQueryResponse<TokenResponse>> {
    try {
      const response = await this.repository.generateToken(
        requestTokenAPIModel
      );
      return {
        status: "success",
        item: response.data.data,
        errorMessage: "",
      };
    } catch (error) {
      return {
        status: "error",
        errorMessage: JSON.stringify(error),
      };
    }
  }
  async thirdPartyPayment(
    kadcoThirdPartyPaymentDto: KadcoThirdPartyPayment
  ): Promise<DocQueryResponse<KadcoThirdPartyPaymentResponse>> {
    try {
      const response = await this.repository.thirdPartyPayment(
        kadcoThirdPartyPaymentDto
      );
      return {
        status: "success",
        item: response.data.data,
        errorMessage: "",
      };
    } catch (error) {
      return {
        status: "error",
        errorMessage: JSON.stringify(error),
      };
    }
  }
  async getCustomer(
    meterNumber: string
  ): Promise<DocQueryResponse<KadunaCustomerDoc>> {
    try {
      const response = await this.repository.getCustomer(meterNumber);
      return {
        status: "success",
        item: response.data.data,
        errorMessage: "",
      };
    } catch (error) {
      return {
        status: "error",
        errorMessage: JSON.stringify(error),
      };
    }
  }

  async hashPassword(
    plaintextPassword: string
  ): Promise<DocQueryResponse<string>> {
    try {
      const response = await this.repository.hashPassword(plaintextPassword);
      return {
        status: "success",
        item: response.data.hash,
        errorMessage: "",
      };
    } catch (error) {
      return {
        status: "error",
        errorMessage: JSON.stringify(error),
      };
    }
  }

  async comparePassword(
    plaintextPassword: string,
    hash: string
  ): Promise<DocQueryResponse<boolean>> {
    try {
      const response = await this.repository.comparePassword(
        plaintextPassword,
        hash
      );
      return {
        status: "success",
        item: response.data.result,
        errorMessage: "",
      };
    } catch (error) {
      return {
        status: "error",
        errorMessage: JSON.stringify(error),
      };
    }
  }

  async createCustomToken(uid: string): Promise<DocQueryResponse<string>> {
    try {
      const response = await this.repository.createCustomToken(uid);
      return {
        status: "success",
        item: response.data.result,
        errorMessage: "",
      };
    } catch (error) {
      return {
        status: "error",
        errorMessage: JSON.stringify(error),
      };
    }
  }
}

export default PowerPayServices;
