import { collectionActions } from "@/db/collection-slice";
import { getAllDocs, getDocs } from "@/helpers/collection-helpers";
import { useCollection, useSession } from "@/hooks/app-hooks";
import { useAppDispatch } from "@/hooks/db-hooks";
import { LoadingButton } from "@mui/lab";
import { Box, Stack, Typography } from "@mui/material";
import { useState } from "react";

const TransactionFilter = () => {
  const [isLoadingSuccess, setIsLoadingSuccess] = useState(false);
  const [isLoadingPending, setIsLoadingPending] = useState(false);
  const [isLoadingFail, setIsLoadingFail] = useState(false);
  const [isLoadingAll, setIsLoadingAll] = useState(false);

  const profile = useSession();
  const page = useCollection().page;

  const dispatch = useAppDispatch();

  return (
    <Stack alignItems="center">
      <Box>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="caption" color="textPrimary">
            Filter
          </Typography>
          <LoadingButton
            variant="contained"
            sx={{ borderRadius: 20, bgcolor: "gray", color: "#fff" }}
            size={"small"}
            onClick={async () => {
              setIsLoadingAll(true);
              dispatch(collectionActions.setTokenTransactionFilter("all"));
              if (page === "customer" && profile.uid) {
                await getDocs(
                  "TokenTransactions",
                  [
                    {
                      uField: "userId",
                      uid: profile.uid,
                    },
                  ],
                  100,
                  true
                );
              }

              if (page === "mgt") {
                await getAllDocs("TokenTransactions", true);
              }

              setIsLoadingAll(false);
            }}
            loading={isLoadingAll}
          >
            All
          </LoadingButton>
          <LoadingButton
            variant="contained"
            sx={{ borderRadius: 20, bgcolor: "green", color: "#fff" }}
            size={"small"}
            onClick={async () => {
              setIsLoadingSuccess(true);
              dispatch(collectionActions.setTokenTransactionFilter("success"));
              if (page === "customer" && profile.uid) {
                await getDocs(
                  "TokenTransactions",
                  [
                    {
                      uField: "status",
                      uid: "success",
                    },
                    {
                      uField: "userId",
                      uid: profile.uid,
                    },
                  ],
                  100,
                  true
                );
              }

              if (page === "mgt") {
                await getDocs(
                  "TokenTransactions",
                  [
                    {
                      uField: "status",
                      uid: "success",
                    },
                  ],
                  100,
                  true
                );
              }

              setIsLoadingSuccess(false);
            }}
            loading={isLoadingSuccess}
          >
            Success
          </LoadingButton>
          <LoadingButton
            variant="contained"
            sx={{ borderRadius: 20, bgcolor: "orange", color: "#fff" }}
            size={"small"}
            onClick={async () => {
              setIsLoadingPending(true);
              dispatch(collectionActions.setTokenTransactionFilter("pending"));
              if (page === "customer" && profile.uid) {
                await getDocs(
                  "TokenTransactions",
                  [
                    {
                      uField: "status",
                      uid: "pending",
                    },
                    {
                      uField: "userId",
                      uid: profile.uid,
                    },
                  ],
                  100,
                  true
                );
              }

              if (page === "mgt") {
                await getDocs(
                  "TokenTransactions",
                  [
                    {
                      uField: "status",
                      uid: "pending",
                    },
                  ],
                  100,
                  true
                );
              }

              setIsLoadingPending(false);
            }}
            loading={isLoadingPending}
          >
            Pending
          </LoadingButton>
          <LoadingButton
            variant="contained"
            sx={{ borderRadius: 20, bgcolor: "red", color: "#fff" }}
            size={"small"}
            onClick={async () => {
              setIsLoadingFail(true);
              dispatch(collectionActions.setTokenTransactionFilter("fail"));
              if (page === "customer" && profile.uid) {
                await getDocs(
                  "TokenTransactions",
                  [
                    {
                      uField: "status",
                      uid: "fail",
                    },
                    {
                      uField: "userId",
                      uid: profile.uid,
                    },
                  ],
                  100,
                  true
                );
              }

              if (page === "mgt") {
                await getDocs(
                  "TokenTransactions",
                  [
                    {
                      uField: "status",
                      uid: "fail",
                    },
                  ],
                  100,
                  true
                );
              }

              setIsLoadingFail(false);
            }}
            loading={isLoadingFail}
          >
            fail
          </LoadingButton>
        </Stack>
      </Box>
    </Stack>
  );
};

export default TransactionFilter;
