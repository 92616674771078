import { ICollectionState } from "@/db/collection-slice";
import { getDocs } from "@/helpers/collection-helpers";
import { useCollection } from "@/hooks/app-hooks";
import { pageTypes } from "@/types/pages-types";
import { IWalletTransactionDocument } from "@/types/wallet-types";
import { generateUUIDV4 } from "@/utils/funcs";
import { useEffect } from "react";
import EmptyList from "../common/EmptyList";
import WalletLoadMoreBtn from "./WalletLoadMoreBtn";
import WalletTransactionListItem from "./WalletTransactionListItem";

interface Props {
  userId: string;
  persona: pageTypes;
}
const WalletTransactionList = ({ userId, persona }: Props) => {
  const collectionState =
    useCollection() as ICollectionState<IWalletTransactionDocument>;

  useEffect(() => {
    (async () => {
      if (persona && userId) {
        await getDocs("WalletTransactions", [
          {
            uField: "userId",
            uid: userId,
            operator: "==",
          },
          {
            uField: "persona",
            uid: persona,
            operator: "==",
          },
        ]);
      }
    })();
  }, [persona, userId]);

  if (collectionState.isLoading) return <p>Loading...</p>;
  if (collectionState.status === "error")
    return <EmptyList title={"An error occured"} caption="." />;

  return (
    <div>
      {collectionState.status === "success" &&
        collectionState.all.count > 0 &&
        collectionState.all.list.map((transaction) => (
          <WalletTransactionListItem
            transaction={transaction}
            key={generateUUIDV4()}
          />
        ))}
      {collectionState.status === "success" &&
        collectionState.all.count > 0 && (
          <WalletLoadMoreBtn persona={persona} userId={userId} />
        )}

      {collectionState.status === "success" &&
        collectionState.all.count === 0 && (
          <EmptyList title="No transactions yet" caption="." />
        )}
    </div>
  );
};

export default WalletTransactionList;
