import { Container, Divider, Grid, Typography } from "@mui/material";

import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ElectricMeterIcon from "@mui/icons-material/ElectricMeter";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ReceiptLongTwoToneIcon from "@mui/icons-material/ReceiptLongTwoTone";

import MgtStatCount from "./MgtStatCount";
import Spacer from "@/components/common/Spacer";
import { useEffect, useState } from "react";
import { collectionServices } from "@/services/root";
import { showSnackbar } from "@/helpers/snackbar-helpers";
import MgtTokenTransactionStats from "./MgtTokenTransactionStats";
import MgtAccountStats from "./MgtAccountStats";
import MgtMeterStats from "./MgtMeterStats";

const MgtDasboardComponent = () => {
  const [allAccountsCount, setAllAccountssCount] = useState(0);
  const [isloadingAllAccountsCount, setIsloadingAllAccountsCount] =
    useState(false);

  const [customersCount, setCustomersCount] = useState(0);
  const [isloadingCustomerCount, setIsloadingCustomerCount] = useState(false);

  const [mgtCount, setMgtCount] = useState(0);
  const [isloadingMgtCount, setIsloadingMgtCount] = useState(false);

  const [allTransactionCount, setAllTransactionCount] = useState(0);
  const [isloadingAllTransactionCount, setIsloadingAllTransactionCount] =
    useState(false);

  const [successTransactionCount, setSuccessTransactionCount] = useState(0);
  const [
    isloadingSuccessTransactionCount,
    setIsloadingSuccessTransactionCount,
  ] = useState(false);

  const [failedTransactionCount, setFailedTransactionCount] = useState(0);
  const [isloadingFailedTransactionCount, setIsloadingFailedTransactionCount] =
    useState(false);

  const [pendingTransactionCount, setPendingTransactionCount] = useState(0);
  const [
    isloadingPendingTransactionCount,
    setIsloadingPendingTransactionCount,
  ] = useState(false);

  const [meterTransactionCount, setMeterTransactionCount] = useState(0);
  const [isloadingMeterTransactionCount, setIsloadingMeterTransactionCount] =
    useState(false);

  useEffect(() => {
    (async () => {
      setIsloadingCustomerCount(true);
      const { status, count, errorMessage } =
        await collectionServices.getDocCount("Users", [
          {
            uField: "persona",
            uid: "customer",
          },
        ]);

      if (status === "success" && count) {
        setCustomersCount(count);
        setIsloadingCustomerCount(false);
      }
      if (status === "success") {
        setIsloadingCustomerCount(false);
      }

      if (status === "error" && errorMessage) {
        showSnackbar({
          status,
          openSnackbar: true,
          msg: errorMessage,
        });
        setIsloadingCustomerCount(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setIsloadingMgtCount(true);
      const { status, count, errorMessage } =
        await collectionServices.getDocCount("Users", [
          {
            uField: "persona",
            uid: "mgt",
          },
        ]);

      if (status === "success" && count) {
        setMgtCount(count);
        setIsloadingMgtCount(false);
      }

      if (status === "success") {
        setIsloadingMgtCount(false);
      }
      if (status === "error" && errorMessage) {
        showSnackbar({
          status,
          openSnackbar: true,
          msg: errorMessage,
        });
        setIsloadingMgtCount(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setIsloadingSuccessTransactionCount(true);
      const { status, count, errorMessage } =
        await collectionServices.getDocCount("TokenTransactions", [
          {
            uField: "status",
            uid: "success",
          },
        ]);

      if (status === "success" && count) {
        setSuccessTransactionCount(count);
        setIsloadingSuccessTransactionCount(false);
      }

      if (status === "success") {
        setIsloadingSuccessTransactionCount(false);
      }
     
      if (status === "error" && errorMessage) {
        showSnackbar({
          status,
          openSnackbar: true,
          msg: errorMessage,
        });
        setIsloadingSuccessTransactionCount(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setIsloadingFailedTransactionCount(true);
      const { status, count, errorMessage } =
        await collectionServices.getDocCount("TokenTransactions", [
          {
            uField: "status",
            uid: "fail",
          },
        ]);

      if (status === "success" && count) {
        setFailedTransactionCount(count);
        setIsloadingFailedTransactionCount(false);
      }
      if (status === "success") {
        setIsloadingFailedTransactionCount(false);
      }
      if (status === "error" && errorMessage) {
        showSnackbar({
          status,
          openSnackbar: true,
          msg: errorMessage,
        });
        setIsloadingFailedTransactionCount(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setIsloadingPendingTransactionCount(true);
      const { status, count, errorMessage } =
        await collectionServices.getDocCount("TokenTransactions", [
          {
            uField: "status",
            uid: "pending",
          },
        ]);

      if (status === "success" && count) {
        setPendingTransactionCount(count);
        setIsloadingPendingTransactionCount(false);
      }

      if (status === "success") {
        setIsloadingPendingTransactionCount(false);
      }

      if (status === "error" && errorMessage) {
        showSnackbar({
          status,
          openSnackbar: true,
          msg: errorMessage,
        });
        setIsloadingPendingTransactionCount(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setIsloadingMeterTransactionCount(true);
      const { status, count, errorMessage } =
        await collectionServices.getAllDocCount("Meters");

      if (status === "success" && count) {
        setMeterTransactionCount(count);
        setIsloadingMeterTransactionCount(false);
      }

         if (status === "success") {
          setIsloadingMeterTransactionCount(false);
      }

      if (status === "error" && errorMessage) {
        showSnackbar({
          status,
          openSnackbar: true,
          msg: errorMessage,
        });
        setIsloadingMeterTransactionCount(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setIsloadingAllAccountsCount(true);
      const { status, count, errorMessage } =
        await collectionServices.getAllDocCount("Users");

      if (status === "success" && count) {
        setAllAccountssCount(count);
        setIsloadingAllAccountsCount(false);
      }
      if (status === "success") {
        setIsloadingMeterTransactionCount(false);
    }

    if (status === "success") {
      setIsloadingMeterTransactionCount(false);
  }

      if (status === "error" && errorMessage) {
        showSnackbar({
          status,
          openSnackbar: true,
          msg: errorMessage,
        });
        setIsloadingAllAccountsCount(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setIsloadingAllTransactionCount(true);
      const { status, count, errorMessage } =
        await collectionServices.getAllDocCount("TokenTransactions");

      if (status === "success" && count) {
        setAllTransactionCount(count);
        setIsloadingAllTransactionCount(false);
      }
      if (status === "success") {
        setIsloadingAllTransactionCount(false);
    }

      if (status === "error" && errorMessage) {
        showSnackbar({
          status,
          openSnackbar: true,
          msg: errorMessage,
        });
        setIsloadingAllTransactionCount(false);
      }
    })();
  }, []);

  return (
    <Container>
      <Spacer space={40} />
      <Typography variant="h6" color="textPrimary" sx={{ fontWeight: "bold" }}>
        Account Counts
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
          <MgtStatCount
            title={isloadingAllAccountsCount ? "Fetching..." : "All Accounts"}
            count={allAccountsCount}
            icon={<SupervisedUserCircleIcon sx={{ fontSize: 40 }} />}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <MgtStatCount
            title={isloadingCustomerCount ? "Fetching..." : "Customers"}
            count={customersCount}
            icon={<AccountCircleIcon sx={{ fontSize: 40 }} />}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <MgtStatCount
            title={isloadingMgtCount ? "Fetching..." : "Mgt"}
            count={mgtCount}
            icon={<AdminPanelSettingsIcon sx={{ fontSize: 40 }} />}
          />
        </Grid>
      </Grid>
      
      <Spacer space={40} />
      <MgtAccountStats />
      <Spacer space={40} />
      <Typography variant="h6" color="textPrimary" sx={{ fontWeight: "bold" }}>
        Token Transactions Counts
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
          <MgtStatCount
            title={isloadingAllTransactionCount ? "Fetching..." : "All"}
            count={allTransactionCount}
            icon={<ReceiptLongTwoToneIcon sx={{ fontSize: 40 }} />}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <MgtStatCount
            title={isloadingSuccessTransactionCount ? "Fetching..." : "Success"}
            count={successTransactionCount}
            icon={<DoneAllIcon sx={{ fontSize: 40 }} />}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <MgtStatCount
            title={isloadingFailedTransactionCount ? "Fetching..." : "Failed"}
            count={failedTransactionCount}
            icon={<ErrorOutlineIcon sx={{ fontSize: 40 }} />}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <MgtStatCount
            title={isloadingPendingTransactionCount ? "Fetching..." : "Pending"}
            count={pendingTransactionCount}
            icon={<AutorenewIcon sx={{ fontSize: 40 }} />}
          />
        </Grid>
      </Grid>
      <Spacer space={40} />
      <MgtTokenTransactionStats />
      <Spacer space={40} />
      <Typography variant="h6" color="textPrimary" sx={{ fontWeight: "bold" }}>
        Meter Counts
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
          <MgtStatCount
            title={isloadingMeterTransactionCount ? "Fetching..." : "All"}
            count={meterTransactionCount}
            icon={<ElectricMeterIcon sx={{ fontSize: 40 }} />}
          />
        </Grid>
      </Grid>
      <MgtMeterStats />
    </Container>
  );
};

export default MgtDasboardComponent;
