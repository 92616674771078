import AccountUpdateProfile from "@/components/account/AccountUpdateProfile";

import GeneralHeader from "@/components/headers/GeneralHeader";
import SessionFormLayout from "@/components/session/SessionFormLayout";
import { CLIENT_BUY_TOKEN, DASHBOARD_BASE } from "@/routes/dashboard-routes";
import { Stack } from "@mui/material";
import Button from "@mui/material/Button";

const UpdateProfile = () => {
  return (
    <>
      <GeneralHeader title="Complete your profile" />
      <SessionFormLayout caption="Kindly input all fields to complete profile">
        <AccountUpdateProfile compact />
      </SessionFormLayout>

      <Stack alignItems="center" sx={{ mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          href={`/${DASHBOARD_BASE}/${CLIENT_BUY_TOKEN}`}
        >
          Skip
        </Button>
      </Stack>
    </>
  );
};

export default UpdateProfile;
