import Spacer from "@/components/common/Spacer";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-mui";
import { useState } from "react";
import { IconButton, InputAdornment, Stack, Typography } from "@mui/material";
import { Lock, VisibilityOff, Visibility } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Link } from "react-router-dom";
import { SESSION_BASE } from "@/routes/session-routes";
import PhoneIcon from "@mui/icons-material/Phone";
import PersonIcon from "@mui/icons-material/Person";

const RegisterForm = () => {
  const [showPassword, setShowPassword] = useState(false);

  function handleShowPassword(): void {
    setShowPassword(!showPassword);
  }
  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        phoneNumber: "",
      }}
      onSubmit={() => {}}
    >
      {({ isSubmitting, submitForm }) => (
        <Form>
          <Field
            component={TextField}
            type="text"
            variant="filled"
            fullWidth
            label="Enter first name"
            name="firstName"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
          />
          <Spacer space={10} />
          <Field
            component={TextField}
            type="text"
            variant="filled"
            fullWidth
            label="Enter last name"
            name="lastName"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
          />
          <Spacer space={10} />
          <Field
            component={TextField}
            type="text"
            variant="filled"
            fullWidth
            label="Enter phone number"
            name="phoneNumber"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneIcon />
                </InputAdornment>
              ),
            }}
          />
          <Spacer space={10} />
          <Field
            name="password"
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            component={TextField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShowPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth
            variant="filled"
          />
          <Spacer space={10} />
          <Stack>
            <LoadingButton
              onClick={submitForm}
              variant="contained"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              Register
            </LoadingButton>
          </Stack>
          <Spacer space={30} />
          <Stack alignItems="center">
            <Typography
              variant="caption"
              color="textPrimary"
              textAlign="center"
            >
              Already have an account? Login{" "}
              <Link to={`/${SESSION_BASE}`}>here</Link>
            </Typography>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default RegisterForm;
