import Spacer from "@/components/common/Spacer";
import { Box, Grid, Typography } from "@mui/material";
import bg1Image from "@/assets/bg-1.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Helmet } from "react-helmet";
interface Props {
  title: string;
}
const DashboardHeader = ({ title }: Props) => {
  return (
    <Box sx={{ position: "relative",mb:2 }}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Box
        sx={{
          position: "absolute",
          top: "20%",
          left: 0,
          width: "100%",
          zIndex: 3,
        }}
      >
        <Grid container>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h1"
              color="textPrimary"
              textAlign="center"
              sx={{ fontSize: "3em", fontWeight: "bolder" , color : "primary.main" }}
            >
              {title}
            </Typography>
            <Spacer space={20} />
          </Grid>
          <Grid item xs={12} md={3}></Grid>
        </Grid>
      </Box>
      <LazyLoadImage
        effect="blur"
        style={{ objectFit: "cover", zIndex: 1 }}
        src={bg1Image}
        width={"100%"}
        height={120}
      />
    </Box>
  );
};

export default DashboardHeader;
