import { useCollection, useSession } from "@/hooks/app-hooks";
import { IHaskeTokenDocument } from "@/types/transaction-types";
import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  Button,
} from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Spacer from "@/components/common/Spacer";
import moment from "moment";
import FormattedAmount from "@/components/common/FormattedAmount";
import { forwardRef, useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  generateTransactionId,
  generateUUIDV4,
  reloadPage,
} from "@/utils/funcs";
import { LoadingButton } from "@mui/lab";
import { collectionServices, powerPayServices } from "@/services/root";
import { showSnackbar } from "@/helpers/snackbar-helpers";
import { KadunaCustomerDoc } from "@/types/customer-types";
import LoadingCircle from "@/components/common/LoadingCircle";
import TokenTransactionReciept from "@/components/forms/token/TokenTransactionReciept";
import ThirdPaymentTransactionReciept from "@/components/forms/token/ThirdPaymentTransactionReciept";

const ViewTransactionContainer = forwardRef((props: any, ref: any) => {
  const current = useCollection().current as IHaskeTokenDocument;
  const [copied, setCopied] = useState(false);

  const profile = useSession();

  const requestId = generateTransactionId();

  const [processing, setProcessing] = useState(false);

  const DETAILS = [
    {
      title: "Reference",
      value: current.transactionID,
    },
    {
      title: "Receipt Number",
      value: current.requestId,
    },
    {
      title: "Paid At",
      value: moment(current.addedOn.toDate()).format("lll"),
    },
    {
      title: "Payment method",
      value:
        current.paymentMethod === "thirdparty"
          ? "Paystack"
          : current.paymentMethod,
    },
  ];
  const PAYMENT_DETAILS = [
    {
      title: "Amount",
      value: <FormattedAmount amount={current.tokenAmount} />,
    },
    {
      title: "Service Charge",
      value: <FormattedAmount amount={current.serviceFee} />,
    },
    {
      title: "Total Amount",
      value: (
        <FormattedAmount amount={current.tokenAmount + current.serviceFee} />
      ),
    },
  ];

  const [kadunaCustomerData, setKadunaCustomerData] =
    useState<KadunaCustomerDoc | null>();

  const [loadingKadunaCustomerData, setLoadingKadunaCustomerData] =
    useState(false);

  useEffect(() => {
    (async () => {
      if (current.meter.meterNumber !== "") {
        setLoadingKadunaCustomerData(true);
        const { status, item, errorMessage } =
          await powerPayServices.getCustomer(current.meter.meterNumber);

        if (status === "success" && item) {
          setKadunaCustomerData(item);
          setLoadingKadunaCustomerData(false);
        }
        if (status === "error" && errorMessage) {
          showSnackbar({ status, msg: errorMessage, openSnackbar: true });
          setLoadingKadunaCustomerData(false);
        }
      }
    })();
  }, [current.meter.meterNumber]);

  const [showSuccessTransactionModal, setShowSuccessTransactionModal] =
    useState(false);

  const [
    showThirdPartyPaymentTransactionModal,
    setShowThirdPartyPaymentTransactionModal,
  ] = useState(false);

  return (
    <div ref={ref}>
      <Container>
        <TokenTransactionReciept
          visible={showSuccessTransactionModal}
          close={() => setShowSuccessTransactionModal(false)}
          data={{
            recieptNo: current.transactionID,
            meterNumber: current.meter.meterNumber,
            customerName: kadunaCustomerData?.customerName ?? "",
            customerPhonenumber: kadunaCustomerData?.customerMobileNo ?? "",
            transactionId: current.tokenResponse?.transactionId ?? "",
            units: current.tokenResponse?.totalUnitVended ?? "",
            cost: current.tokenAmount + current.serviceFee,
            address: kadunaCustomerData?.customerAddress ?? "",
            token: current.tokenResponse?.result ?? "",
          }}
        />
        <ThirdPaymentTransactionReciept
          visible={showThirdPartyPaymentTransactionModal}
          close={() => setShowThirdPartyPaymentTransactionModal(false)}
          data={{
            recieptNo: current.paymentResponse?.receiptNo ?? 0,
            customerName: kadunaCustomerData?.customerName ?? "",
            customerPhonenumber: kadunaCustomerData?.customerMobileNo ?? "",
            transactionId: current.paymentResponse?.paymentId ?? "",
            cost: current.tokenAmount + current.serviceFee,
            address: kadunaCustomerData?.customerAddress ?? "",
          }}
        />
        <Spacer space={50} />
        <Grid container spacing={1} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={6}>
            <Stack>
              <Grid
                container
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  mt: 2,
                }}
              >
                <Grid item xs={3}>
                  <LazyLoadImage
                    src={require("@/assets/electric-meter.png")}
                    alt="buy for meter"
                    style={{ width: 60, height: 60 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Stack alignItems="center">
                    <Typography variant="caption" color="textPrimary">
                      {current.meter.meterName}
                    </Typography>
                    <Typography color="textPrimary">
                      {current.meter.meterNumber}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Stack
                    direction="row"
                    sx={{
                      width: "100%",
                      justifyContent: { xs: "center", md: "right" },
                    }}
                  >
                    <Box
                      sx={{
                        bgcolor:
                          current.status === "success"
                            ? "green"
                            : current.status === "pending"
                            ? "orange"
                            : current.status === "fail"
                            ? "red"
                            : "white",
                        color: "#fff",
                        width: "fit-content",
                        p: 1,
                        borderRadius: 4,
                        fontSize: 8,
                      }}
                    >
                      {current.status === "success"
                        ? "SUCCESS"
                        : current.status === "pending"
                        ? "PENDING"
                        : current.status === "fail"
                        ? "FAIL"
                        : "FAIL"}
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
              <Divider flexItem sx={{ my: 2 }} />
              {current.status === "success" && current.meterType === "Prepaid" && (
                <>
                  <Typography color="textPrimary" textAlign="center">
                    <code>TOKEN</code>
                  </Typography>
                  <Box
                    boxShadow={10}
                    sx={{
                      borderRadius: 20,
                      p: 2,
                      position: "relative",
                    }}
                  >
                    <Typography color="textPrimary" textAlign="center">
                      <code>{current.tokenResponse?.result}</code>
                    </Typography>
                    <Box sx={{ position: "absolute", right: 10, bottom: 10 }}>
                      <Stack>
                        <CopyToClipboard
                          text={`${current.tokenResponse?.result}`}
                          onCopy={() => {
                            setCopied(true);
                            setTimeout(() => {
                              setCopied(false);
                            }, 5000);
                          }}
                        >
                          <Tooltip title="copied" open={copied}>
                            <IconButton>
                              <ContentCopyIcon />
                            </IconButton>
                          </Tooltip>
                        </CopyToClipboard>
                      </Stack>
                    </Box>
                  </Box>

                  <Typography
                    color="textPrimary"
                    textAlign="center"
                    sx={{ mt: 2 }}
                  >
                    Number of Units is{" "}
                    <code>{current.tokenResponse?.totalUnitVended} kWh</code>
                  </Typography>
                </>
              )}

              {current.status === "pending" && (
                <>
                  <Stack alignItems="center">
                    <LoadingButton
                      loading={processing}
                      variant="contained"
                      sx={{
                        width: "fit-content",
                        bgcolor: "primary.light",
                        color: "#fff",
                      }}
                      onClick={async () => {
                        setProcessing(true);
                        const {
                          status: requestTokenStatus,
                          errorMessage: requqestTokenErrmsg,
                          item: _tokenResponse,
                        } = await powerPayServices.generateToken({
                          meterNumber: current.meter.meterNumber,
                          tokenType: "CreditToken",
                          amount: `${current.tokenAmount}`,
                          tokenId: `${process.env.REACT_APP_CLIENT_ID}`,
                          locationofPayment: `${
                            profile.address.city
                              ? profile.address.city
                              : "kaduna"
                          },${
                            profile.address.state
                              ? profile.address.state
                              : "kaduna"
                          }`,
                          isBorrowed: false,
                          employeeTokenId: `${process.env.REACT_APP_CLIENT_ID}`,
                          requestId: requestId,
                          modeofPayment: "card",
                          paymentRefNumber: requestId,
                          telephoneNumber: profile.phoneNumber,
                        });

                        if (
                          requestTokenStatus === "success" &&
                          _tokenResponse?.resultCode === "Credit:Electricity"
                        ) {
                          const {
                            status: tokenGenStatus,
                            errorMessage: tokenGenErrMsg,
                          } = await collectionServices.editDoc(
                            "TokenTransactions",
                            current.transactionID,
                            {
                              status: "success",
                              tokenResponse: _tokenResponse,
                            }
                          );

                          if (tokenGenStatus === "success") {
                            reloadPage();
                          }
                          if (tokenGenStatus === "error") {
                            showSnackbar({
                              status: tokenGenStatus,
                              msg: tokenGenErrMsg,
                              openSnackbar: true,
                            });
                            setProcessing(false);
                          }
                        }

                        if (
                          requestTokenStatus === "success" &&
                          _tokenResponse?.resultCode !== "Credit:Electricity"
                        ) {
                          showSnackbar({
                            status: "warning",
                            msg: `${_tokenResponse?.tokenComment}`,
                            openSnackbar: true,
                          });
                          setProcessing(false);
                        }

                        if (
                          requestTokenStatus === "error" &&
                          requqestTokenErrmsg
                        ) {
                          showSnackbar({
                            status: requestTokenStatus,
                            msg: requqestTokenErrmsg,
                            openSnackbar: true,
                          });
                          setProcessing(false);
                        }
                      }}
                    >
                      Generate token
                    </LoadingButton>
                  </Stack>
                </>
              )}
            </Stack>
            <Divider sx={{ my: 2 }} />
            {DETAILS.map((item) => (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                key={generateUUIDV4()}
              >
                <Typography variant="caption" color="textPrimary">
                  {item.title}
                </Typography>
                <Typography variant="body2" color="textPrimary">
                  {item.value}
                </Typography>
              </Stack>
            ))}
            {loadingKadunaCustomerData && (
              <Stack direction="row" alignItems="center" columnGap={1}>
                <Typography variant="caption" color="textPrimary">
                  Fetching customer info
                </Typography>
                <LoadingCircle />
              </Stack>
            )}
            {kadunaCustomerData !== null && !loadingKadunaCustomerData && (
              <>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="caption" color="textPrimary">
                    Meter Number
                  </Typography>
                  <Typography
                    variant="overline"
                    color="textPrimary"
                    sx={{ fontWeight: "bold" }}
                  >
                    {kadunaCustomerData?.meterNumber}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="caption" color="textPrimary">
                    Customer Name
                  </Typography>
                  <Typography
                    variant="overline"
                    color="textPrimary"
                    sx={{
                      fontWeight: "bold",
                      width: 200,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textAlign: "right",
                    }}
                  >
                    {kadunaCustomerData?.customerName}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="caption" color="textPrimary">
                    Customer AccountNo
                  </Typography>
                  <Typography
                    variant="overline"
                    color="textPrimary"
                    sx={{
                      fontWeight: "bold",
                      width: 200,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textAlign: "right",
                    }}
                  >
                    {kadunaCustomerData?.customerAccountNo}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="caption" color="textPrimary">
                    Customer State
                  </Typography>
                  <Typography
                    variant="overline"
                    color="textPrimary"
                    sx={{
                      fontWeight: "bold",
                      width: 200,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textAlign: "right",
                    }}
                  >
                    {kadunaCustomerData?.customerState}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="caption" color="textPrimary">
                    Customer transformer
                  </Typography>
                  <Typography
                    variant="overline"
                    color="textPrimary"
                    sx={{
                      fontWeight: "bold",
                      width: 200,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textAlign: "right",
                    }}
                  >
                    {kadunaCustomerData?.customerTransformer}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="caption" color="textPrimary">
                    Address
                  </Typography>
                  <Typography
                    variant="overline"
                    color="textPrimary"
                    sx={{
                      fontWeight: "bold",
                      width: 300,
                      // whiteSpace: "nowrap",
                      // overflow: "hidden",
                      // textOverflow: "ellipsis",
                      textAlign: "right",
                      lineBreak: "auto",
                      lineHeight: 2,
                    }}
                  >
                    {kadunaCustomerData?.customerAddress}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="caption" color="textPrimary">
                    Regional Office
                  </Typography>
                  <Typography
                    variant="overline"
                    color="textPrimary"
                    sx={{
                      fontWeight: "bold",
                      width: 200,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textAlign: "right",
                    }}
                  >
                    {kadunaCustomerData?.regionalOffice}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="caption" color="textPrimary">
                    Service Center
                  </Typography>
                  <Typography
                    variant="overline"
                    color="textPrimary"
                    sx={{
                      fontWeight: "bold",
                      width: 200,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textAlign: "right",
                    }}
                  >
                    {kadunaCustomerData?.serviceCenter}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="caption" color="textPrimary">
                    Is Prepaid
                  </Typography>
                  <Typography
                    variant="overline"
                    color="textPrimary"
                    sx={{ fontWeight: "bold" }}
                  >
                    {kadunaCustomerData?.isPPM ? "Yes" : "No"}
                  </Typography>
                </Stack>
              </>
            )}
            <Divider sx={{ my: 2 }} />
            {PAYMENT_DETAILS.map((item) => (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                key={generateUUIDV4()}
              >
                <Typography variant="caption" color="textPrimary">
                  {item.title}
                </Typography>
                <Typography variant="overline" color="textPrimary">
                  {item.value}
                </Typography>
              </Stack>
            ))}
          </Grid>
        </Grid>
        {kadunaCustomerData !== undefined && (
          <Stack alignItems="center">
            <Button
              variant="contained"
              color="primary"
              sx={{ color: "#fff", width: "fit-content" }}
              onClick={() => {
                if (current.meterType === "Prepaid") {
                  setShowSuccessTransactionModal(true);
                } else {
                  setShowThirdPartyPaymentTransactionModal(true);
                }
              }}
            >
              Show reciept
            </Button>
          </Stack>
        )}
      </Container>
    </div>
  );
});

export default ViewTransactionContainer;
