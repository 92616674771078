import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import InputAdornment from "@mui/material/InputAdornment";
import { collectionServices, powerPayServices } from "@/services/root";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import LoadingCircle from "@/components/common/LoadingCircle";
import { Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { IAccountDocument } from "@/types/session-types";
import { showSnackbar } from "@/helpers/snackbar-helpers";
import { sessionRepository } from "@/repositories/root";
import { CLIENT_BUY_TOKEN, DASHBOARD_BASE } from "@/routes/dashboard-routes";

const SignInForm = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [checkingPhonenumber, setCheckingPhonenumber] = useState(false);
  const [phoneNumberExists, setPhoneNumberExists] = useState(false);

  const [password, setpassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const [loggingIn, setLoggingIn] = useState(false);

  const navigate = useNavigate();

  return (
    <Stack spacing={2}>
      <TextField
        id="phonenumber"
        value={phoneNumber}
        onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
          let val = event.target.value;
          setPhoneNumber(val);
          setPhoneNumberExists(false);
          if (val.length > 10) {
            setCheckingPhonenumber(true);
            let response = await collectionServices.getDocCount("Users", [
              {
                uid: val,
                uField: "phoneNumber",
              },
            ]);
            setCheckingPhonenumber(false);
            if (response.status === "success") {
              const count = response.count;
              if (count === 0) {
                setPhoneNumberExists(true);
              }
            }
            //   if (response.status === "error") {
            //     console.log(response.errorMessage);
            //   }
          }
        }}
        type="text"
        variant="filled"
        fullWidth
        label="Enter phone number (i.e 0812000000)"
        name="phoneNumber"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PhoneIphoneIcon />
            </InputAdornment>
          ),
        }}
      />
      {phoneNumberExists && (
        <Alert
          severity="warning"
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => {
                navigate(`/`);
              }}
            >
              Get started
            </Button>
          }
        >
          This phonenumber does not exists in our database.
        </Alert>
      )}
      {checkingPhonenumber && <LoadingCircle />}
      <FormControl fullWidth variant="filled">
        <InputLabel htmlFor="password">Password</InputLabel>
        <Input
          id="password"
          type={showPassword ? "text" : "password"}
          name="password"
          value={password}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setpassword(event.target.value);
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>

      <LoadingButton
        loading={loggingIn}
        onClick={async () => {
          setLoggingIn(true);
          let getUserByPhonenumberResponse = await collectionServices.getDocs(
            "Users",
            [
              {
                uField: "phoneNumber",
                uid: phoneNumber,
              },
            ]
          );
          setLoggingIn(false);

          if (getUserByPhonenumberResponse.status === "success") {
            if (getUserByPhonenumberResponse.list.length > 0) {
              const account: IAccountDocument =
                getUserByPhonenumberResponse.list[0];
              const hash = account.hash;

              let comparePasswordResponse =
                await powerPayServices.comparePassword(password, hash);
              if (comparePasswordResponse.status === "success") {
                const valid = comparePasswordResponse.item;

                if (valid) {
                  let tokenResponse = await powerPayServices.createCustomToken(
                    account.uid
                  );
                  if (tokenResponse.status === "success") {
                    let token = tokenResponse.item;
                    // console.log("token->", token);
                    let signInResponse =
                      await sessionRepository.signInWithCustomToken(
                        token ?? ""
                      );

                    if (signInResponse.status === "success") {
                      navigate(`/${DASHBOARD_BASE}/${CLIENT_BUY_TOKEN}`);
                    }

                    if (signInResponse.status === "error") {
                      showSnackbar({
                        status: "error",
                        msg: signInResponse.errorMessage ?? "",
                        openSnackbar: true,
                      });
                    }
                  }
                  if (tokenResponse.status === "error") {
                    showSnackbar({
                      status: "error",
                      msg: tokenResponse.errorMessage ?? "",
                      openSnackbar: true,
                    });
                  }
                } else {
                  showSnackbar({
                    status: "error",
                    msg: "Password is incorrect",
                    openSnackbar: true,
                  });
                }
              }

              if (comparePasswordResponse.status === "error") {
                showSnackbar({
                  status: "error",
                  msg: comparePasswordResponse.errorMessage ?? "",
                  openSnackbar: true,
                });
              }
            } else {
              showSnackbar({
                status: "error",
                msg: "Account do not exist!",
                openSnackbar: true,
              });
            }
          }

          if (getUserByPhonenumberResponse.status === "error") {
            showSnackbar({
              status: "error",
              msg: getUserByPhonenumberResponse.errorMessage ?? "",
              openSnackbar: true,
            });
          }
        }}
      >
        Login
      </LoadingButton>
    </Stack>
  );
};

export default SignInForm;
