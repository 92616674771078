import { db } from "@/configs/firebase";
import { COLLECTIONS } from "@/constants/collection-constants";
import { IAccountDocument } from "@/types/session-types";
import {
  IAddToUserWallet,
  ICreatePSTransferReceiptCode,
  IDeductFromUserWallet,
  IGetAccountDetailsParams,
  IGetBankParams,
  IGetCustomerTransactions,
  IInstantPSInitiateTransfer,
  IMoveFundFromCommissionToBalance,
  IUpdateUserPSTransferReceiptCode,
  IWalletTransactionInput,
} from "@/types/wallet-types";

import axios from "axios";

import {
  doc,
  setDoc,
  updateDoc,
  deleteField,
  runTransaction,
  increment,
  serverTimestamp,
  query,
  collection,
  where,
  orderBy,
  limit,
  getDocs,
  startAfter,
  getDoc,
} from "firebase/firestore";

class WalletRepository {
  async getbanks({ country, currency }: IGetBankParams) {
    return axios.get("https://api.paystack.co/bank", {
      params: {
        country,
        currency,
      },
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_LIVE_SECRET_KEY}`,
      },
    });
  }

  async resolveAccountDetails({
    accountNumber,
    bankCode,
  }: IGetAccountDetailsParams) {
    return axios.get("https://api.paystack.co/bank/resolve", {
      params: {
        account_number: accountNumber,
        bank_code: bankCode,
      },
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_LIVE_SECRET_KEY}`,
        "Content-Type": "application/json",
      },
    });
  }

  async createPSTransferReceiptCode({
    accountName,
    accountNumber,
    bankCode,
    persona,
  }: ICreatePSTransferReceiptCode) {
    return axios.post(
      "https://api.paystack.co/transferrecipient",
      {
        type: "nuban",
        name: accountName,
        account_number: accountNumber,
        bank_code: bankCode,
        currency: "NGN",
        metadata: {
          class: persona,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_LIVE_SECRET_KEY}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  async updateUserPSTransferReceiptCode({
    userId,
    accountName,
    accountNumber,
    bankCode,
    bankName,
    psrecieptCode,
  }: IUpdateUserPSTransferReceiptCode) {
    return setDoc(
      doc(db, COLLECTIONS.users, userId),
      {
        wallet: {
          bankAccount: {
            paystack: {
              accountName,
              accountNumber,
              bankCode,
              bankName,
              psrecieptCode,
            },
          },
        },
      },
      { merge: true }
    );
  }
  async updateBrandPSTransferReceiptCode({
    userId,
    accountName,
    accountNumber,
    bankCode,
    bankName,
    psrecieptCode,
  }: IUpdateUserPSTransferReceiptCode) {
    return setDoc(
      doc(db, COLLECTIONS.users, userId),
      {
        shop: {
          wallet: {
            bankAccount: {
              paystack: {
                accountName,
                accountNumber,
                bankCode,
                bankName,
                psrecieptCode,
              },
            },
          },
        },
      },
      { merge: true }
    );
  }

  async updateMgtPSTransferReceiptCode({
    accountName,
    accountNumber,
    bankCode,
    bankName,
    psrecieptCode,
  }: IUpdateUserPSTransferReceiptCode) {
    return setDoc(
      doc(db, COLLECTIONS.ekioja, "wallet"),
      {
        bankAccount: {
          paystack: {
            accountName,
            accountNumber,
            bankCode,
            bankName,
            psrecieptCode,
          },
        },
      },
      { merge: true }
    );
  }
  async removeBankAccount(userId: string) {
    return updateDoc(doc(db, COLLECTIONS.users, userId), {
      "wallet.bankAccount": deleteField(),
    });
  }
  async removeBrandBankAccount(userId: string) {
    return updateDoc(doc(db, COLLECTIONS.users, userId), {
      "shop.wallet.bankAccount": deleteField(),
    });
  }

  async removeMgtBankAccount() {
    return updateDoc(doc(db, COLLECTIONS.ekioja, "wallet"), {
      bankAccount: deleteField(),
    });
  }

  async instantPSInitiateTransfer({
    amount,
    psrecieptCode,
    reason,
  }: IInstantPSInitiateTransfer) {
    return axios.post(
      "https://api.paystack.co/transfer",
      {
        source: "balance",
        amount: amount * 100,
        recipient: psrecieptCode,
        reason: reason,
      },
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_LIVE_SECRET_KEY}`,
          "Content-Type": `application/json`,
        },
      }
    );
  }

  async deductFromUserWallet({ userId, amount }: IDeductFromUserWallet) {
    const userDocRef = doc(db, COLLECTIONS.users, userId);

    return runTransaction(db, async (transaction) => {
      const userDoc = await transaction.get(userDocRef);
      if (!userDoc.exists()) {
        return;
      }

      transaction.set(
        userDocRef,
        {
          wallet: {
            balance: increment(-amount),
            overallOut: increment(amount),
          },
        },
        { merge: true }
      );
    });
  }
  async deductFromBrandWallet({ userId, amount }: IDeductFromUserWallet) {
    const userDocRef = doc(db, COLLECTIONS.users, userId);

    return runTransaction(db, async (transaction) => {
      const userDoc = await transaction.get(userDocRef);
      if (!userDoc.exists()) {
        return;
      }

      transaction.set(
        userDocRef,
        {
          shop: {
            wallet: {
              balance: increment(-amount),
              overallOut: increment(amount),
            },
          },
        },
        { merge: true }
      );
    });
  }

  async deductFromMgtWallet({ amount }: IDeductFromUserWallet) {
    const userDocRef = doc(db, COLLECTIONS.ekioja, "wallet");

    return runTransaction(db, async (transaction) => {
      const userDoc = await transaction.get(userDocRef);
      if (!userDoc.exists()) {
        return;
      }

      transaction.set(
        userDocRef,
        {
          balance: increment(-amount),
          overallOut: increment(amount),
        },
        { merge: true }
      );
    });
  }

  async addToBrandWallet({ userId, amount }: IAddToUserWallet) {
    const userDocRef = doc(db, COLLECTIONS.users, userId);
    return runTransaction(db, async (transaction) => {
      const userDoc = await transaction.get(userDocRef);
      if (!userDoc.exists()) {
        return;
      }

      transaction.set(
        userDocRef,
        {
          shop: {
            wallet: {
              balance: increment(amount),
              overallIn: increment(amount),
            },
          },
        },
        { merge: true }
      );
    });
  }
  async addToUserWallet({ userId, amount }: IAddToUserWallet) {
    const userDocRef = doc(db, COLLECTIONS.users, userId);
    return runTransaction(db, async (transaction) => {
      const userDoc = await transaction.get(userDocRef);
      if (!userDoc.exists()) {
        return;
      }

      transaction.set(
        userDocRef,
        {
          wallet: {
            balance: increment(amount),
            overallIn: increment(amount),
          },
        },
        { merge: true }
      );
    });
  }
  async addToMgtWallet({ amount }: IAddToUserWallet) {
    const userDocRef = doc(db, COLLECTIONS.ekioja, "wallet");
    return runTransaction(db, async (transaction) => {
      const userDoc = await transaction.get(userDocRef);
      if (!userDoc.exists()) {
        return;
      }

      transaction.set(
        userDocRef,
        {
          balance: increment(amount),
          overallIn: increment(amount),
        },
        { merge: true }
      );
    });
  }

  async addToUserCommission({ userId, amount }: IAddToUserWallet) {
    const userDocRef = doc(db, COLLECTIONS.users, userId);
    return runTransaction(db, async (transaction) => {
      const userDoc = await transaction.get(userDocRef);
      if (!userDoc.exists()) {
        return;
      }

      transaction.set(
        userDocRef,
        {
          wallet: {
            totalCommission: increment(amount),
          },
        },
        { merge: true }
      );
    });
  }

  async addToUserCredit({ userId, amount }: IAddToUserWallet) {
    const userDocRef = doc(db, COLLECTIONS.users, userId);
    return runTransaction(db, async (transaction) => {
      const userDoc = await transaction.get(userDocRef);
      if (!userDoc.exists()) {
        return;
      }

      transaction.set(
        userDocRef,
        {
          wallet: {
            credit: increment(amount),
            overallIn: increment(amount),
          },
        },
        { merge: true }
      );
    });
  }

  async deductFromUserCredit({ userId, amount }: IDeductFromUserWallet) {
    const userDocRef = doc(db, COLLECTIONS.users, userId);

    return runTransaction(db, async (transaction) => {
      const userDoc = await transaction.get(userDocRef);
      if (!userDoc.exists()) {
        return;
      }

      transaction.set(
        userDocRef,
        {
          wallet: {
            credit: increment(-amount),
          },
        },
        { merge: true }
      );
    });
  }
  async addToMgtCommission(amount: number) {
    var mgtDocRef = doc(db, COLLECTIONS.mgt, "finance");

    return runTransaction(db, async (transaction) => {
      const userDoc = await transaction.get(mgtDocRef);
      if (!userDoc.exists()) {
        return;
      }

      transaction.set(
        mgtDocRef,
        {
          totalCommission: increment(amount),
        },
        { merge: true }
      );
    });
  }

  async moveFundFromCommissionToBalance({
    userId,
    amount,
  }: IMoveFundFromCommissionToBalance) {
    const userDocRef = doc(db, COLLECTIONS.users, userId);

    return runTransaction(db, async (transaction) => {
      return transaction.get(userDocRef).then((userDoc) => {
        if (!userDoc.exists) {
          throw new Error("user Document not found!");
        }

        const currentdata = userDoc.data() as IAccountDocument;
        const newWalletBalance = currentdata.wallet?.balance
          ? currentdata.wallet?.balance + amount
          : currentdata.wallet?.balance;
        const newWalletCommission = currentdata.wallet?.totalCommission
          ? currentdata.wallet?.totalCommission - amount
          : currentdata.wallet?.totalCommission;

        transaction.set(
          userDocRef,
          {
            wallet: {
              balance: newWalletBalance,
              totalCommission: newWalletCommission,
            },
          },
          { merge: true }
        );
      });
    });
  }

  async getCustomerTransactions({
    perPage,
    page,
    customer,
  }: IGetCustomerTransactions) {
    return axios.get("https://api.paystack.co/transaction", {
      params: {
        perPage,
        page,
        customer,
      },
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_LIVE_SECRET_KEY}`,
      },
    });
  }

  async recordWalletTransaction(values: IWalletTransactionInput) {
    const docRef = doc(db, COLLECTIONS.walletTransactions, values.uid);
    return setDoc(docRef, {
      ...values,
      addedOn: serverTimestamp(),
    });
  }

  async getWalletTransactions(userId: string) {
    const q = query(
      collection(db, COLLECTIONS.walletTransactions),
      where("userId", "==", userId),
      orderBy("timestamp", "desc"),
      limit(10)
    );

    return getDocs(q);
  }
  async fetchMoreWalletTransactions(userId: string, lastDoc: any) {
    const q = query(
      collection(db, COLLECTIONS.walletTransactions),
      where("userId", "==", userId),
      orderBy("timestamp", "desc"),
      startAfter(lastDoc),
      limit(4)
    );

    return getDocs(q);
  }
  async getMgtWalletTransactions() {
    const q = query(
      collection(db, COLLECTIONS.walletTransactions),
      where("userId", "==", "ekioja007"),
      orderBy("timestamp", "desc"),
      limit(10)
    );

    return getDocs(q);
  }
  async fetchMoreMgtWalletTransactions(lastDoc: any) {
    const q = query(
      collection(db, COLLECTIONS.walletTransactions),
      where("userId", "==", "ekioja007"),
      orderBy("timestamp", "desc"),
      startAfter(lastDoc),
      limit(10)
    );

    return getDocs(q);
  }

  async getMgtFinance() {
    const docRef = doc(db, COLLECTIONS.mgt, "finance");
    return getDoc(docRef);
  }
}

export default WalletRepository;
