import { Backdrop, Box, Button, Stack, Typography } from "@mui/material";
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import vSwitchLogo from "@/assets/v-switch.jpg";

interface Props {
  visible: boolean;
  close: () => void;
  data: {
    recieptNo: number;
    customerName: string;
    customerPhonenumber: string;
    transactionId: string;
    cost: number;
    address: string;
  };
}

const ThirdPaymentTransactionReciept = ({ data, visible, close }: Props) => {
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 4,
        marginLeft: 0,
      }}
      open={visible}
    >
      <div>
        <Box
          sx={{
            minHeight: 600,
            width: { xs: "86vw", md: 300 },
            borderRadius: 5,
            bgcolor: "background.paper",
            p: 2,
          }}
          ref={componentRef}
        >
          <Stack alignItems="center" spacing={1}>
            <Typography
              variant="caption"
              color="textPrimary"
              textAlign="center"
            >
              **** CUSTOMER'S COPY ****
            </Typography>

            <img src={vSwitchLogo} alt="virtual switch" width={170} />

            <Typography variant="subtitle2" color="textPrimary">
              Kaduna Electric.
            </Typography>
            <Typography variant="subtitle2" color="textPrimary">
              POSTPAID
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="caption"
                color="textPrimary"
                sx={{
                  fontWeight: "bold",
                  width: 100,
                  textAlign: "left",
                  lineHeight: 1,
                }}
              >
                RECIEPT NO:
              </Typography>
              <Typography
                variant="overline"
                color="textPrimary"
                sx={{
                  fontWeight: "bold",
                  width: 200,
                  textAlign: "right",
                  lineHeight: 1,
                }}
              >
                {data.recieptNo}
              </Typography>
            </Stack>
            <Typography variant="body2" color="textPrimary">
              ****************************************
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="caption"
                color="textPrimary"
                sx={{
                  width: 100,
                  textAlign: "left",
                  lineHeight: 1,
                }}
              >
                DATE:
              </Typography>
              <Typography
                variant="overline"
                color="textPrimary"
                sx={{
                  fontWeight: "bold",
                  width: 200,
                  textAlign: "right",
                  lineHeight: 1,
                }}
              >
                {new Date().toDateString()}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="caption"
                color="textPrimary"
                sx={{
                  width: 100,
                  textAlign: "left",
                  lineHeight: 1,
                }}
              >
                BILLER:
              </Typography>
              <Typography
                variant="overline"
                color="textPrimary"
                sx={{
                  fontWeight: "bold",
                  width: 200,
                  textAlign: "right",
                  lineHeight: 1,
                }}
              >
                Kaduna Electric Distribution PostPaid
              </Typography>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="caption"
                color="textPrimary"
                sx={{
                  width: 100,
                  textAlign: "left",
                  lineHeight: 1,
                }}
              >
                CUSTOMER NAME:
              </Typography>
              <Typography
                variant="overline"
                color="textPrimary"
                sx={{
                  fontWeight: "bold",
                  width: 200,
                  textAlign: "right",
                  lineHeight: 1,
                }}
              >
                {data.customerName}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="caption"
                color="textPrimary"
                sx={{
                  width: 100,
                  textAlign: "left",
                  lineHeight: 1,
                }}
              >
                CUSTOMER PHONENUMBER:
              </Typography>
              <Typography
                variant="overline"
                color="textPrimary"
                sx={{
                  fontWeight: "bold",
                  width: 200,
                  textAlign: "right",
                  lineHeight: 1,
                }}
              >
                {data.customerPhonenumber || "nil"}
              </Typography>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="caption"
                color="textPrimary"
                sx={{
                  width: 100,
                  textAlign: "left",
                  lineHeight: 1,
                }}
              >
                REFERENCE:
              </Typography>
              <Typography
                variant="overline"
                color="textPrimary"
                sx={{
                  fontWeight: "bold",
                  width: 200,
                  textAlign: "right",
                  lineHeight: 1,
                }}
              >
                {data.transactionId}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="caption"
                color="textPrimary"
                sx={{
                  width: 100,
                  textAlign: "left",
                  lineHeight: 1,
                }}
              >
                COST:
              </Typography>
              <Typography
                variant="overline"
                color="textPrimary"
                sx={{
                  fontWeight: "bold",
                  width: 200,
                  textAlign: "right",
                  lineHeight: 1,
                }}
              >
                {new Intl.NumberFormat(undefined, {
                  style: "currency",
                  currency: "NGN",
                }).format(data.cost)}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="caption"
                color="textPrimary"
                sx={{
                  width: 100,
                  textAlign: "left",
                  lineHeight: 1,
                }}
              >
                ADDRESS:
              </Typography>
              <Typography
                variant="overline"
                color="textPrimary"
                sx={{
                  fontWeight: "bold",
                  width: 200,
                  textAlign: "right",
                  lineHeight: 1,
                }}
              >
                {data.address}
              </Typography>
            </Stack>
            <Typography variant="caption" color="textPrimary">
              *********************************
            </Typography>
            <Typography variant="caption" color="textPrimary">
              Thanks, Come again
            </Typography>
          </Stack>
        </Box>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{ mt: 1 }}
        >
          <Button
            variant="contained"
            color="primary"
            sx={{ color: "#fff" }}
            onClick={close}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ color: "#fff" }}
            onClick={handlePrint}
          >
            Print
          </Button>
        </Stack>
      </div>
    </Backdrop>
  );
};

export default ThirdPaymentTransactionReciept;
