import { IAccountWallet } from "@/types/session-types";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";

interface Props {
  wallet: IAccountWallet;
}
const WalletBankAccountInfo = ({ wallet }: Props) => {
  if (!wallet?.bankAccount) return <></>;
  return (
    <Box boxShadow={2} sx={{ backgroundColor: "background.paper" }}>
      <Typography variant="caption" color="textPrimary" sx={{ m: 1 }}>
        Bank account information
      </Typography>
      <Divider />
      {wallet?.bankAccount !== undefined && wallet !== undefined && (
        <>
          <List>
            <ListItem>
              <ListItemText
                primary={wallet?.bankAccount.paystack.accountName}
                secondary="Account name"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={wallet?.bankAccount.paystack.accountNumber}
                secondary="Account number"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={wallet?.bankAccount.paystack.bankName}
                secondary="Bank name"
              />
            </ListItem>
          </List>
        </>
      )}
    </Box>
  );
};

export default WalletBankAccountInfo;
