import { Grid, Paper, Stack, Typography } from "@mui/material";
import { NumericFormat } from "react-number-format";

const ClientDashboardContainer = () => {
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <Paper sx={{ width: "100%" }}>
            <Stack alignItems="center">
              <Typography
                variant="subtitle1"
                color="textPrimary"
                sx={{ fontSize: 20, fontWeight: "bolder" }}
              >
                My Meters
              </Typography>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                sx={{ fontSize: 30, fontWeight: "bolder" }}
              >
                10
              </Typography>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ width: "100%" }}>
            <Stack alignItems="center">
              <Typography
                variant="subtitle1"
                color="textPrimary"
                sx={{ fontSize: 20, fontWeight: "bolder" }}
              >
                Transactions
              </Typography>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                sx={{ fontSize: 30, fontWeight: "bolder" }}
              >
                100
              </Typography>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ width: "100%" }}>
            <Stack alignItems="center">
              <Typography
                variant="subtitle1"
                color="textPrimary"
                sx={{ fontSize: 20, fontWeight: "bolder" }}
              >
                Balance
              </Typography>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                sx={{ fontSize: 30, fontWeight: "bolder" }}
              >
                <NumericFormat
                  value={20000}
                  thousandSeparator
                  displayType="text"
                  prefix="₦"
                />
              </Typography>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default ClientDashboardContainer;
