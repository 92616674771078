import { ISessionState } from "@/db/session-slice";
import { showSnackbar } from "@/helpers/snackbar-helpers";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { isUndefined } from "lodash";
import { useEffect, useState } from "react";
import { generateUUIDV4, parseOptionWithMatch } from "@/utils/funcs";
import { useCollection, useSession } from "@/hooks/app-hooks";
import { collectionServices } from "@/services/root";
import { setShowLoadMoreBtn } from "@/helpers/collection-helpers";
import ListAsyncSearchBar from "../common/list/ListAsyncSearchBar";
import { ICollectionState } from "@/db/collection-slice";
import { IAccountDocument } from "@/types/session-types";
import { IHaskeTokenDocument } from "@/types/transaction-types";
import { useNavigate } from "react-router-dom";
import { CLIENT_TRANSACTIONS, DASHBOARD_BASE } from "@/routes/dashboard-routes";

const TransactionsSearch = () => {
  const [list, setList] = useState<IHaskeTokenDocument[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [query, setQuery] = useState("");
  const collectionState = useCollection() as ICollectionState<IAccountDocument>;
  const profile = useSession() as ISessionState;

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      setList([]);
      setIsSearching(true);
      if (collectionState.page === "mgt") {
        const {
          status,
          list: _list,
          errorMessage,
        } = await collectionServices.queryAllDocs("TokenTransactions", query);
        if (status === "success") {
          setIsSearching(false);
          const result = _list as IHaskeTokenDocument[];
          setList(result);
        }
        if (status === "error") {
          setIsSearching(false);
          showSnackbar({
            status: "error",
            msg: errorMessage,
            openSnackbar: true,
          });
        }
      }

      if (collectionState.page === "customer") {
        const {
          status,
          list: _list,
          errorMessage,
        } = await collectionServices.queryDocs(
          "TokenTransactions",
          [
            {
              uField: "userId",
              uid: profile.uid,
            },
          ],
          query
        );
        if (status === "success") {
          setIsSearching(false);
          const result = _list as IHaskeTokenDocument[];
          setList(result);
        }
        if (status === "error") {
          setIsSearching(false);
          showSnackbar({
            status: "error",
            msg: errorMessage,
            openSnackbar: true,
          });
        }
      }
    })();
  }, [query, profile.uid, collectionState]);

  const contains = (product: IHaskeTokenDocument, query: string) => {
    if (!isUndefined(query)) {
      const formattedQuery = query?.toLowerCase();
      const {
        transactionID,
        meter: { meterName, meterNumber },
      } = product;

      if (
        transactionID?.includes(formattedQuery) ||
        meterName?.includes(formattedQuery) ||
        meterNumber?.includes(formattedQuery)
      ) {
        return true;
      }
    }

    return false;
  };

  const renderOption = (
    props: any,
    option: IHaskeTokenDocument,
    { inputValue }: { inputValue: string }
  ) => {
    const partsTransactionID = parseOptionWithMatch(
      `${option.transactionID}`,
      inputValue
    );
    const partsMeterNumber = parseOptionWithMatch(
      `${option.meter.meterNumber}`,
      inputValue
    );
    const partsMeterName = parseOptionWithMatch(
      `${option.meter.meterName}`,
      inputValue
    );

    return (
      <Box
        component="li"
        {...props}
        key={generateUUIDV4()}
        onClick={() => {
          navigate(
            `/${DASHBOARD_BASE}/${CLIENT_TRANSACTIONS}/${option.transactionID}`
          );

          setShowLoadMoreBtn(false);
        }}
      >
        <Stack>
          <Typography variant="subtitle2">
            {partsTransactionID?.map((part, index) => (
              <span
                key={index}
                style={{
                  color: part.highlight ? "#1b5e20" : "#81c784",
                }}
              >
                {part.text}
              </span>
            ))}
          </Typography>
          <Typography variant="subtitle2">
            {partsMeterNumber?.map((part, index) => (
              <span
                key={index}
                style={{
                  color: part.highlight ? "#1b5e20" : "#81c784",
                }}
              >
                {part.text}
              </span>
            ))}
          </Typography>
          <Typography variant="subtitle2">
            {partsMeterName?.map((part, index) => (
              <span
                key={index}
                style={{
                  color: part.highlight ? "#1b5e20" : "#81c784",
                }}
              >
                {part.text}
              </span>
            ))}
          </Typography>
        </Stack>
        <Divider sx={{ my: 1 }} />
      </Box>
    );
  };

  const stringify = (option: IHaskeTokenDocument) => {
    return `${option.transactionID?.toLowerCase()} ${option.meter.meterNumber?.toLowerCase()} ${option.meter.meterName?.toLowerCase()}`;
  };

  return (
    <>
      <ListAsyncSearchBar
        list={list}
        contains={contains}
        renderOption={renderOption}
        stringify={stringify}
        placeHolderText={
          "Search for transaction id, meter number or meter name"
        }
        isSearching={isSearching}
        query={query}
        setQuery={setQuery}
      />
    </>
  );
};

export default TransactionsSearch;
