import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";
import { Divider, IconButton } from "@mui/material";
import AddFundsForm from "@/components/forms/wallet/AddFundsForm";
import { Close } from "@mui/icons-material";

interface Props {
  setVisible?: any;
  visible: boolean;
  close?: any;
}

const WalletAddFundsModal = (props: Props) => {
  return (
    <>
      {" "}
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 4,
          marginLeft: 0,
        }}
        open={props.visible}
      >
        <Box
          sx={{
            minHeight: 100,
            width: { xs: "100vw", md: 600 },
            borderRadius: 5,
            bgcolor: "background.paper",
            pt: 2,
            position: "relative",
          }}
        >
          <div style={{ position: "absolute", top: 0, left: 0 }}>
            <IconButton
              onClick={() => {
                if (props.close) {
                  props.close();
                } else {
                  props.setVisible(!props.visible);
                }
              }}
            >
              <Close />
            </IconButton>
          </div>
          <Typography
            variant="subtitle2"
            color="textPrimary"
            textAlign={"center"}
          >
            Add funds
          </Typography>
          <Divider sx={{ my: 2 }} />
          <AddFundsForm
            close={() => {
              if (props.close) {
                props.close();
              } else {
                props.setVisible(!props.visible);
              }
            }}
          />
        </Box>
      </Backdrop>
    </>
  );
};

export default WalletAddFundsModal;
