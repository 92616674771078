export const APP_NAME = "Haske";



export const APP_CURRENCY = "₦";


  
export const RATING_LABELS: { [index: string]: string } = {
  0.5: "Very Useless",
  1: "Useless",
  1.5: "Very Poor",
  2: "Poor",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Very Good",
  4.5: "Excellent",
  5: "Very Excellent",
};

