const SESSION_BASE = "session";
const FORGOT_PASSWORD = "forgot-password";
const REGISTER = "register";
const ACCOUNT_CREATED_SUCCESS = "account-created-successfully";
const VERIFY_EMAIL = "verify-email";
const ACCESS_RESTRICTED = "access-restricted";
const SECURITY_CHECK = "security-check";

export {
  SESSION_BASE,
  FORGOT_PASSWORD,
  REGISTER,
  ACCOUNT_CREATED_SUCCESS,
  VERIFY_EMAIL,
  ACCESS_RESTRICTED,
  SECURITY_CHECK,
};
