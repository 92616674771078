import { auth } from "@/configs/firebase";
import SessionModel from "@/models/session-models";
import { UserInfo, onAuthStateChanged } from "firebase/auth";
import { IImage } from "types/global-types";
import { DocQueryResponse, mutationResponse } from "types/promise-types";
import { IAccountDocument, IAccountInput } from "types/session-types";

class SessionRepository {
  protected model: SessionModel;
  constructor() {
    this.model = new SessionModel();
  }
  async getAccount(uid: string): Promise<DocQueryResponse<IAccountDocument>> {
    try {
      const doc = await this.model.getAccount(uid);
      if (doc.exists()) {
        const data = doc.data() as Omit<IAccountDocument, "uid">;
        return {
          status: "success",
          item: {
            uid: doc.id,
            ...data,
          },
        };
      }
      return {
        status: "success",
      };
    } catch (error: any) {
      return {
        status: "error",
        errorMessage: error.message,
      };
    }
  }
  async createAccount(
    values: Omit<IAccountInput, "metadata">
  ): Promise<mutationResponse> {
    try {
      await this.model.createAccount(values);

      return {
        status: "success",
        errorMessage: "no error",
        successMessage: "created profile successfully",
      };
    } catch (error: any) {
      // console.log(error);

      return {
        status: "error",
        errorMessage: error.message,
        successMessage: "",
      };
    }
  }
  async updateAccount(
    values: Required<Pick<IAccountInput, "uid">> & Partial<IAccountInput>
  ): Promise<mutationResponse> {
    try {
      await this.model.updateAccount(values);

      return {
        status: "success",
        errorMessage: "no error",
        successMessage: "Updated profile successfully",
      };
    } catch (error: any) {
      // console.log(error);

      return {
        status: "error",
        errorMessage: error.message,
        successMessage: "",
      };
    }
  }
  async updateAccountPhoto(
    values: IImage & { id: string }
  ): Promise<mutationResponse> {
    try {
      await this.model.updateAccountPhoto(values);

      return {
        status: "success",
        errorMessage: "no error",
        successMessage: "Updated profile photo successfully",
      };
    } catch (error: any) {
      return {
        status: "error",
        errorMessage: error.message,
        successMessage: "",
      };
    }
  }
  async updateUserCredential(values: UserInfo): Promise<mutationResponse> {
    try {
      await this.model.updateUserCredential(values);

      return {
        status: "success",
        errorMessage: "no error",
        successMessage: "Updated profile successfully",
      };
    } catch (error: any) {
      return {
        status: "error",
        errorMessage: error.message,
        successMessage: "",
      };
    }
  }

  async updateUserEmail(email: string): Promise<mutationResponse> {
    try {
      await this.model.updateUserEmail(email);

      return {
        status: "success",
        errorMessage: "no error",
        successMessage: "Updated user email successfully",
      };
    } catch (error: any) {
      return {
        status: "error",
        errorMessage: error.message,
        successMessage: "",
      };
    }
  }
  async sendEmailVerificationToUser(): Promise<mutationResponse> {
    try {
      await this.model.sendEmailVerificationToUser();

      return {
        status: "success",
        errorMessage: "no error",
        successMessage: "Sent user email verification successfully",
      };
    } catch (error: any) {
      return {
        status: "error",
        errorMessage: error.message,
        successMessage: "",
      };
    }
  }
  async updateUserPassword(newPassword: string): Promise<mutationResponse> {
    try {
      await this.model.updateUserPassword(newPassword);

      return {
        status: "success",
        errorMessage: "no error",
        successMessage: "Updated user password successfully",
      };
    } catch (error: any) {
      return {
        status: "error",
        errorMessage: error.message,
        successMessage: "",
      };
    }
  }
  async sendPasswordResetEmailToUser(email: string): Promise<mutationResponse> {
    try {
      await this.model.sendPasswordResetEmailToUser(email);

      return {
        status: "success",
        errorMessage: "no error",
        successMessage: "Sent user password reset email successfully",
      };
    } catch (error: any) {
      return {
        status: "error",
        errorMessage: error.message,
        successMessage: "",
      };
    }
  }
  async deleteUserForever(): Promise<mutationResponse> {
    try {
      await this.model.deleteUserForever();

      return {
        status: "success",
        errorMessage: "no error",
        successMessage: "Deleted user  successfully",
      };
    } catch (error: any) {
      return {
        status: "error",
        errorMessage: error.message,
        successMessage: "",
      };
    }
  }
  async createUserAccount(
    email: string,
    password: string
  ): Promise<mutationResponse> {
    try {
      const userCredential = await this.model.createUserAccount(
        email,
        password
      );

      return {
        status: "success",
        errorMessage: "no error",
        successMessage: "Created user session successfully",
        data: userCredential,
      };
    } catch (error: any) {
      return {
        status: "error",
        errorMessage: error.message,
        successMessage: "",
      };
    }
  }

  async signIn(email: string, password: string): Promise<mutationResponse> {
    try {
      const userCredential = await this.model.signIn(email, password);

      return {
        status: "success",
        errorMessage: "no error",
        successMessage: "Logged user in successfully",
        data: userCredential,
      };
    } catch (error: any) {
      return {
        status: "error",
        errorMessage: error.message,
        successMessage: "",
      };
    }
  }

  async signInWithCustomToken(token: string): Promise<mutationResponse> {
    try {
      const userCredential = await this.model.signInWithCustomToken(token);

      return {
        status: "success",
        errorMessage: "no error",
        successMessage: "Logged user in successfully",
        data: userCredential,
      };
    } catch (error: any) {
      return {
        status: "error",
        errorMessage: error.message,
        successMessage: "",
      };
    }
  }
  async logOut(): Promise<mutationResponse> {
    try {
      await this.model.logOut();

      return {
        status: "success",
        errorMessage: "no error",
        successMessage: "Logged user out successfully",
      };
    } catch (error: any) {
      return {
        status: "error",
        errorMessage: error.message,
        successMessage: "",
      };
    }
  }

  async makeUserActive(id: string): Promise<mutationResponse> {
    try {
      await this.model.makeUserActive(id);

      return {
        status: "success",
        errorMessage: "",
        successMessage: "User status changed to active successfully",
      };
    } catch (error: any) {
      return {
        status: "error",
        errorMessage: error.message,
        successMessage: "",
      };
    }
  }
  async makeUserInActive(id: string): Promise<mutationResponse> {
    try {
      await this.model.makeUserInActive(id);

      return {
        status: "success",
        errorMessage: "",
        successMessage: "User status changed to inactive successfully",
      };
    } catch (error: any) {
      return {
        status: "error",
        errorMessage: error.message,
        successMessage: "",
      };
    }
  }
  async makeUserBlocked(id: string): Promise<mutationResponse> {
    try {
      await this.model.makeUserBlocked(id);

      return {
        status: "success",
        errorMessage: "",
        successMessage: "User status changed to blocked successfully",
      };
    } catch (error: any) {
      return {
        status: "error",
        errorMessage: error.message,
        successMessage: "",
      };
    }
  }
  async deleteUserPermanently(id: string): Promise<mutationResponse> {
    try {
      await this.model.deleteUserPermanently(id);

      return {
        status: "success",
        errorMessage: "",
        successMessage: "User deleted successfully",
      };
    } catch (error: any) {
      return {
        status: "error",
        errorMessage: error.message,
        successMessage: "",
      };
    }
  }

  getValidatedUser() {
    return new Promise((resolve, reject) => {
      const unsubscribe = onAuthStateChanged(
        auth,
        (user) => {
          unsubscribe();
          resolve(user);
        },
        reject // pass up any errors attaching the listener
      );
    });
  }
}

export default SessionRepository;
