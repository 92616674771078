import EmptyList from "@/components/common/EmptyList";
import LoadingScreen from "@/components/common/LoadingScreen";
import DashboardSectionLayout from "@/components/dashboard/DashboardSectionLayout";
import DashboardHeader from "@/components/headers/DashboardHeader";
import AddMeterBtn from "@/components/meter/AddMeterBtn";
import MeterContainer from "@/components/meter/MeterContainer";
import {
  getDocs,
  setPageState,
  setShowLoadMoreBtn,
} from "@/helpers/collection-helpers";
import { useCollection, useSession } from "@/hooks/app-hooks";
import { useEffect } from "react";

const ClientMeter = () => {
  const collectionState = useCollection();
  const profile = useSession();

  useEffect(() => {
    (async () => {
      if (profile.uid) {
        await getDocs("Meters", [
          {
            uField: "userId",
            uid: profile.uid ? profile.uid : "sjj",
          },
        ]);

        setPageState("customer");
        setShowLoadMoreBtn(true);
      }
    })();
  }, [profile.uid]);

  if (collectionState.isLoading) return <LoadingScreen />;
  if (collectionState.status === "error")
    return (
      <EmptyList
        title={"An error occured"}
        caption="Click on the button below to add a new meter"
        action={<AddMeterBtn />}
      />
    );

  return (
    <>
      {collectionState.status === "success" && collectionState.all.count > 0 && (
        <>
          <DashboardHeader title="My Meters" />
          <DashboardSectionLayout>
            <MeterContainer />
          </DashboardSectionLayout>
        </>
      )}
      {collectionState.status === "success" &&
        collectionState.all.count === 0 && (
          <EmptyList
            title={"Meter list is empty"}
            caption="Click on the button below to add a new meter"
            action={<AddMeterBtn />}
          />
        )}
    </>
  );
};

export default ClientMeter;
