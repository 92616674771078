import DashboardSectionLayout from "@/components/dashboard/DashboardSectionLayout";
import DashboardHeader from "@/components/headers/DashboardHeader";
import UsersContainer from "@/components/users/UsersContainer";
import {
  getAllDocs,
  setPageState,
  setShowLoadMoreBtn,
} from "@/helpers/collection-helpers";
import { useEffect } from "react";

const MgtAccounts = () => {
  useEffect(() => {
    (async () => {
      await getAllDocs("Users");

      setPageState("mgt");
      setShowLoadMoreBtn(true);
    })();
  }, []);
  return (
    <>
      <DashboardHeader title="Manage Accounts" />
      <DashboardSectionLayout>
        <UsersContainer />
      </DashboardSectionLayout>
    </>
  );
};

export default MgtAccounts;
