import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import GeneralFooter from "../nav/GeneralFooter";
import GeneralNav from "../nav/GeneralNav";
import { motion } from "framer-motion";

const GeneralLayout = () => {
  return (
    <Box sx={{ bgcolor: "background.default", minHeight: "100vh" }}>
      <GeneralNav />
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ ease: "easeInOut" }}
      >
        <Outlet />
      </motion.div>
      <GeneralFooter />
    </Box>
  );
};

export default GeneralLayout;
